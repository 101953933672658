import React, {Component/*,Fragment*/} from 'react';
//import $ from 'jquery';
import axios from 'axios';
import { connect } from "react-redux";
//import CommentsList from '../pages/articleComponents/commentList';
import defaultAvatar from '../../Assets/img/catalog_avatar.jpg';
import { Link } from 'react-router-dom';

class ConnectedComment extends Component {

	constructor(props) {
        super(props);

        this.state = {
			loggedInUserID: null,
			unique_id: this.props.data.unique_id,
			isEditing: this.props.data.isEditing ? this.props.data.isEditing : false,
			commentData: this.props.data.commentData,
			userData: this.props.data.userData,
			replies: this.props.data.replies,
			editButtonText: this.props.data.isEditing ? 'Καταχώρηση' : 'Επεξεργασία',
			isOwnerLoggedIn: false,
			isUserLoggedIn: false
		};

		this.editComment = this.editComment.bind(this);
		this.onCommentTextChange = this.onCommentTextChange.bind(this);
		this.unmount = this.unmount.bind(this);
		this.replyToComment = this.replyToComment.bind(this);
    }

    componentDidMount() {
    	this.setState({
    		isOwnerLoggedIn: (this.props.currentUser.isLoggedIn && this.props.currentUser.data.id === this.state.userData.id),
			isUserLoggedIn: this.props.currentUser.isLoggedIn,
    	});

    	//this.textarea.focus();
    }

    componentDidUpdate(prevProps, prevState){
		//console.log('comment updated')
		// console.log(this.state.unique_id)
		// console.log(`Comment Updated with previous props`,prevProps.data.userData);
		// console.log(`and new props`,this.props.data.userData);
    	if(prevProps.currentUser !== this.props.currentUser){
    		this.setState({
	    		isOwnerLoggedIn: (this.props.currentUser.isLoggedIn && this.props.currentUser.data.id === this.state.userData.id),
	    		isUserLoggedIn: this.props.currentUser.isLoggedIn
	    	});
		}

		if(prevProps.data.userData !== this.props.data.userData){
    		this.setState({
				userData: this.props.data.userData,
				isOwnerLoggedIn: (this.props.currentUser.isLoggedIn && this.props.currentUser.data.id === this.props.data.userData.id),
	    		isUserLoggedIn: this.props.currentUser.isLoggedIn
	    	});
	    }
		
		/* if(prevProps.data.isEditing !== this.props.data.isEditing){
    		this.setState({
	    		isEditing: this.props.data.isEditing ? this.props.data.isEditing : false,
				editButtonText: this.props.data.isEditing ? 'Καταχώρηση' : 'Επεξεργασία',
	    	});
	    } */

	    if(prevProps.data.replies !== this.props.data.replies){
    		this.setState({
	    		replies: this.props.data.replies
	    	});

	    }

	    if(prevProps.data.unique_id !== this.props.data.unique_id){
    		this.setState({
	    		unique_id: this.props.data.unique_id
	    	});
	    }

	    if(prevProps.data.commentData !== this.props.data.commentData){
    		this.setState({
	    		commentData: this.props.data.commentData
	    	});
		}	    
    }

    editComment(e){
		let buttonText = (!this.state.isEditing) ? 'Καταχώρηση' : 'Επεξεργασία';

    	this.setState({
    		isEditing: !this.state.isEditing,
    		editButtonText: buttonText
    	});
    		
    	if (this.state.isEditing && this.state.commentData.id === null) {
    		let newCommentData = {
    			commentData: this.state.commentData,
    			userData: this.state.userData,
    			replies: this.state.replies,
    			unique_id: this.state.unique_id
    		};

    		this.props.submitReplyToComment(newCommentData);
    	}else if (this.state.isEditing) {
    		let headers = {
	    		'Authorization': 'Bearer '+this.props.currentUser.token
	    	}

    		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/editComment', {
    			commentID:this.state.commentData.id,
    			newText:this.state.commentData.text,
    			is_deleted:this.state.commentData.is_deleted
    		}, {headers: headers})
			.then(response => {
				//console.log(response);
		        return response;
		      })
		      .then(json => {
		      	//console.log(json.data);
		    })
			.catch(err => console.log(err));
    	}
    }

    onCommentTextChange(e){
    	this.setState({
    		commentData:{
    			...this.state.commentData,
    			text: e.target.value
    		}
    	})

    	this.resizeIt(e.target);
    }

    unmount(){

    	if (this.state.replies.length > 0) {
    		this.setState({
	    		commentData:{
	    			...this.state.commentData,
	    			text: 'Το σχόλιο έχει διαγραφεί.',
	    			is_deleted: 1
	    		}
	    	});

	    	let headers = {
	    		'Authorization': 'Bearer '+this.props.currentUser.token
	    	}

    		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/editComment', {
    			commentID:this.state.commentData.id,
    			newText:'Το σχόλιο έχει διαγραφεί.',
    			is_deleted:1
    		}, {headers: headers})
			.then(response => {
				//console.log(response);
		        return response;
		      })
		      .then(json => {
		      	//console.log(json.data);
		    })
			.catch(err => console.log(err));
    	}else{
    		this.props.unmountMe(this.state.unique_id,this.state.commentData.id);
    	}
    }

    replyToComment(parentId = null){
    	let parentIdToSend = this.props.depth < this.props.maxDepth ? this.state.commentData.id : this.state.commentData.parentCommentID;

    	/*if (parentId) {
    		parentIdToSend = parentIdToSend;
    	}else{
    		parentIdToSend = this.props.depth < this.props.maxDepth ? this.state.commentData.id : this.state.commentData.parentCommentID;
    	}*/

    	this.props.addReplyToComment(parentIdToSend);
    }

    resizeIt(element) {
		// Reset field height
		element.style.height = 'inherit';

		// Get the computed styles for the element
		var computed = window.getComputedStyle(element);

		// Calculate the height
		var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
		             + parseInt(computed.getPropertyValue('padding-top'), 10)
		             + element.scrollHeight
		             + parseInt(computed.getPropertyValue('padding-bottom'), 10)
		             + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

		element.style.height = height + 'px';
    }

	render() {
		const imgUrl = (this.state.userData.image) ? this.state.userData.image : defaultAvatar
		const imageStyle = {
			backgroundImage: "url(" + window.serverURL + "/serve_images/" + imgUrl + ")",
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat"
		}
		console.log(this.state.userData);
		return(

			<div className='comment d-flex flex-column'>
				<div className="comment-area d-flex align-items-top justify-content-between">
					<div className="icon-container">
						<div className="icon" style={imageStyle}>							
						</div>
					</div>
					<div className="body d-flex flex-column">
						<div className="user-name">
							<h4>{this.state.userData.full_name}</h4>
						</div>
						<div className="timestamp d-sm-none"><i className="fa fa-clock-o" aria-hidden="true"></i> {this.state.commentData.updated_at}</div>
						<div className="user-social">
							<a href={"/hcp_profile/"+this.state.userData.id} target="_blank" rel="noopener noreferrer nofollow" aria-label="hcp profile link"><div className="d-none">hidden text</div><i className="fa fa-vcard" aria-hidden="true"></i></a>							
							{(this.state.userData.social.facebook) ? <a href={this.state.userData.social.facebook} target="_blank" rel="noopener noreferrer nofollow"><div className="d-none">hidden text</div><i className="fa fa-facebook" aria-hidden="true"></i></a> : ''}
							{(this.state.userData.social.twitter) ? <a href={this.state.userData.social.twitter} target="_blank" rel="noopener noreferrer nofollow"><div className="d-none">hidden text</div><i className="fa fa-twitter" aria-hidden="true"></i></a> : ''}
							{(this.state.userData.social.instagram) ? <a href={this.state.userData.social.instagram} target="_blank" rel="noopener noreferrer nofollow"><div className="d-none">hidden text</div><i className="fa fa-instagram" aria-hidden="true"></i></a> : ''}
							{(this.state.userData.social.linkedin) ? <a href={this.state.userData.social.linkedin} target="_blank" rel="noopener noreferrer nofollow"><div className="d-none">hidden text</div><i className="fa fa-linkedin" aria-hidden="true"></i></a> : ''}
						</div>
						<div className="comment-text-container">
							<div className={this.state.isEditing ? 'comment-text hide' : 'comment-text'}>{this.state.commentData.text}</div>
							<textarea rows="2" className={this.state.isEditing ? 'comment-edit form-control' : 'comment-edit form-control hide'} value={this.state.commentData.text} onChange={this.onCommentTextChange} ref={(textarea) => { this.textarea = textarea; }}></textarea>
						</div>
						<div className={(this.state.commentData.is_deleted === 1 || this.props.isParentDeleted === 1) ? "button-container d-none" : "button-container d-flex"}>
							<div className="row">
								<div className="col-12 col-sm-9">
									<div className={this.state.isOwnerLoggedIn ? "" : "d-none"}>
										<div className={ (!this.state.isEditing) ? "edit-button btn btn-primary" : "new-comment-submit btn btn-primary"} onClick={this.editComment}>{this.state.editButtonText}</div>
										<div className="delete-button btn btn-primary" onClick={this.unmount}>Διαγραφή</div>
									</div>
								</div>
								<div className="col-12 col-sm-3">
									<div className={this.state.isUserLoggedIn ? "reply-button btn btn-primary" : "reply-button btn btn-primary d-none"} onClick={this.replyToComment}>Απάντηση</div>
								</div>
							</div>
						</div>
					</div>
					<div className="timestamp d-sm-block d-none"><i className="fa fa-clock-o" aria-hidden="true"></i> {this.state.commentData.updated_at}</div>
				</div>
				<div className="comment-replies-area">
					{this.props.data.replies.map((item,index) => (
				          <Comment 
				          key={index} 
				          data={item}
				          unmountMe={this.props.unmountMe}
				          addReplyToComment={this.props.addReplyToComment}
				          submitReplyToComment={this.props.submitReplyToComment}
				          articleId={this.props.articleId} 
				          articleType={this.props.articleType}
				          depth={this.props.depth+1}
				          maxDepth={this.props.maxDepth}
				          isParentDeleted={this.state.commentData.is_deleted}
				          />
				        ))}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
 return({
   	currentUser: state.user
 });
};

const Comment = connect(mapStateToProps)(ConnectedComment);

export default Comment;
