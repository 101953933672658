import React, {Component} from 'react';
import $ from 'jquery';

class ContactPage extends Component {

	constructor() {
		super();
		
		this.state = {
			openContainer: false
		};		

		this.toggleContainer = this.toggleContainer.bind(this)
	}
    

	componentDidMount() {		
		$(document).ready(function() {
		    accessibilityButtons({
		        font: {
		            nameButtonIncreaseFont: '+Α', // Default
		            ariaLabelButtonIncreaseFont: 'Αύξηση μεγέθους γραμματοσειράς', // Default
		            nameButtonDecreaseFont: '-A', // Default
		            ariaLabelButtonDecreaseFont: 'Μείωση μεγέθους γραμματοσειράς' // Default
		        },
		        contrast: {
		            nameButtonAddContrast: 'Προσθήκη αντίθεσης', // Default
		            ariaLabelButtonAddContrast: 'Προσθήκη αντίθεσης', // Default
		            nameButtonRemoveContrast: 'Αφαίρεση αντίθεσης', // Default
		            ariaLabelButtonRemoveContrast: 'Αφαίρεση αντίθεσης' // Default
		        },
                grayscale: {
                    nameButtonAddGrayscale: 'Προσθήκη γκρίζου φίλτρου', // Default
                    ariaLabelButtonAddGrayscale: 'Προσθήκη γκρίζου φίλτρου', // Default
                    nameButtonRemoveGrayscale: 'Αφαίρεση γκρίζου φίλτρου', // Default
                    ariaLabelButtonRemoveGrayscale: 'Αφαίρεση γκρίζου φίλτρου' // Default
                },
                lightbg: {
                    nameButtonAddLightbg: 'Προσθήκη άσπρου φόντου', // Default
                    ariaLabelButtonAddLightbg: 'Προσθήκη άσπρου φόντου', // Default
                    nameButtonRemoveLightbg: 'Αφαίρεση άσπρου φόντου', // Default
                    ariaLabelButtonRemoveLightbg: 'Αφαίρεση άσπρου φόντου' // Default
                },
                underline: {
                    nameButtonAddUnderline: 'Προσθήκη υπογράμμισης συνδέσμων', // Default
                    ariaLabelButtonAddUnderline: 'Προσθήκη υπογράμμισης συνδέσμων', // Default
                    nameButtonRemoveUnderline: 'Αφαίρεση υπογράμμισης συνδέσμων', // Default
                    ariaLabelButtonRemoveUnderline: 'Αφαίρεση υπογράμμισης συνδέσμων' // Default
                }
		    });
		})
	}
	
	componentDidUpdate() {				
			
	}

	toggleContainer() {
		
		this.setState({
			openContainer: !this.state.openContainer
		})
	}
	
	render() {
		const openedClass = (this.state.openContainer) ? 'open' : '';
		return (
			
			<div className={"js-accessibility-container d-flex flex-row  " + openedClass}>
				<div className="accessibility-button" onClick={this.toggleContainer}>
				</div>
				<div className="accessibility-functions-container">
					<ul>
						<li>
							<button aria-label="Αύξηση/Μείωση μεγέθους γραμματοσειράς" id="accessibility-font" className="js-accessibility btn btn-info">Αύξηση μεγέθους γραμματοσειράς</button>
						</li>
						<li>
							<button aria-label="Add Contrast" id="accessibility-contrast" className="js-accessibility btn btn-info">Add Contrast</button>
						</li>
                        <li>
                            <button aria-label="Add Grayscale" id="accessibility-grayscale" className="js-accessibility btn btn-info">Add Grayscale</button>
                        </li>
                        <li>
                            <button aria-label="Add Light Background" id="accessibility-lightbg" className="js-accessibility btn btn-info">Add Light Background</button>
                        </li>
                        <li>
                            <button aria-label="Underline" id="accessibility-underline" className="js-accessibility btn btn-info">Add Underline</button>
                        </li>
					</ul>
				</div>
			</div>
		);
		
	}
}

export default ContactPage;




var accessibilityButtons = function accessibilityButtons(options) {    

    /**
     * hasClass
     * @param  {string}  element - DOM element
     * @param  {string}  clazz   - Class Name
     * @return {Boolean}
     */

    function hasClass(element, clazz) {
        return (' ' + element.className + ' ').indexOf(' ' + clazz + ' ') > -1;
    }

    var setting = {
        font: {
            nameButtonIncreaseFont: '+A',
            ariaLabelButtonIncreaseFont: 'Increase Font',
            nameButtonDecreaseFont: '-A',
            ariaLabelButtonDecreaseFont: 'Decrease Font'
        },

        contrast: {
            nameButtonAddContrast: 'Add Contrast',
            ariaLabelButtonAddContrast: 'Add Contrast',
            nameButtonRemoveContrast: 'Remove Contrast',
            ariaLabelButtonRemoveContrast: 'Remove Contrast'
        },
        grayscale: {
            nameButtonAddGrayscale: 'Προσθήκη γκρίζου φίλτρου', // Default
            ariaLabelButtonAddGrayscale: 'Προσθήκη γκρίζου φίλτρου', // Default
            nameButtonRemoveGrayscale: 'Αφαίρεση γκρίζου φίλτρου', // Default
            ariaLabelButtonRemoveGrayscale: 'Αφαίρεση γκρίζου φίλτρου' // Default
        },
        lightbg: {
            nameButtonAddLightbg: 'Προσθήκη άσπρου φόντου', // Default
            ariaLabelButtonAddLightbg: 'Προσθήκη άσπρου φόντου', // Default
            nameButtonRemoveLightbg: 'Αφαίρεση άσπρου φόντου', // Default
            ariaLabelButtonRemoveLightbg: 'Αφαίρεση άσπρου φόντου' // Default
        },
        underline: {
            nameButtonAddUnderline: 'Προσθήκη υπογράμμισης συνδέσμων', // Default
            ariaLabelButtonAddUnderline: 'Προσθήκη υπογράμμισης συνδέσμων', // Default
            nameButtonRemoveUnderline: 'Αφαίρεση υπογράμμισης συνδέσμων', // Default
            ariaLabelButtonRemoveUnderline: 'Αφαίρεση υπογράμμισης συνδέσμων' // Default
        }
    };

    // Set buttons name and aria label
    if (options) {
        for (var key in options) {
            if (options.hasOwnProperty(key)) {
                var obj = options[key];

                for (var prop in obj) {
                    if (obj.hasOwnProperty(prop)) {
                        setting[key][prop] = obj[prop];
                    }
                }
            }
        }
    }
    
    var $body = document.body,
        $fontButton = document.getElementById('accessibility-font'),
        $contrastButton = document.getElementById('accessibility-contrast'),
        $grayscaleButton = document.getElementById('accessibility-grayscale'),
        $lightbgButton = document.getElementById('accessibility-lightbg'),
        $underlineButton = document.getElementById('accessibility-underline'),
        $accessibilityButtons = document.getElementsByClassName('js-accessibility'),
        storageFont = localStorage.accessibility_font,
        storageContrast = localStorage.accessibility_contrast,
        storageGrayscale = localStorage.accessibility_grayscale,
        storageLightbg = localStorage.accessibility_lightbg,
        storageUnderline = localStorage.accessibility_underline;

    // Check if exist storage and set the correct button names and aria attributes
    if (storageFont && $fontButton) {
        $body.classList.add('accessibility-font');

        $fontButton.innerHTML = setting.font.nameButtonDecreaseFont;
        $fontButton.setAttribute('aria-label', setting.font.ariaLabelButtonDecreaseFont);
    } else if ($fontButton) {
        $fontButton.innerHTML = setting.font.nameButtonIncreaseFont;
        $fontButton.setAttribute('aria-label', setting.font.ariaLabelButtonIncreaseFont);
    }

    if (storageContrast && $contrastButton) {
        $body.classList.add('accessibility-contrast');

        $contrastButton.innerHTML = setting.contrast.nameButtonRemoveContrast;
        $contrastButton.setAttribute('aria-label', setting.contrast.ariaLabelButtonRemoveContrast);
    } else if ($contrastButton) {
        $contrastButton.innerHTML = setting.contrast.nameButtonAddContrast;
        $contrastButton.setAttribute('aria-label', setting.contrast.ariaLabelButtonAddContrast);
    }

    if (storageGrayscale && $grayscaleButton) {
        $body.classList.add('accessibility-grayscale');

        $grayscaleButton.innerHTML = setting.grayscale.nameButtonRemoveGrayscale;
        $grayscaleButton.setAttribute('aria-label', setting.grayscale.ariaLabelButtonRemoveGrayscale);
    } else if ($grayscaleButton) {
        $grayscaleButton.innerHTML = setting.grayscale.nameButtonAddGrayscale;
        $grayscaleButton.setAttribute('aria-label', setting.grayscale.ariaLabelButtonAddGrayscale);
    }

    if (storageLightbg && $lightbgButton) {
        $body.classList.add('accessibility-lightbg');

        $lightbgButton.innerHTML = setting.lightbg.nameButtonRemoveLightbg;
        $lightbgButton.setAttribute('aria-label', setting.lightbg.ariaLabelButtonRemoveLightbg);
    } else if ($lightbgButton) {
        $lightbgButton.innerHTML = setting.lightbg.nameButtonAddLightbg;
        $lightbgButton.setAttribute('aria-label', setting.lightbg.ariaLabelButtonAddLightbg);
    }

    if (storageUnderline && $underlineButton) {
        $body.classList.add('accessibility-underline');

        $underlineButton.innerHTML = setting.underline.nameButtonRemoveUnderline;
        $underlineButton.setAttribute('aria-label', setting.underline.ariaLabelButtonRemoveUnderline);
    } else if ($underlineButton) {
        $underlineButton.innerHTML = setting.underline.nameButtonAddUnderline;
        $underlineButton.setAttribute('aria-label', setting.underline.ariaLabelButtonAddUnderline);
    }


    /**
     * Get the click event
     * Rename the buttons
     * Apply/Remove Contrast or Font Size
     * Manage storage
     */
    function accessibility() {
        return function () {
            var $this = this;

            if (hasClass($body, $this.getAttribute('id'))) {
                $body.classList.remove($this.getAttribute('id'));

                if ($this.getAttribute('id') === 'accessibility-font') {
                    $this.innerHTML = setting.font.nameButtonIncreaseFont;
                    $this.setAttribute('aria-label', setting.font.ariaLabelButtonIncreaseFont);
                    localStorage.removeItem('accessibility_font');
                } else if ($this.getAttribute('id') === 'accessibility-underline') {
                    $this.innerHTML = setting.underline.nameButtonAddUnderline;
                    $this.setAttribute('aria-label', setting.font.ariaLabelButtonAddUnderline);
                    localStorage.removeItem('accessibility_underline');
                } else {
                    removeAllColorSettings();
                    if ($this.getAttribute('id') === 'accessibility-contrast') {                            
                        $this.innerHTML = setting.contrast.nameButtonAddContrast;
                        $this.setAttribute('aria-label', setting.contrast.ariaLabelButtonAddContrast);
                        localStorage.removeItem('accessibility_contrast');   
                    } else if ($this.getAttribute('id') === 'accessibility-grayscale'){
                        $this.innerHTML = setting.grayscale.nameButtonAddGrayscale;
                        $this.setAttribute('aria-label', setting.grayscale.ariaLabelButtonAddGrayscale);
                        localStorage.removeItem('accessibility_grayscale');   
                    } else if ($this.getAttribute('id') === 'accessibility-lightbg'){
                        $this.innerHTML = setting.lightbg.nameButtonAddLightbg;
                        $this.setAttribute('aria-label', setting.lightbg.ariaLabelButtonAddLightbg);
                        localStorage.removeItem('accessibility_lightbg');   
                    }
                }
            } else {
                          
                
                if ($this.getAttribute('id') === 'accessibility-font') {
                    if (!storageFont) {
                        localStorage.setItem('accessibility_font', true);
                    }
                    $this.innerHTML = setting.font.nameButtonDecreaseFont;
                    $this.setAttribute('aria-label', setting.font.ariaLabelButtonDecreaseFont);
                } else if ($this.getAttribute('id') === 'accessibility-contrast') { 
                    removeAllColorSettings();     
                    if (!storageContrast) {
                        localStorage.setItem('accessibility_contrast', true);
                    }
                    $this.innerHTML = setting.contrast.nameButtonRemoveContrast;
                    $this.setAttribute('aria-label', setting.contrast.ariaLabelButtonRemoveContrast);
                } else if ($this.getAttribute('id') === 'accessibility-grayscale'){
                    removeAllColorSettings();     
                    if (!storageGrayscale) {
                        localStorage.setItem('accessibility_grayscale', true);
                    }
                    $this.innerHTML = setting.grayscale.nameButtonRemoveGrayscale;
                    $this.setAttribute('aria-label', setting.grayscale.ariaLabelButtonRemoveGrayscale);
                } else if ($this.getAttribute('id') === 'accessibility-lightbg'){
                    removeAllColorSettings();     
                    if (!storageLightbg) {
                        localStorage.setItem('accessibility_lightbg', true);
                    }
                    $this.innerHTML = setting.lightbg.nameButtonRemoveLightbg;
                    $this.setAttribute('aria-label', setting.lightbg.ariaLabelButtonRemoveLightbg);
                } else if ($this.getAttribute('id') === 'accessibility-underline') {                    
                    if (!storageContrast) {
                        localStorage.setItem('accessibility_underline', true);
                    }
                    $this.innerHTML = setting.underline.nameButtonRemoveUnderline;
                    $this.setAttribute('aria-label', setting.underline.ariaLabelButtonRemoveUnderline);
                }

                $body.classList.add($this.getAttribute('id')); 
            }
        };
    }

    function removeAllColorSettings() {
        localStorage.removeItem('accessibility_grayscale');
        localStorage.removeItem('accessibility_lightbg');
        localStorage.removeItem('accessibility_contrast');

        $contrastButton.innerHTML = setting.contrast.nameButtonAddContrast;
        $contrastButton.setAttribute('aria-label', setting.contrast.ariaLabelButtonAddContrast);
        $grayscaleButton.innerHTML = setting.grayscale.nameButtonAddGrayscale;
        $grayscaleButton.setAttribute('aria-label', setting.grayscale.ariaLabelButtonAddGrayscale);
        $lightbgButton.innerHTML = setting.lightbg.nameButtonAddLightbg;
        $lightbgButton.setAttribute('aria-label', setting.lightbg.ariaLabelButtonAddLightbg);

        $body.classList.remove('accessibility-contrast', 'accessibility-grayscale','accessibility-lightbg');

    }
    // Listening Click Event
    for (var i = 0; i < $accessibilityButtons.length; i++) {
        $accessibilityButtons[i].addEventListener('click', accessibility());
    }
};

