import React, {Component/*, Fragment */} from 'react';
import '../../../Assets/css/commentlist.min.css';
//import $ from 'jquery';
import axios from 'axios';
import { connect } from "react-redux";
import Comment from "../../basicComponents/comment";
import { findComment, recursiveCommentCount, recursiveCommentNames } from "../../helper/Helper";

class ConnectedCommentList extends Component {

	constructor(props) {
        super(props)

        this.state = {
			comments: this.props.comments,
			commentComponents: [],
			newCommentText: '',
			ownCommentCount: 0,
			childCommentCount: 0,
			commentNames: [],
			childCommentNames: [],
		};	

        this.onNewCommentTextChange = this.onNewCommentTextChange.bind(this);        
        this.submitNewComment = this.submitNewComment.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.addReplyToComment = this.addReplyToComment.bind(this);
        this.submitReplyToComment = this.submitReplyToComment.bind(this);
        this.calculateCommentCount = this.calculateCommentCount.bind(this);
    }

    componentDidMount() {  
    	//this.makeCommentComponents();    	
    }

    componentDidUpdate(prevProps, prevState) {
		if(prevProps.comments !== this.props.comments){ 
	    	this.setState({
				comments: this.props.comments
			});
	    }

		if(prevState.comments !== this.state.comments){ 
			this.setState({
				ownCommentCount: this.state.comments.length,
				childCommentCount: recursiveCommentCount(this.state.comments),
				commentNames: this.state.comments.map(comment => comment.userData.full_name),
				childCommentNames: recursiveCommentNames(this.state.comments),
			}, function () {
				this.props.returnCommentCount(this.state.ownCommentCount + this.state.childCommentCount, [...new Set([...this.state.commentNames,...this.state.childCommentNames])]);
			});
	    }
	}

	calculateCommentCount(commentTree){
		
	}

    onNewCommentTextChange(e){
    	this.setState({newCommentText: e.target.value})
    }

	submitNewComment(e){
		var dateOptions = { /*weekday: 'long',*/ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

		let timestamp = new Date().toLocaleDateString('el-EL', dateOptions)
		
		var newCommentData = {
			userData: {
				id: this.props.currentUser.data.id,
				full_name: this.props.currentUser.data.full_name,
				social: {
					facebook: this.props.currentUser.data.facebook,
					twitter: this.props.currentUser.data.twitter,
					instagram: this.props.currentUser.data.instagram,
					linkedin: this.props.currentUser.data.linkedin
				},
				image: this.props.currentUser.data.image
			},
			commentData: {
				id: null,
				parentCommentID: 0,
				articleId: this.props.articleId,
				articleType: this.props.articleType,
				text: this.state.newCommentText,
				created_at: timestamp,
				updated_at: timestamp,
				is_deleted: 0
			},
			replies: [],
			unique_id: '_' + Math.random().toString(36).substr(2, 9)
		}

		let headers = {
    		'Authorization': 'Bearer '+this.props.currentUser.token
    	}

		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/addComment', newCommentData, {headers: headers})
		.then(response => {
			//console.log(response);
	        return response;
	      })
	      .then(json => {
	      	//console.log(json.data);

	      	//let comments=this.state.comments;

	      	newCommentData.commentData.id  = json.data.data;

	      	this.setState({
				comments: [
					newCommentData,					
					...this.state.comments,
				],
				newCommentText: ''
			});
	    })
		.catch(err => console.log(err));

	}

	deleteComment(commentUniqueID,commentID){
		/*var newComments = this.state.comments.filter(function(value, index, arr) {
			return value.commentData.id != commentUniqueID;
		});*/

		//console.log(this.state.comments);

		var newComments = findComment(this.state.comments, commentUniqueID, 'delete');

		//console.log(newComments);

		let headers = {
    		'Authorization': 'Bearer '+this.props.currentUser.token
    	}

		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/deleteComment', {id:commentID}, {headers: headers})
		.then(response => {
			//console.log(response);
	        return response;
	      })
	      .then(json => {
	      	//console.log(json.data);
	    })
		.catch(err => console.log(err));

		this.setState({
			comments: newComments
		});
	}

	addReplyToComment(parentCommentID){
		var dateOptions = { /*weekday: 'long',*/ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };	

		let timestamp = new Date().toLocaleDateString('el-EL', dateOptions)

		//let isValidDepth = this.props.depth < this.props.maxDepth;

		var newCommentData = {
			userData: {
				id: this.props.currentUser.data.id,
				full_name: this.props.currentUser.data.full_name,
				social: {
					facebook: this.props.currentUser.data.facebook,
					twitter: this.props.currentUser.data.twitter,
					instagram: this.props.currentUser.data.instagram,
					linkedin: this.props.currentUser.data.linkedin
				},
				image: this.props.currentUser.data.image
			},
			commentData: {
				id: null,
				parentCommentID: parentCommentID,
				articleId: this.props.articleId,
				articleType: this.props.articleType,
				text: '',
				created_at: timestamp,
				updated_at: timestamp,
				is_deleted: 0
			},
			replies: [],
			isEditing: true,
			unique_id: '_' + Math.random().toString(36).substr(2, 9)
		};
		
		let newComments = findComment([...this.state.comments], parentCommentID, 'addReply',newCommentData);

		this.setState(state => {
	      const comments = newComments;

	      return {
	        comments,
	      };
	    });
	}

	submitReplyToComment(newCommentData){	
		let headers = {
    		'Authorization': 'Bearer '+this.props.currentUser.token
    	}

		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/addComment', newCommentData, {headers: headers})
		.then(response => {
			//console.log(response);
	        return response;
	      })
	      .then(json => {
	      	//console.log(json.data);

	      	newCommentData.commentData.id  = json.data.data;
			newCommentData.isEditing  = false;
	      	let newComments = findComment([...this.state.comments], newCommentData.unique_id, 'modify',newCommentData);

	      	this.setState(state => {
			    const comments = newComments;

			    return {
			      comments,
			    };
		    });
	    })
		.catch(err => console.log(err));
	}

	render() {

		return(
			<div className="comment-list">
				<div className={this.props.currentUser.isLoggedIn ? "new-comment-container d-flex justify-content-between align-items-center" : "new-comment-container d-none justify-content-between align-items-center"}>
					<textarea 
						className="new-comment-input form-control" 
						rows="2"
						value={this.state.newCommentText}
						placeholder="Γράψτε ένα σχόλιο..."
						onChange={this.onNewCommentTextChange}>
					</textarea>
					<div className="new-comment-submit btn btn-primary" onClick={this.submitNewComment}>Καταχώρηση</div>
				</div>
				<div className="comment-container d-flex flex-column justify-content-between align-items-center">
				        {this.state.comments.map((item,index) => (
				          <Comment 
				          key={index} 
				          data={item}
				          unmountMe={this.deleteComment}
				          addReplyToComment={this.addReplyToComment}
				          submitReplyToComment={this.submitReplyToComment}
				          articleId={this.props.articleId} 
				          articleType={this.props.articleType}
				          depth={this.props.depth}
				          maxDepth={this.props.maxDepth}
				          />
				        ))}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
 return({
   	currentUser: state.user
 });
};

const CommentList = connect(mapStateToProps)(ConnectedCommentList);

export default CommentList;
