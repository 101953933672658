import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import Article from '../../basicComponents/article';
import Advertisement from '../../basicComponents/advertisement';

class Categories extends Component {
	constructor() {
		super();		
		this.mySwiper2 = React.createRef();
		
	}

	componentDidMount() {
		
	}

	onWindowResize() {
		
        
    }
	
	render() {	
		//var interleaveOffset = 0.5;
		const params = {
			loop: true,
			speed: 1500,	
			autoplay:{
				disableOnInteraction:false
			},			
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
			},			
			fadeEffect: {
				crossFade: true
			},
			slidesPerView: 4,
			spaceBetween: 40,
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween:0,
					allowSlidePrev: true,
					allowSlideNext: true
				}
			}
		};	
		
		if (this.props.articles.length > 0) {
			return (
				<section id="categories">					
					<div className="container" style={{height: '100%',width:'100%'}}>
						<div className="row">			
							<div className={(this.props.isLoggedIn && this.props.adObj) ? "col-12 col-lg-3 d-flex align-items-center" : "d-none"}><Advertisement adObj = {this.props.adObj}/></div>				
							<div className={(this.props.isLoggedIn && this.props.adObj) ? "col-12 col-lg-9 d-flex align-items-center flex-column" : "col-12"}>
								<div className="section-title align-self-start w-100">
									<h2>Κατηγορίες</h2>
								</div>
								<Swiper ref={this.mySwiper2} {...params} key={'swiper2'} id="swiper2">
									{this.props.articles.map((article, index) => {
					                    return <Article {...this.props} articleObj={this.props.articles[index]} class="item-medium" key={article.id}/>			                    
					                })}	
							  	</Swiper>
							</div>
						</div>
				  	</div>
				 </section>				
			);	
		} else {
			return (
				<section id="categories">
					<Swiper ref={this.mySwiper2} {...params} key={'swiper2'}>
						{/*<div><img src={window.serverURL+"/serve_images/articles/February2019/iOla5poIGpgAqa3oG1CR.jpg"} /></div>
						<div>Slide 2</div>
						<div>Slide 3</div>				*/}
					</Swiper>
				</section>				
			);	
		}
		
	}
}

export default Categories;
