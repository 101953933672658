import axios from 'axios';

function trackArticlePageView(event, eventsHistory) {
    //window.dataLayer.push(...event);

    event.data.clientBrowser = getClientBrowser();
    
    axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/track_article', {trackedData: event.data})
	.then(response => {
		//console.log(response);
        return response;
      })
      .then(json => {
      	//console.log(json.data);
    })
	.catch(err => console.log(err));

    return event
}

function getClientBrowser () {

	var clientBrowser;
	if (navigator.userAgent.search("MSIE") >= 0) {
	     clientBrowser = "MSIE";
	}
	else if (navigator.userAgent.search("Edge") >= 0) {
	     clientBrowser = "Edge";
	}
	else if (navigator.userAgent.search("Chrome") >= 0) {
	     clientBrowser = "Chrome";
	}
	else if (navigator.userAgent.search("Firefox") >= 0) {
	     clientBrowser = "Firefox";
	}
	else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
	     clientBrowser = "Safari";
	}
	else if (navigator.userAgent.search("Opera") >= 0) {
	     clientBrowser = "Opera";
	}

	return clientBrowser;
}
 
// Allow `trackArticlePageView` to listen only on `ADD_TO_CART` event
trackArticlePageView.eventType = 'TRACK_VIEW';
 
export default trackArticlePageView;