import React from 'react';
import { Link } from 'react-router-dom';
function Tag(props) {	
	var divStyle = {
        backgroundColor: props.tagColor,
    }
    
    return (
    	<Link to={{pathname:"/category/"+props.catId}}>
        <div className="tag" style={divStyle}>
        	{props.tagName}
        	
        </div>
        </Link>
    );
}

export default Tag;