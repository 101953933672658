import React, { Component } from "react";
import "../../Assets/css/passwordResetPage.min.css";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
//import Event from "../basicComponents/event";
import axios from "axios";
import { Formik, /*FormikProps,*/ Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { captureRequestError } from "../../redux/actions";

const SignupSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, "Πολύ μικρός κωδικός")
		.required("Required"),
	passwordConfirm: Yup.string()
		.min(8, "Πολύ μικρός κωδικός")
		.required("Required")
		.oneOf([Yup.ref("password"), null], "Λάθος στην επαλήθευση κωδικού")
});

const formikInitialValues={
	password:"",
	passwordConfirm:"",
};

class ConnectedPasswordResetPage extends Component {
	constructor() {
		super();
		
		this.state = {};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeUserPassword = this.changeUserPassword.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentDidUpdate() {
		
	}

	handleSubmit(values, { props = this.props, setSubmitting }) {
		//process form submission here

		let resetData = {
			new_password: values.password,
			token: queryString.parse(this.props.location.search).token
		};

		this.changeUserPassword(resetData);

		//done submitting, set submitting to false
		setSubmitting(false);
		return;
	}

	changeUserPassword(resetData){
		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/changePassword', resetData)
	      .then(response => {
	        //console.log(response);
	        return response;
	      })
	      .then(json => {
	        if (json.data.success){
	        	alert('Η αλλαγή του κωδικού σας πραγματοποιήθηκε επιτυχώς.');
	        	window.$('#loginModal').modal('show');
	        } else {
	          	this.props.dispatchError(json.data.data);
	        }
	      })
	      .catch(error => {
	        alert("An Error Occured!" + error);
	      });
	}

	render() {
		return (
			<div className="container-fluid passwordResetPage">
				<h3 className="password-reset-title">Επαναφορά Κωδικού</h3>

				<h5 className="password-reset-info">Πληκτρολογήστε το καινούργιο σας κωδικό και πατήστε "Επαναφορά" για να αποθηκευτούν οι αλλαγές.</h5>

				<Formik initialValues={formikInitialValues} validationSchema={SignupSchema} onSubmit={this.handleSubmit}>
					{({ errors, isSubmitting, values, isValid }) => (
						<Form>
							<div className="form-group">
								<label>
									Νέος κωδικός<span className="form-asterisk">*</span>
								</label>
								<Field required type="password" name="password" className="form-control" />
								<ErrorMessage name="password" render={msg => <div className="customErrorMessage">{msg}</div>} />
							</div>
							<div className="form-group">
								<label>
									Επαλήθευση νέου κωδικού<span className="form-asterisk">*</span>
								</label>
								<Field required type="password" name="passwordConfirm" className="form-control" />
								<ErrorMessage name="passwordConfirm" render={msg => <div className="customErrorMessage">{msg}</div>} />
							</div>
							<button type="submit" disabled={isSubmitting} className="btn btn-primary">
								Επαναφορά
							</button>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		dispatchError: errorData => dispatch(captureRequestError(errorData))
	};
};

const PasswordResetPage = connect(null, mapDispatchToProps)(ConnectedPasswordResetPage);

export default withRouter(PasswordResetPage);
