function getTrackArticlePageView(type, articleId) {
  return {
      type: 'TRACK_VIEW',
      data: {
          type: type,
          articleId: articleId
      }
  }
};

export default getTrackArticlePageView;