/*import $ from 'jquery';*/

/*const getBrowserLocation = () => {
	return (
		$.ajax('http://ip-api.com/json')
		.then(
			function success(response) {
				
			},

			function fail(data, status) {
				console.log('Request failed.  Returned status of',status);
			}
		)
	)
	
}
export default getBrowserLocation;*/
/*export function getBrowserLocation () {
	var response;
	$.ajax('http://ip-api.com/json')
	.then(
		function success(response) {
			
		},

		function fail(data, status) {
			console.log('Request failed.  Returned status of',status);
		}
	);
	
}*/

export function add(x, y) {
  return x + y;
}

function searchTree(element, matchingId, resultCallback){
     if(element.title === matchingId){
          return element;
     }else if (element.children != null){
          var i;
          var result = null;
          for(i=0; result == null && i < element.children.length; i++){
               result = searchTree(element.children[i], matchingId);
          }
          return result;
     }
     return null;
}

export function findComment(comments, id, operation, operationData) {
	return comments.map(comment => {
		//debugger
		let newComment={};
		if(comment.unique_id === id || comment.commentData.id === id){
			if (operation === 'delete') {
				return null;
			}else if (operation === 'addReply') {
				newComment = {
					...comment,
					replies: [
						...comment.replies,
						operationData
					]
				};
				return newComment;
			}else if (operation === 'modify') {
				return {
					...comment,
					...operationData
				};
			}
	     }else if (comment.replies.length > 0){
	        var result = null;

	        result = findComment([...comment.replies], id, operation, operationData);

	        newComment = {
					...comment,
					replies: [
						...result
					]
				};

	        return newComment;
	     }
	     return comment;
	}).filter(element => {
		return element !== null && element !== '';
	});
}

export function recursiveCommentCount(commentTree) {
	return commentTree.reduce((result, comment) => {
		if (comment.replies.length > 0) {
			let ownReplies = comment.replies.length;
			let childReplies = recursiveCommentCount(comment.replies);
			return result + ownReplies + childReplies;
		}else {
			return result;
		}
	},0);
}

export function recursiveCommentNames(commentTree) {
	return commentTree.reduce((result, comment) => {
		if (comment.replies.length > 0) {
			let ownName = comment.userData.full_name;
			let childNames = recursiveCommentNames(comment.replies);
			return [...result,ownName,...childNames];
		}else {
			return [...result, comment.userData.full_name];
		}
	},[]);
}