import React from 'react';
import defaultAvatar from '../../Assets/img/catalog_avatar.jpg';
/*var Highlight = require('react-highlighter');*/
import Highlight from 'react-highlighter';

function getHighlightedText(text, highlight) { 
    var textNormalized = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    var highlightNormalized = highlight.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    var parts = (text) ? text.split(new RegExp(`(${highlight})`, 'gi')) : [];
    var partsNormalized = (textNormalized) ? textNormalized.split(new RegExp(`(${highlightNormalized})`, 'gi')) : []; 
    var indexesOfPartsNormalized = [];

    //console.log(partsNormalized, highlightNormalized, parts)   
    return <span> { partsNormalized.map((part, i) => 

        <span className={ (part === highlightNormalized ) ? "highlightedTextSearch" : "" } key={Math.random()+'_'+i} >
            { parts[i] }
        </span>)
    } </span>;
}

function Catalog(props) {   
    let self = {};
    
    self.title = props.catalogObj.title;
    self.address = props.catalogObj.address;
    self.image = (props.catalogObj.image) ? window.serverURL+"/serve_images/"+props.catalogObj.image : defaultAvatar;
    self.phone = (props.catalogObj.phone) ? props.catalogObj.phone : '';   
    self.category = (props.catalogObj.category && props.catalogObj.type !== 3) ? props.catalogObj.category : '';
    self.email = (props.catalogObj.email) ? props.catalogObj.email : '';
    self.emailsArray = self.email.split(/[,|/]/);    
    self.emailsEl = self.emailsArray.map((email, index) => {
                        var mailToReturn;                        
                        if (index < (self.emailsArray.length-1) ) {
                            mailToReturn = <span><a key={props.catalogObj.id+"_"+index} href={"mailto:"+email.trim()}><Highlight matchElement="span" ignoreDiacritics matchClass="highlightedTextSearch" search={props.searchValue}>{email.trim()}</Highlight></a><span> | </span></span>;                            
                        } else {                            
                            mailToReturn = <span><a key={props.catalogObj.id+"_"+index} href={"mailto:"+email.trim()}><Highlight matchElement="span" ignoreDiacritics matchClass="highlightedTextSearch" search={props.searchValue}>{email.trim()}</Highlight></a></span>;                            
                        }                        
                        return mailToReturn;                        
                    });
    self.twitter = (props.catalogObj.twitter) ? <a href={props.catalogObj.twitter} target="_blank" rel="noopener noreferrer nofollow" aria-label="twitter"><div className="d-none">hidden text</div><i className="fa fa-twitter" aria-hidden="true"></i></a> : '';
    self.instagram = (props.catalogObj.instagram) ? <a href={props.catalogObj.instagram} target="_blank" rel="noopener noreferrer nofollow" aria-label="instagram"><div className="d-none">hidden text</div><i className="fa fa-instagram" aria-hidden="true"></i></a> : '';
    self.website = (props.catalogObj.website) ? <a href={props.catalogObj.website} target="_blank" rel="noopener noreferrer nofollow" aria-label="website"><div className="d-none">hidden text</div><i className="fa fa-globe" aria-hidden="true"></i></a> : '';
    self.facebook = (props.catalogObj.facebook) ? <a href={props.catalogObj.facebook} target="_blank" rel="noopener noreferrer nofollow" aria-label="facebook"><div className="d-none">hidden text</div><i className="fa fa-facebook" aria-hidden="true"></i></a> : '';
    self.linkedin = (props.catalogObj.linkedin) ? <a href={props.catalogObj.linkedin} target="_blank" rel="noopener noreferrer nofollow" aria-label="linkedin"><div className="d-none">hidden text</div><i className="fa fa-linkedin" aria-hidden="true"></i></a> : '';
    self.hcp_profile_link = (props.catalogObj.hcp_profile_link) ? <a href={props.catalogObj.hcp_profile_link} target="_blank" rel="noopener noreferrer nofollow" aria-label="hcp profile link"><div className="d-none">hidden text</div><i className="fa fa-vcard" aria-hidden="true"></i></a> : '';
    self.image_alt = props.catalogCategory + " | " + self.title + " | All4Diabetes";
    return (           
        <div className="catalog d-flex" key={props.catalogObj.id}>
            <div className="catalog-left-container">
                <div className="catalog-img-container d-flex align-items-center">
                    <img src={self.image} alt={self.image_alt} className="img-fluid m-auto"/>
                </div>
            </div>
            <div className="catalog-right-container">
                <h3 className="catalog-title"><Highlight matchElement="span" ignoreDiacritics matchClass="highlightedTextSearch" search={props.searchValue}>{self.title}</Highlight></h3>
                <div className="catalog-address">
                    <i className="fa fa-map-o" aria-hidden="true"></i> Διεύθυνση: <Highlight matchElement="span" ignoreDiacritics matchClass="highlightedTextSearch" search={props.searchValue}>{self.address}</Highlight>
                </div>
                <div className="catalog-phones">
                    <i className="fa fa-phone" aria-hidden="true"></i> Τηλέφωνα:  <Highlight matchElement="span" ignoreDiacritics matchClass="highlightedTextSearch" search={props.searchValue}>{self.phone}</Highlight>
                </div>
                { 
                    (self.category !== '') 
                    ?   <div className="catalog-category">
                            <i className="fa fa-list" aria-hidden="true"></i> Κατηγορία: <Highlight matchElement="span" ignoreDiacritics matchClass="highlightedTextSearch" search={props.searchValue}>{self.category}</Highlight>
                        </div>
                    : ''
                }
                
                { 
                    (self.email !== '') 
                    ?   <div className="catalog-email">
                            <i className="fa fa-envelope-o" aria-hidden="true"></i> Email:  {self.emailsEl}                             
                                
                        </div>
                    : ''
                }
                <div className="catalog-social-container d-flex justify-content-end">
                    {self.hcp_profile_link} {self.website} {self.facebook} {self.linkedin} {self.twitter} {self.instagram} 
                </div>
            </div>
                    
            
        </div>
        
    );
}

export default Catalog;