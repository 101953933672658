import React, {Component} from 'react';
import '../../Assets/css/eventspage.min.css';
import Event from '../basicComponents/event';
import EventFilters from '../basicComponents/eventFilters';
import axios from 'axios';
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {Helmet} from "react-helmet";

class ConnectedEventsPage extends Component {

	constructor() {
		super();
		
		this.state = {
			filterBy: "next",
			events:[],
			allEvents:[],
			items: [],
			chunks:[],
		    isLoading: true,
		    cursor: 0,
		    hasMore: true,
		};		

		this.handleEventSorting = this.handleEventSorting.bind(this);
		this.loadMore = this.loadMore.bind(this);
		this.makeChunks = this.makeChunks.bind(this);
	}

	componentDidMount() {		
		window.scrollTo(0,0);	
		this.getAllEvents();

	}
	
	componentDidUpdate() {				
			
	}	

	getAllEvents() {		
		let allEvents = [];
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/events')
	      .then(res => {
	        allEvents = res.data.data;	
	        //var eventsSorted = allEvents.sort((a, b) => (a.date_start > b.date_start) ? -1 : 1);  

	        var eventsSorted = allEvents.sort((a, b) => (a.date_start < b.date_start) ? -1 : 1);   
	        var eventsFiltered = eventsSorted.filter(
	        	function (a) {
	        		
	        		return Date.now() < Date.parse(a.date_start);
	        	}
	        ); 	        

	        this.setState({
	        	allEvents : allEvents,
	        	events : eventsFiltered	        	
			});

			this.makeChunks();
	      	this.loadMore();
			
	    }).catch(err => 
	    	console.log('Login: ', err)
	    );

	}

	handleFilterChange = (filter) => {	
		var filteredEvents;
		
		if (filter.by == "next") {
			filteredEvents = this.state.allEvents.filter(
	        	function (a) {
	        		
	        		return Date.now() < Date.parse(a.date_start);
	        	}
	        );
		} else {
			filteredEvents = this.state.allEvents.filter(
	        	function (a) {
	        		
	        		return Date.now() >= Date.parse(a.date_start);
	        	}
	        );
		}

		var filteredSortedEvents = this.handleEventSorting(filteredEvents, filter);
		

		this.setState({
			events: filteredEvents,
			filterBy: filter.by
		},() => {			
			this.makeChunks();
			this.loadMore();
		});
	}

	handleEventSorting(eventsToSort, filter) {
		let isReversed = filter.order === "descending" ? false : true;
		

		eventsToSort.sort(function(a, b) {
			var keyA,keyB;
			keyA = new Date(a.date_start);
			keyB = new Date(b.date_start);

			// Compare the 2 dates

			if (!isReversed) {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			} else {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			}

			return 0;
		});

		return eventsToSort;
	}


	makeChunks() {
		var i,j,chunk = 5;
		/* this.setState({
			chunks:[],
			items:[]
		})*/
		this.state.chunks.length = 0;
		this.state.items.length =0;
		this.state.cursor=0;		
		for (i=0,j=this.state.events.length; i<j; i+=chunk) {
		    this.state.chunks.push(this.state.events.slice(i,i+chunk));		    
		}
		
		if (this.state.chunks.length>1) {
			this.state.hasMore=true;
		}

	
 	}

	loadMore() {
		if (this.state.items.length >= this.state.events.length) {
			this.setState({ hasMore: false });
			return;
		}
	    this.setState({ isLoading: true });
	    this.setState({
			items: [...this.state.items, ...this.state.chunks[this.state.cursor]],
			cursor: (this.state.cursor+1),
			isLoading: false
		});
	   
	}
	
	render() {
		
		if (this.state.events) {			
			if (this.state.events.length>0) {
				return (
					<div className="container-fluid eventsPage">
						<Helmet
							title={"Εκδηλώσεις για τον Διαβήτη | All4Diabetes"}
							meta={[
							
							]}
						/>
						<h3 className="event-page-title d-flex justify-content-center align-items-center">ΕΚΔΗΛΩΣΕΙΣ</h3>
						<EventFilters onSelectFilter={this.handleFilterChange} />
						<InfiniteScroll
							dataLength={this.state.items.length}
							next={this.loadMore}
							hasMore={this.state.hasMore}
							loader={<h3 style={{ textAlign: "center" }}>
							<b>Φόρτωση περισσότερων...</b></h3>}
							endMessage={
							<p style={{ textAlign: "center" }}>
							<b>Τέλος {this.state.filterBy == 'next' ? 'επερχόμενων' : 'προηγούμενων'} εκδηλώσεων!</b>
							</p>
							}

						>
				        	<div className="container d-flex flex-row justify-content-between flex-wrap">
								{this.state.items.map((event, index) => {
									if (!this.props.currentUser.isLoggedIn && parseInt(event.for_registered_only) ===1 ) {
										return null;										
									} 
									return <Event eventObj={event} parentClassName="event-page" class="event" key={event.id}/>;
										
								})}	
							</div>					
						
						</InfiniteScroll>
					</div>
					
				);
			}
		}		
		
		return (
			
			<div className="container-fluid eventsPage">
				<Helmet
					title={"Εκδηλώσεις για τον Διαβήτη | All4Diabetes"}
					meta={[
					
					]}
				/>
				<h3 className="event-page-title d-flex justify-content-center align-items-center">ΕΚΔΗΛΩΣΕΙΣ</h3>
			</div>
		);
		
	}
}

const mapStateToProps = (state, ownProps) => {
	return({
		currentUser: state.user
	});
};

const EventsPage = connect(mapStateToProps)(ConnectedEventsPage);

export default EventsPage;
