import React from 'react';
import defaultEventImage from '../../Assets/img/default_event.jpg';
function Event(props) {   
    let self = {};
    //console.log(props);
    self.title = props.eventObj.title;
    self.place = props.eventObj.place;
    self.location = props.eventObj.location;
    self.image = (props.eventObj.image) ? window.serverURL+"/serve_images/"+props.eventObj.image : defaultEventImage;
    let dateEnd = (props.eventObj.date_end) ? props.eventObj.date_end : '';
    let dateStart = (props.eventObj.date_start) ? props.eventObj.date_start : '';
    var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var dateStartFormatted = new Date(dateStart).toLocaleDateString('el-EL', dateOptions);
    var dateEndFormatted = new Date(dateEnd).toLocaleDateString('el-EL', dateOptions);
    self.date = (dateStartFormatted===dateEndFormatted) ? dateStartFormatted : dateStartFormatted + " - " + dateEndFormatted;
    
    self.eventType = props.eventObj.event_type_name;
    self.notes = props.eventObj.notes;
    self.organization = props.eventObj.organization;    
    self.url = (props.eventObj.url !== "" && props.eventObj.url !== undefined && props.eventObj.url !== null) ? props.eventObj.url : "";
    self.image_alt = "Εκδηλώσεις για τον Διαβήτη | " + self.title +" | All4Diabetes";
    return (           
        <div className="event container">
            <div className="row">
                <div className="col-12 col-md-3 event-image-container">
                    {(self.url !== "") ? 
                        <a href={self.url} target="_blank" rel="noopener noreferrer nofollow">
                            <img src={self.image} alt={self.image_alt} className="img-fluid m-auto" style={{width:"100%"}}/>
                        </a>
                        :
                        <img src={self.image} alt={self.image_alt} className="img-fluid m-auto" style={{width:"100%"}}/>
                    }
                </div>
                <div className="col-12 col-md-9 event-info-container">
                    <div className="event-title">
                        {(self.url !== "") ? 
                            <a href={self.url} target="_blank" rel="noopener noreferrer nofollow">{self.title}</a>
                            :
                            self.title
                        }
                    </div>
                    <div className="event-date">{self.date}</div>
                    <div className="event-location">{ (self.place)?self.place+", " : '' } { (self.location)?self.location : ''}</div>
                    <div className="event-type">{ (self.eventType) ? "Κατηγορία: "+self.eventType : ''}</div>
                    <div className="event-organization">{ (self.organization) ? "Διοργάνωση: "+self.organization : ''}</div>
                    <div className="event-notes">{ (self.notes) ? "Σημειώσεις: "+self.notes : ''}</div>
                    <div className="event-more-info">
                        {(self.url !== "") ? 
                            <a href={self.url} target="_blank" rel="noopener noreferrer nofollow">Περισσότερες πληροφορίες</a>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
            
        </div>
        
    );
}

export default Event;