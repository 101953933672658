import React, {Component} from 'react';
import loginBg from '../../Assets/img/login_modal_bg.png';
import logo from '../../Assets/img/logo.png';
import $ from 'jquery';
import axios from 'axios';
import { connect } from "react-redux";
import { loginUser, captureRequestError } from "../../redux/actions";

class ConnectedLoginModal extends Component {

	constructor() {
        super()

        this.state = {
			alertStyle: {}
		};	

        this.handleSubmit = this.handleSubmit.bind(this);
        this.loginUser = this.loginUser.bind(this);
    }

    componentDidMount() {  
    	//this.loginUser('testemail2@email.com','testpassword2');
		//this.registerUser('testuser2','testemail2@email.com','testpassword2');

		window.$('#loginFailAlert').alert();

		/*this.props.cookies.set('name', 'Ross', { path: '/' });

		let cookieName = this.props.cookies.get('name');

		console.log(cookieName);*/

    }

	handleSubmit(e) {	    
	    e.preventDefault();

	    const form = e.target;
    	const data = $(form).serializeArray();    	

    	this.loginUser(data[0].value,data[1].value,data[2] ? true : false);	
	}

	handleLoginSuccess(success,rememberMe,userData){
		if (success) {
    		window.$('#loginModal').modal('hide');

    		if (rememberMe) {
    			this.props.cookies.set('userAuthToken', JSON.stringify({
    				userEmail: userData.data.email,
					token: userData.token,
					rememberUser: true
    			}), { 
    				path: '/',
    				maxAge: 60 * 60 * 24 * 30 * 6
    			});
    		}else{
    			this.props.cookies.set('userAuthToken', JSON.stringify({
    				userEmail: userData.data.email,
					token: userData.token,
					rememberUser: false
    			}), { 
    				path: '/',
    				maxAge: 60 * 60 * 8
    			});
    		}
    	}
	}

	/*MT might be working*/

	loginUser(email, password, rememberMe) {
	    
	    var formData = new FormData();
	    formData.append("email", email);
		formData.append("password", password);
		formData.append("remember_me", rememberMe);

	    axios
	      .post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/login', formData)
	      .then(response => {
	        //console.log(response);
	        return response;
	      })
	      .then(json => {
	      	let userData = {};
	        if (json.data.success) {
	          //alert("Login Successful!");
	          
	          userData.data = json.data.userData.data;
	          userData.token = json.data.userData.token;
	          
	          this.props.dispatchUserData(userData);

	          /*let userData = {
	            name: json.data.data.name,
	            id: json.data.data.id,
	            email: json.data.data.email,
	            auth_token: json.data.data.auth_token,
	            timestamp: new Date().toString()
	          };
	          let appState = {
	            isLoggedIn: true,
	            user: userData
	          };*/
	          // save app state with user date in local storage
	          //localStorage["appState"] = JSON.stringify(appState);
	          /*this.setState({
	            isLoggedIn: appState.isLoggedIn,
	            user: appState.user
	          });*/
	        } else {
				//alert("Login Failed!");
				if (json.data.code === 'login-001' || json.data.code === 'login-002') {
					this.setState({
						alertStyle: {
							display: 'block'
						}
					});
				}else{
					this.props.dispatchError(json.data.data);
				}
	        }
	        this.handleLoginSuccess(json.data.success,rememberMe,userData);
	      })
	      .catch(error => {
			alert(`An Error Occured! ${error}`);
	      });
	  }

	/*MT end*/

	render() {
		const divStyle = {			
	        background: 'url('+loginBg+') no-repeat left center',
	        backgroundSize: 'cover'        
	    };
		return(
			<div className="modal" id="loginModal">
				<div className="modal-dialog">
					<div className="modal-content">	
						<div className="modal-body">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
					        	<span aria-hidden="true">&times;</span>
					        </button>
							<div className="container-fluid">
								<div className="row">
									<div className="col-12 col-md-4 order-1 order-md-1" style={{zIndex:1}}>
										<div className="row">
											<div className="container-fluid">
												<div className="logo-line-left"></div>
												<div className="loginLogoContainer pull-right" style={{position:'relative'}}>
													<img id="loginLogo" alt="All4Diabetes logo" src={logo} className="img-fluid pull-right" width="240" style={{marginRight:-90}}/>
												</div>
												<div className="logo-line-right"></div>
											</div>
										</div>
										<div className="row">
											<div className="container">
												<div className="d-flex flex-column login-content-left justify-content-between">
													<div className="alert alert-danger .alert-dismissible" style={this.state.alertStyle} id="loginFailAlert" role="alert">
													  Λάθος email ή κωδικός 
													  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
													    <span aria-hidden="true">&times;</span>
													  </button>
													</div>
													<p> Παρακαλώ συνδεθείτε στον λογαριασμό σας</p>
													<form onSubmit={this.handleSubmit}>
														<div className="form-group">
															<label htmlFor="loginInputEmail">Email</label>
															<input type="email" name="email" className="form-control" id="loginInputEmail" placeholder="Εισαγωγή email" />															
														</div>
														<div className="form-group">
															<label htmlFor="loginInputPassword">Κωδικός</label>
															<input type="password" name="password" className="form-control" id="loginInputPassword" placeholder="Εισαγωγή κωδικού" />
														</div>
														<div className="form-group">
															<div className="form-check">
																<input type="checkbox" name="rememberMe" className="form-check-input" id="loginCheck" />
																<label className="form-check-label" htmlFor="loginCheck">Να με θυμάσαι</label>
																<div className="resetPasswordButton pull-right" id="forgot-password" data-toggle="modal" data-target="#resetPasswordModal" data-dismiss="modal">Ξέχασα τον κωδικό μου</div>
															</div>
														</div>
														<p className="form_error loginError"></p>
														<button type="submit" className="btn btn-primary d-flex m-auto hcp_connect">Σύνδεση</button>
														<div className="alert alert-danger" role="alert">
									                        <p id="passwordError" className="form_error"></p>
									                        <p id="emailError" className="form_error"></p>
									                    </div>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-8 d-md-block order-2 order-md-2" style={divStyle}>
										<div className="container">											
											<div className="d-flex flex-column login-content-right justify-content-between">
												<h2>Καλώς ήρθατε</h2>
												<p>Δεν έχετε λογαριασμό;</p>
												<p>Δημιουργήστε έναν πατώντας στον παρακάτω σύνδεσμο</p>
												<div data-toggle="modal" data-target="#registerModal" data-dismiss="modal" className="registerButton align-self-start">Εγγραφή</div>
											</div>											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
 return({
   state: state,
   cookies: ownProps.cookies,
 });
};

const mapDispatchToProps = (dispatch) => {
  return {
	dispatchUserData: userData => dispatch(loginUser(userData)),
	dispatchError: errorData => dispatch(captureRequestError(errorData))
  };
}

const LoginModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginModal);

export default LoginModal;
