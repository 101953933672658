import React, {Component} from 'react';
import axios from 'axios';
import { Formik, /*FormikProps,*/ Form, Field/*, ErrorMessage*/  } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';

const formikInitialValues={
	name:"",
	email:"",
	phone:"",
	mobile:"",	
	message:"",
	privacy:false,
	terms:false
}

const SignupSchema = Yup.object().shape({  	
  name: Yup.string()
  	.min(3, 'Όνομα μικρότερο από 3 χαρακτήρες')
    .required('Required'),  
  privacy: Yup.boolean(true).required('Required'),
  terms: Yup.boolean(true).required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  message: Yup.string()
  	.min(8, 'Μήνυμα μικρότερο από 8 χαρακτήρες')
    .required('Required'),
});

const recaptchaRef = React.createRef();

class AskTheExpertModal extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			isEmailSending:false,
			emailWasSent:"",
			verifiedReCaptcha:false
		};		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.sendContactEmail = this.sendContactEmail.bind(this);
		this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
	}

	componentDidMount() {		
		window.scrollTo(0,0);
	}
	
	componentDidUpdate() {				
			
	}

	sendContactEmail(formData,setSubmitting) {
		/*for (var pair of formData.entries()) {
		    console.log(pair[0]+ ', ' + pair[1]); 
		}*/
		axios
			.post(window.serverURL + "/all4diabetes/admin/public/admin/api/ajax_requests/askTheExpertForm", formData)
			.then(response => {
				//console.log(response);
				return response;
			})
			.then(json => {
				
				if (json.data.status === "success") {
					this.setState(
						{
							emailWasSent: "true",
							isEmailSending:false,
							verifiedReCaptcha:false
						}
					);
					window.$('#askTheExpertModal').modal('hide');
    				window.$('#askTheExpertSuccessModal').modal('show');
				} else {
					this.setState(
						{
							emailWasSent: "false",
							isEmailSending:false,
							verifiedReCaptcha:false
						}
					);
					window.$('#askTheExpertModal').modal('hide');
					window.$('#askTheExpertErrorModal').modal('show');
				}
				setSubmitting(false)
				recaptchaRef.current.reset();
			})
			.catch(error => {
				this.setState(
					{
						emailWasSent: "false",
						isEmailSending:false,
						verifiedReCaptcha:false
					}
				)
				window.$('#askTheExpertModal').modal('hide');
				window.$('#askTheExpertErrorModal').modal('show');
				setSubmitting(false)
				recaptchaRef.current.reset();
			});
		
	}

	handleSubmit (values, { 
      props = this.props, 
      setSubmitting 
    }) { 
    	var self = this;
    	self.setState({
    		isEmailSending:true
    	})
    	setSubmitting(true);
		let formData = new FormData();

		for (var key in values) {
			formData.append(key, values[key]);
		}

		formData.set('name',values["name"]);
		formData.set('email',values["email"]);
		formData.set('phone',values["phone"]);
		formData.set('mobile',values["mobile"]);
		formData.set('message',values["message"]);
		formData.set('article_id',this.props.articleId);
		formData.set('hcp_id',this.props.hcpInfo.id);
		
		//formData.set('secondarySpecialty',specialties.find(obj => obj.value == values.secondarySpecialty).label);

		this.sendContactEmail(formData,setSubmitting);
		/*setTimeout(function(){
			setSubmitting(false);
		}, 5000)*/
		//done submitting, set submitting to false
		
		return;
	}

	onChangeRecaptcha(value) {
		if (value) {
			this.setState({
				verifiedReCaptcha:true
			})	
		} else {
			this.setState({
				verifiedReCaptcha:false
			})
		}
		
	}
	
	render() {
		return (
			<div>
				<div className="modal" id="askTheExpertModal">
					<div className="modal-dialog">
						<div className="modal-content">	
							<div className="modal-header">							
								<div className="modal-title">Συμπληρώστε τη φόρμα και θα επικοινωνήσει μαζί σας ο/η {this.props.hcpInfo.full_name}</div>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
						        	<span aria-hidden="true">&times;</span>
						        </button>
							</div>
							<div className="modal-body">
								
								<div className="container-fluid">
									<div className="row">
										<div className="col-12 " style={{zIndex:1}}>
											<div className="row">
												<div className="container content-container">
													<div className="d-flex flex-column justify-content-between">
				
														<Formik
														initialValues={formikInitialValues}
												        validationSchema={SignupSchema}
												        onSubmit={this.handleSubmit}
												        >
												        {({errors, isSubmitting, values, isValid }) => (				        	
												        	<Form>
												        		<div className="container form-container">
													        		<div className="form-group row">
																		<div className="col-12">
																			<Field aria-label="Full name" required type="text" name="name" className='form-control' placeholder="Το όνομά σας"/> 
																		</div>
																	</div>
																	<div className="form-group row">
																		<div className="col-12">
																			<Field aria-label="Email" required type="email" name="email" className='form-control' placeholder="Το e-mail σας" /> 
																		</div>
																	</div>
																	<div className="form-group row">
																		<div className="col-12">
																			<Field aria-label="phone" type="text" name="phone" className='form-control' placeholder="Τηλέφωνο Επικοινωνίας (σταθερό)" /> 
																		</div>
																	</div>
																	<div className="form-group row">
																		<div className="col-12">
																			<Field aria-label="mobile" type="text" name="mobile" className='form-control' placeholder="Τηλέφωνο Επικοινωνίας (κινητό)" /> 
																		</div>
																	</div>
																	<div className="form-group row">
																		<div className="col-12">
																			<Field aria-label="Message" required type="text" component="textarea" name="message" className='form-control' placeholder="Μήνυμα" /> 
																		</div>
																	</div>
																	<div className="form-group row">
																		<div className="form-check">
																			<label className="form-check-label">
																			<Field type="checkbox" name="terms" className='form-check-input' required/>
																			Αποδέχομαι τους <Link to="/terms" target="_blank" >Όρους χρήσης</Link>
																			</label>
																			
																		</div>
																	</div>
																	<div className="form-group row">
																		<div className="form-check">
																			<label className="form-check-label">
																			<Field type="checkbox" name="privacy" className='form-check-input' required/>
																			Συμφωνώ με την <Link to="/privacy_policy" target="_blank" >Πολιτική Απορρήτου</Link>
																			</label>
																			
																		</div>
																	</div>
																	{/*console.log(isSubmitting||!isValid||!this.state.verifiedReCaptcha)*/}
																	<ReCAPTCHA
																		ref={recaptchaRef}
																	    sitekey="6LdhOsAUAAAAAFvg5E_koU6je3ql1UrBsyk2QHje"
																	    onChange={this.onChangeRecaptcha}
																	    size="compact"
																	/>
																	
																	<div className="text-right">
																		<button 
																			type="submit" 
																			disabled={isSubmitting||!isValid||!this.state.verifiedReCaptcha}
																			className="btn btn-primary">
																			Αποστολή
																		</button>
													                </div>
																</div>
															</Form>
															)}
														</Formik>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				<div className="modal" tabIndex="-1" role="dialog" id="askTheExpertSuccessModal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Επιτυχής αποστολή</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>Η ερώτηση σας έχει αποσταλεί στο Επαγγελματία Υγείας, ο οποίος θα επικοινωνήσει απευθείας μαζί σας.</p>
								<p>Σας Ευχαριστούμε!</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">ΟΚ</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal" tabIndex="-1" role="dialog" id="askTheExpertErrorModal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Ανεπιτυχής προσπάθεια επικοινωνίας</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>Υπήρξε πρόβλημα στην προσπάθεια επικοινωνίας με τον ειδικό. Παρακαλούμε προσπαθήστε αργότερα.</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">ΟΚ</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		
	}
}

export default AskTheExpertModal;