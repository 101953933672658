import React, {Component} from 'react';
//import $ from 'jquery';
import { Formik, /*FormikProps,*/ Form, Field, ErrorMessage  } from 'formik';
import SelectField from './selectField';
//import CreatableSelectField from './creatableSelectField';
import * as Yup from 'yup';
import axios from 'axios';
//import CreatableSelect from 'react-select/creatable';
import { Link } from 'react-router-dom';

const specialties = [
  { label: "Γενικός Ιατρός", value: 1 },
  { label: "Διαιτολόγος", value: 2 },
  { label: "Ενδοκρινολόγος", value: 3 },
  { label: "Καρδιολόγος", value: 4 },
  { label: "Λοιπές Ειδικότητες", value: 5 },
  { label: "Νοσηλευτής", value: 6 },
  { label: "Παθολόγος", value: 7 },
  { label: "Παιδίατρος", value: 8 },
  { label: "Φαρμακοποιός", value: 9 },
  { label: "Χειρουργός", value: 10 },
  { label: "Διατροφολόγος", value: 11 },
];

const counties = [
    { label: "Αιτωλοακαρνανίας", value: 1 },
	{ label: "Αργολίδας", value: 2 },
	{ label: "Αρκαδίας", value: 3 },
	{ label: "Άρτας", value: 4 },
	{ label: "Αττικής", value: 5 },
	{ label: "Αχαΐας", value: 6 },
	{ label: "Βοιωτίας", value: 7 },
	{ label: "Γρεβενών", value: 8 },
	{ label: "Δράμας", value: 9 },
	{ label: "Δωδεκανήσου", value: 10 },
	{ label: "Έβρου", value: 11 },
	{ label: "Εύβοια", value: 12 },
	{ label: "Ευρυτανίας", value: 13 },
	{ label: "Ζακύνθου", value: 14 },
	{ label: "Ηλείας", value: 15 },
	{ label: "Ημαθίας", value: 16 },
	{ label: "Ηρακλείου", value: 17 },
	{ label: "Θεσπρωτίας", value: 18 },
	{ label: "Θεσσαλονίκης", value: 19 },
	{ label: "Ιωαννίνων", value: 20 },
	{ label: "Καβάλας", value: 21 },
	{ label: "Καρδίτσας", value: 22 },
	{ label: "Καστοριάς", value: 23 },
	{ label: "Κέρκυρας", value: 24 },
	{ label: "Κεφαλληνίας", value: 25 },
	{ label: "Κιλκίς", value: 26 },
	{ label: "Κοζάνης", value: 27 },
	{ label: "Κορινθίας", value: 28 },
	{ label: "Κυκλάδων", value: 29 },
	{ label: "Λακωνίας", value: 30 },
	{ label: "Λάρισας", value: 31 },
	{ label: "Λασιθίου", value: 32 },
	{ label: "Λέσβου", value: 33 },
	{ label: "Λευκάδας", value: 34 },
	{ label: "Μαγνησίας", value: 35 },
	{ label: "Μεσσηνίας", value: 36 },
	{ label: "Ξάνθης", value: 37 },
	{ label: "Πέλλας", value: 38 },
	{ label: "Πιερίας", value: 39 },
	{ label: "Πρέβεζας", value: 40 },
	{ label: "Ρεθύμνης", value: 41 },
	{ label: "Ροδόπης", value: 42 },
	{ label: "Σάμου", value: 43 },
	{ label: "Σερρών", value: 44 },
	{ label: "Τρικάλων", value: 45 },
	{ label: "Φθιώτιδας", value: 46 },
	{ label: "Φλώρινας", value: 47 },
	{ label: "Φωκίδας", value: 48 },
	{ label: "Χαλκιδικής", value: 49 },
	{ label: "Χανίων", value: 50 },
	{ label: "Χίου", value: 51 },
];

const cities = [
	{ label: "Άγιος", value: 1 },
	{ label: "Άγιος Στέφανος", value: 2 },
	{ label: "Αγριά", value: 3 },
	{ label: "Αγρίνιο", value: 4 },
	{ label: "Αθήνα", value: 5 },
	{ label: "Αίγινα", value: 6 },
	{ label: "Αίγιο", value: 7 },
	{ label: "Αλεξάνδρεια", value: 8 },
	{ label: "Αλεξανδρούπολη", value: 9 },
	{ label: "Αλμυρός", value: 10 },
	{ label: "Αμαλιάδα", value: 11 },
	{ label: "Αμπελώνας", value: 12 },
	{ label: "Άμφισσα", value: 13 },
	{ label: "Ανάβυσσος", value: 14 },
	{ label: "Ανατολή", value: 15 },
	{ label: "Άνοιξη", value: 16 },
	{ label: "Άργος", value: 17 },
	{ label: "Άργος Ορεστικό", value: 18 },
	{ label: "Αργοστόλι", value: 19 },
	{ label: "Αριδαία", value: 20 },
	{ label: "Άρτα", value: 21 },
	{ label: "Άρτεμη", value: 22 },
	{ label: "Αρχάγγελος", value: 23 },
	{ label: "Ασβεστοχώρι", value: 24 },
	{ label: "Αυλώνας", value: 25 },
	{ label: "Αφάντου", value: 26 },
	{ label: "Βάρη", value: 27 },
	{ label: "Βασιλικό", value: 28 },
	{ label: "Βέροια", value: 29 },
	{ label: "Βόλος", value: 30 },
	{ label: "Βροντάδος", value: 31 },
	{ label: "Γάζι", value: 32 },
	{ label: "Γαργαλιάνοι", value: 33 },
	{ label: "Γαστούνη", value: 34 },
	{ label: "Γέρακας", value: 35 },
	{ label: "Γιαννιτσά", value: 36 },
	{ label: "Γιάννουλη", value: 37 },
	{ label: "Γλυκά", value: 38 },
	{ label: "Γρεβενά", value: 39 },
	{ label: "Διαβατά", value: 40 },
	{ label: "Διδυμότειχο", value: 41 },
	{ label: "Διόνυσος", value: 42 },
	{ label: "Δράμα", value: 43 },
	{ label: "Δροσιά", value: 44 },
	{ label: "Δροσιά", value: 45 },
	{ label: "Έδεσσα", value: 46 },
	{ label: "Ελασσόνα", value: 47 },
	{ label: "Ελευσίνα", value: 48 },
	{ label: "Επανομή", value: 49 },
	{ label: "Ερμούπολη", value: 50 },
	{ label: "Ζάκυνθος", value: 51 },
	{ label: "Ζεφύρι", value: 52 },
	{ label: "Ηγουμενίτσα", value: 53 },
	{ label: "Ηράκλειο", value: 54 },
	{ label: "Θέρμη", value: 55 },
	{ label: "Θεσσαλονίκη", value: 56 },
	{ label: "Θήβα", value: 57 },
	{ label: "Θρακομακεδόνες", value: 58 },
	{ label: "Ιαλυσός", value: 59 },
	{ label: "Ιεράπετρα", value: 60 },
	{ label: "Ιωάννινα", value: 61 },
	{ label: "Καβάλα", value: 62 },
	{ label: "Καλαμάτα", value: 63 },
	{ label: "Καλαμπάκα", value: 64 },
	{ label: "Καλύβια", value: 65 },
	{ label: "Κάλυμνος", value: 66 },
	{ label: "Καρδίτσα", value: 67 },
	{ label: "Καρπενήσι", value: 68 },
	{ label: "Κάρυστος", value: 69 },
	{ label: "Καστοριά", value: 70 },
	{ label: "Κατερίνη", value: 71 },
	{ label: "Κάτω Αχαΐα", value: 72 },
	{ label: "Κερατέα", value: 73 },
	{ label: "Κέρκυρα", value: 74 },
	{ label: "Κιάτο", value: 75 },
	{ label: "Κιλκίς", value: 76 },
	{ label: "Κοζάνη", value: 77 },
	{ label: "Κομοτηνή", value: 78 },
	{ label: "Κόρινθος", value: 79 },
	{ label: "Κορωπί", value: 80 },
	{ label: "Κουνουπιδιανά", value: 81 },
	{ label: "Κουφάλια", value: 82 },
	{ label: "Κρεμαστή", value: 83 },
	{ label: "Κρύα Βρύση", value: 84 },
	{ label: "Κρυονέρι", value: 85 },
	{ label: "Κυπαρισσία", value: 86 },
	{ label: "Κως", value: 87 },
	{ label: "Λαγκαδάς", value: 88 },
	{ label: "Λαμία", value: 89 },
	{ label: "Λάρισα", value: 90 },
	{ label: "Λαύριο", value: 91 },
	{ label: "Λεοντάρι", value: 92 },
	{ label: "Λευκάδα", value: 93 },
	{ label: "Λιβαδειά", value: 94 },
	{ label: "Λιτόχωρο", value: 95 },
	{ label: "Λουτράκι", value: 96 },
	{ label: "Μακροχώρι", value: 97 },
	{ label: "Μάνδρα", value: 98 },
	{ label: "Μαραθώνας", value: 99 },
	{ label: "Μαρκόπουλο", value: 100 },
	{ label: "Μεγαλόπολη", value: 101 },
	{ label: "Μέγαρα", value: 102 },
	{ label: "Μεσολόγγι", value: 103 },
	{ label: "Μεσσήνη", value: 104 },
	{ label: "Μοίρες", value: 105 },
	{ label: "Μουρνιές", value: 106 },
	{ label: "Μύρινα", value: 107 },
	{ label: "Μυτιλήνη", value: 108 },
	{ label: "Νάξος", value: 109 },
	{ label: "Νάουσα", value: 110 },
	{ label: "Ναύπακτος", value: 111 },
	{ label: "Ναύπλιο", value: 112 },
	{ label: "Νέα", value: 113 },
	{ label: "Νέα Αγχίαλος", value: 114 },
	{ label: "Νέα Αρτάκη", value: 115 },
	{ label: "Νέα Καλλικράτεια", value: 116 },
	{ label: "Νέα Μηχανιώνα", value: 117 },
	{ label: "Νέα Μουδανιά", value: 118 },
	{ label: "Νέα Πέραμος", value: 119 },
	{ label: "Νέο Καρλόβασι", value: 120 },
	{ label: "Νέοι Επιβάτες", value: 121 },
	{ label: "Νεροκούρος", value: 122 },
	{ label: "Ξάνθη", value: 123 },
	{ label: "Ξυλόκαστρο", value: 124 },
	{ label: "Οβρυά", value: 125 },
	{ label: "Ορεστιάδα", value: 126 },
	{ label: "Ορχομενός", value: 127 },
	{ label: "Παιανία", value: 128 },
	{ label: "Παλαμάς", value: 129 },
	{ label: "Παλλήνη", value: 130 },
	{ label: "Παραλία Αχαΐας", value: 131 },
	{ label: "Πάτρα", value: 132 },
	{ label: "Περαία", value: 133 },
	{ label: "Πλαγιάρι", value: 134 },
	{ label: "Πολύγυρος", value: 135 },
	{ label: "Πολύκαστρο", value: 136 },
	{ label: "Πόρτο Ράφτη", value: 137 },
	{ label: "Πρέβεζα", value: 138 },
	{ label: "Πτολεμαΐδα", value: 139 },
	{ label: "Πύργος", value: 140 },
	{ label: "Ραφήνα", value: 141 },
	{ label: "Ρέθυμνο", value: 142 },
	{ label: "Ρίο", value: 143 },
	{ label: "Ρόδος", value: 144 },
	{ label: "Σαλαμίνα", value: 145 },
	{ label: "Σάμος", value: 146 },
	{ label: "Σέρρες", value: 147 },
	{ label: "Σητεία", value: 148 },
	{ label: "Σιάτιστα", value: 149 },
	{ label: "Σιδηρόκαστρο", value: 150 },
	{ label: "Σίνδος", value: 151 },
	{ label: "Σκύδρα", value: 152 },
	{ label: "Σούδα", value: 153 },
	{ label: "Σοφάδες", value: 154 },
	{ label: "Σπάρτη", value: 155 },
	{ label: "Σπάτα", value: 156 },
	{ label: "Τρίκαλα", value: 157 },
	{ label: "Τρίλοφος", value: 158 },
	{ label: "Τρίπολη", value: 159 },
	{ label: "Τυμπάκι", value: 160 },
	{ label: "Τύρναβος", value: 161 },
	{ label: "Φάρσαλα", value: 162 },
	{ label: "Φέρες", value: 163 },
	{ label: "Φιλιατρά", value: 164 },
	{ label: "Φίλυρο", value: 165 },
	{ label: "Φλώρινα", value: 166 },
	{ label: "Χαλάστρα", value: 167 },
	{ label: "Χαλκίδα", value: 168 },
	{ label: "Χανιά", value: 169 },
	{ label: "Χίος", value: 170 },
	{ label: "Χρυσούπολη", value: 171 },
	{ label: "Ψαχνά", value: 172 },
	{ label: "Ωραιόκαστρο", value: 173 },
];

const titles = [
  { label: "Κυρία", value: 1 },
  { label: "Κύριος", value: 2 },
];


/*const formikInitialValues={
	about:"test in about",
	address:"testAddress",
	city:"testCity",
	comment:"test in comment",
	county:"testCounty",
	email:"mtzortzis@leap.gr",
	facebook:"testFacebook",
	instagram:"testInstagram",
	name:"testName",
	password:"testpassword",
	passwordConfirm:"testpassword",
	phone:"123456789",
	postcode:"12345",
	primarySpecialty:1,
	secondarySpecialty:2,
	surname:"testSurname",
	terms:true,
	title:2,
	twitter:"testTwitter"
}*/

const formikInitialValues={
	about:"",
	address:"",
	city:"",
	comment:"",
	county:"",
	area:"",
	email:"",
	facebook:"",
	instagram:"",
	linkedin:"",
	name:"",
	password:"",
	passwordConfirm:"",
	phone:"",
	postcode:"",
	primarySpecialty:"",	
	surname:"",
	terms:false,
	privacy:false,
	title:1,
	twitter:""
}

const SignupSchema = Yup.object().shape({
	title: Yup.number("Required")
		.required("Required")
		.nullable(),
	name: Yup.string().required("Required"),
	surname: Yup.string().required("Required"),
	email: Yup.string()
		.email("Invalid email")
		.required("Required"),
	password: Yup.string()
		.min(8, "Πολύ μικρός κωδικός")
		.required("Required"),
	passwordConfirm: Yup.string()
		.min(8, "Πολύ μικρός κωδικός")
		.required("Required")
		.oneOf([Yup.ref("password"), null], "Λάθος στην επαλήθευση κωδικού"),
	/*.test('passwords-match', 'Passwords must match ya fool', function(value) {
      return this.parent.password === value;
    })*/
	primarySpecialty: Yup.number().required("Required"),
	address: Yup.string().required("Required"),
	county: Yup.number().required("Required"),
	city: Yup.number().required("Required"),
	postcode: Yup.number().required("Required"),
	phone: Yup.number().required("Required"),
});

class RegisterForm extends Component {
	constructor() {
	    super()

	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.registerUser = this.registerUser.bind(this);
	}

	componentDidMount(){
		
	}

	handleSubmit (values, { 
      props = this.props, 
      setSubmitting 
    }) {
   		
		//process form submission here

		let formData = new FormData();

		for (var key in values) {
			formData.append(key, values[key]);
		}

		formData.set('title',titles.find(obj => obj.value === values.title).label);
		formData.set('primarySpecialty',specialties.find(obj => obj.value === values.primarySpecialty).label);
		formData.set('county',counties.find(obj => obj.value === values.county).label);
		var cityToSend = (cities.find(obj => obj.value === values.city)) ? cities.find(obj => obj.value === values.city).label : values.city;
		formData.set('city', cityToSend);
		//formData.set('secondarySpecialty',specialties.find(obj => obj.value == values.secondarySpecialty).label);

		this.registerUser(formData);

		//done submitting, set submitting to false
		setSubmitting(false);
		return;
	}

  	registerUser(formData) {	    

	    axios
	      .post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/register', formData)
	      .then(response => {
	        //console.log(response);
	        return response;
	      })
	      .then(json => {
	        if (json.data.success) {
	          //alert(`Registration Successful!`);

	          /*let userData = {
	            name: json.data.data.name,
	            id: json.data.data.id,
	            email: json.data.data.email,
	            auth_token: json.data.data.auth_token,
	            timestamp: new Date().toString()
	          };
	          let appState = {
	            isLoggedIn: true,
	            user: userData
	          };*/
	          // save app state with user date in local storage
	          //localStorage["appState"] = JSON.stringify(appState);
	          /*this.setState({
	            isLoggedIn: appState.isLoggedIn,
	            user: appState.user
	          });*/
	        } else {
	          //alert(json.data.data);
	        }
	        this.props.handleModalOnSubmit(json.data.success,json.data.data);
	      })
	      .catch(error => {
	        alert("An Error Occured!" + error);
	        //console.log(`${formData} ${error}`);
	      });
	  };


  	render() {
		return(
			
			<Formik
			initialValues={formikInitialValues}
	        validationSchema={SignupSchema}
	        onSubmit={this.handleSubmit}
	        >
	        {({errors, isSubmitting, values, isValid }) => (
              <Form>
              	<div className="form-group">
					<label>Τίτλος<span className="form-asterisk">*</span>
					</label>
					<Field required className='form-control' name="title" component={SelectField} options={titles} />
				</div>
				<div className="form-group">
					<label>Όνομα<span className="form-asterisk">*</span>
					</label>
					<Field required type="text" name="name" className='form-control'/> 
				</div>
				<div className="form-group">
					<label>Επώνυμο<span className="form-asterisk">*</span>
					</label>
					<Field required type="text" name="surname" className='form-control'/> 
				</div>
				<div className="form-group">
					<label>Email<span className="form-asterisk">*</span>
					</label>
					<Field required type="email" name="email" className='form-control'/> 
					<ErrorMessage name="email" render={msg => <div className="customErrorMessage">{msg}</div>}/>
				</div>
				
				<div className="form-group">
					<label>Κωδικός<span className="form-asterisk">*</span>
					</label>
					<Field required type="password" name="password" className='form-control'/>
					<ErrorMessage name="password"  render={msg => <div className="customErrorMessage">{msg}</div>} />
				</div>
				<div className="form-group">
					<label>Επαλήθευση κωδικού<span className="form-asterisk">*</span>
					</label>
					<Field required type="password" name="passwordConfirm" className='form-control'/> 
					<ErrorMessage name="passwordConfirm"  render={msg => <div className="customErrorMessage">{msg}</div>} />
				</div>
				<div className="form-group">
					<label>Ειδικότητα<span className="form-asterisk">*</span>
					</label>
					<Field required name="primarySpecialty" component={SelectField} options={specialties} />
					<ErrorMessage name="primarySpecialty"  render={msg => <div className="customErrorMessage">{msg}</div>} />
				</div>
				<div className="form-group">
					<label>Διεύθυνση<span className="form-asterisk">*</span>
					</label>
					<Field required type="text" name="address" className='form-control'/>
					<ErrorMessage name="address"  render={msg => <div className="customErrorMessage">{msg}</div>} />
				</div>
				<div className="form-group">
					<label>Νομός<span className="form-asterisk">*</span>
					</label>
					<Field required type="text" name="county" className='form-control' component={SelectField} options={counties}/> 
					<ErrorMessage name="county"  render={msg => <div className="customErrorMessage">{msg}</div>} />
				</div>
				<div className="form-group">
					<label>Πόλη<span className="form-asterisk">*</span>
					</label>
					{/*OPTION created from user: just use CreatableSelectField instead of SelectField */}
					<Field required type="text" name="city" className='form-control' component={SelectField} options={cities}/>  
					<ErrorMessage name="city"  render={msg => <div className="customErrorMessage">{msg}</div>} />
				</div>
				<div className="form-group">
					<label>Περιοχή
					</label>
					<Field type="text" name="area" className='form-control'/>  
				</div>
				<div className="form-group">
					<label>Ταχυδρομικός κώδικας<span className="form-asterisk">*</span>
					</label>
					<Field required type="text" name="postcode" className='form-control'/>  
					<ErrorMessage name="postcode"  render={msg => <div className="customErrorMessage">{msg}</div>} />
				</div>
				<div className="form-group">
					<label>Τηλέφωνο<span className="form-asterisk">*</span>
					</label>
					<Field required type="tel" name="phone" className='form-control'/>  
					<ErrorMessage name="phone"  render={msg => <div className="customErrorMessage">{msg}</div>} />
				</div>
				<div className="form-group">
					<label>Σχετικά με εσάς
					</label>
					<Field type="text" component="textarea"name="about" className='form-control'/> 
				</div>
				<div className="form-group">
					<label>Facebook
					</label>
					<Field type="text" name="facebook" className='form-control'/>  
				</div>
				<div className="form-group">
					<label>Twitter
					</label>
					<Field type="text" name="twitter" className='form-control'/>  
				</div>
				<div className="form-group">
					<label>Instagram
					</label>
					<Field type="text" name="instagram" className='form-control'/> 
				</div>
				<div className="form-group">
					<label>Linkedin
					</label>
					<Field type="text" name="linkedin" className='form-control'/> 
				</div>
				<div className="form-group">
					<label>Σχόλιο
					</label>
					<Field type="text" component="textarea"name="comment" className='form-control'/>
				</div>
				<div className="form-group">
					<div className="form-check">
						<label className="form-check-label">
						<Field type="checkbox" name="terms" className='form-check-input' required/>
						Συναινώ στους <Link to="/terms_hcps" target="_blank" >Όρους Χρήσης</Link>
						</label>
					</div>
					<div className="form-check">
						<label className="form-check-label">
						<Field type="checkbox" name="privacy" className='form-check-input' required/>
						Έχω ενημερωθεί για την <Link to="/privacy_policy" target="_blank" >Πολιτική Απορρήτου</Link>
						</label>
						
					</div>
				</div>             	
				 
                <button 
                  type="submit" 
                  disabled={isSubmitting}
                  className="btn btn-primary">
                     Εγγραφή
                </button>
              </Form>
	       )}
	      </Formik>
		);
	}
}

export default RegisterForm;