import React, { Component } from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
//import { browserHistory } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga4';
import axios from 'axios';
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import { withCookies/*, Cookies*/  } from 'react-cookie';
import { connect } from "react-redux";
import { loginUser } from "./redux/actions";
import $ from 'jquery';

import './Assets/css/default.min.css';
import './Assets/css/homepage.min.css';
import {array_move} from './Assets/js/main.js';
import {getIndex} from './Assets/js/main.js';

import Header from './components/headerComponent/header';
import Footer from './components/footerComponent/footer';
import HomePage from './components/pages/homePage';
import HcpProfile from './components/pages/hcpPage';
import ArticlePage from './components/pages/articlePage';
import MedicalArticlePage from './components/pages/medicalArticlePage';
import CategoryPage from './components/pages/categoryPage';
import CatalogPage from './components/pages/catalogPage';
import EventsPage from './components/pages/eventsPage';
import PasswordResetPage from './components/pages/passwordResetPage';
import ContactPage from './components/pages/contactPage';
import TermsPage from './components/pages/termsPage';
import TermsHcpsPage from './components/pages/termsHcpsPage';
import PrivacyPage from './components/pages/privacyPage';
import PrivacyPageApp from './components/pages/privacyPageApp';
import MedicalArticlesListPage from './components/pages/medicalArticlesListPage';
import LoginModal from './components/basicComponents/loginModal';
import ErrorModal from './components/basicComponents/alertModal';
import RegisterModal from './components/basicComponents/registerModal';
import ResetPasswordModal from './components/basicComponents/resetPasswordModal';

import WebAccessibility from './components/basicComponents/webAccessibility';

import 'accessibility-buttons/dist/css/accessibility-buttons.css';

import { CookieBanner } from '@palmabit/react-cookie-law';

/*const trackPageView = location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname+ location.search);
};

const initGa = history => {
  ReactGA.initialize('UA-151243246-1', {
    //debug: true
  });  
  trackPageView(history.location);
  history.listen(trackPageView);
};

const history = createHistory()*/
//initGa(history);

const trackPageView = location => {
	//console.log(location);
	//console.log(document.title);
  /*ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname+ location.search);*/
  ReactGA.send({ hitType: "pageview", page: location.pathname+ location.search, title: document.title });
};

const initGa = history => {
  ReactGA.initialize('G-C0P3X13ZD4', {
    //debug: true
  });  
  trackPageView(history.location);
  history.listen(trackPageView);
};

const history = createHistory()
initGa(history);


class ConnectedApp extends Component {
	constructor() {
        super()
        this.state = {
        	loggedInUser: {},
            articles: [],
            medicalArticles: [],
            articlesLoaded: false,
            medicalArticlesLoaded: false,
            tags: [],
            tagsLoaded: false,
            catalogTypes:[]
        };
        this.getAllArticles = this.getAllArticles.bind(this);
        this.getAllMedicalArticles = this.getAllMedicalArticles.bind(this);
        this.getAllTags = this.getAllTags.bind(this);
        this.getAllCatalogTypes = this.getAllCatalogTypes.bind(this);
        this.removeLoginCookie = this.removeLoginCookie.bind(this);
        this.loginUserFromCookie = this.loginUserFromCookie.bind(this);
    }

    componentWillMount() {

    }

    componentWillUnmount() {
    	/*window.onpopstate  = (e) => {
			debugger;
		}*/
    }

    componentDidUpdate(){
    	
    }


    componentDidMount() {  
    	$(window).on('popstate', this.handleBackPress);
		document.addEventListener("backbutton", this.handleBackPress, false);

		this.getAllTags();
    	
		this.loginUserFromCookie();
		
    }

    loginUserFromCookie(){
    	if (this.props.cookies.cookies.userAuthToken) {
    		const userCookie = JSON.parse(this.props.cookies.cookies.userAuthToken);

    		let headers = {
	    		'Authorization': 'Bearer '+userCookie.token
	    	}
    		
    		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/getUserFromAuthToken',{email:userCookie.userEmail}, {headers: headers})
		    .then(response => {
		        //console.log(response);

		        if (response.data.success) {
			      	//if (json.data.userData.token) {
			      		let refreshedToken = response.headers['authorization'];

			        	if (refreshedToken == null) {
			        		refreshedToken = userCookie.token;
			        	}else{
			        		refreshedToken = refreshedToken.replace('Bearer ', '');
			        	}

			        	let userData = {
				        	token: refreshedToken,
				          	data: response.data.userData.data
				        }

				        this.props.dispatchUserLogin(userData);

			        	this.props.cookies.set('userAuthToken', JSON.stringify({
				          	userEmail: userData.data.email,
			    			token: refreshedToken
				        }), {
				          	path: '/',
			    			maxAge: 60 * 60 * 8
				        });
			        //}
			    }

		        return response;
		    }).then(json => {
		    	
		  	})
		    .catch(error => {
		        alert(`An Error Occured! ${error}`);
		    });	
    	}
    }

    handleBackPress(event) {
    	
    	if ( $('.modal-backdrop').hasClass('show') ) {
    		
			event.preventDefault();
			event.stopPropagation();
			
			window.$('.modal').modal('hide');			
			$('.modal-backdrop').remove();
			$("body").removeClass('modal-open');
			$("body").css('padding-right',0);
			setTimeout(function(){
				window.history.go(1);
			}, 10);
			
			return false;
    	}
    	
	}

    getAllArticles() {
    	let allArticles = [];
		this.setState({
	        	articles : allArticles,
	        	articlesLoaded : true,
	        });	
	    this.getAllCatalogTypes();

	    // NO NEED TO FETCH 500 articles. Each component makes its own specific requests for articles
    	/*axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/articles', {})
	      .then(res => {
	      	
	      	console.log(window.serverURL);
	        allArticles = res.data.data;
	        // console.log(allArticles);
	        this.setState({
	        	articles : allArticles,
	        	articlesLoaded : true,
	        });	
	        this.getAllCatalogTypes();
	      }).catch(err => console.log('Login: ', err));*/
    }

    getAllTags() {
    	var allTags = [];
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/tags', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allTags = res.data.data;

	        var allTagsSorted = allTags.sort((a, b) => (a.title < b.title) ? -1 : 1);
	        var categoryAll = {
	        	category_id: 'all',
				color: "#555555",
				created_at: null,
				id: 0,
				short_name: "Όλες",
				title: "Όλες οι κατηγορίες",
				updated_at: null
	        }	        
	        array_move(allTagsSorted,getIndex(16,allTagsSorted,'id'),(allTagsSorted.length-1));
	        allTagsSorted.unshift(categoryAll);
	        this.setState({
	        	tags : allTagsSorted,
	        	tagsLoaded: true
	        });	        
	        this.getAllArticles();
	      }).catch(err => 
	      		console.log('Login: ', err)
	      );
	}

	getAllCatalogTypes() {
		let allCatalogTypes = [];
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/catalog_types', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allCatalogTypes = res.data.data;
	        //console.log(allTags);
	        this.setState({
	        	catalogTypes : allCatalogTypes,	        	
	        });
	        //this.getAllMedicalArticles();
	      }).catch(err => 
	      	console.log('Login: ', err)
	      	);
	}

	getAllMedicalArticles() {		
		let allMedicalArticles = [];
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/medical_articles', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allMedicalArticles = res.data.data;
	        /*console.log(allMedicalArticles);*/
	        this.setState({
	        	...this.state,
	        	medicalArticles : allMedicalArticles,
	        	medicalArticlesLoaded : true,
	        });
	      }).catch(err => 
	      	console.log('Login: ', err)
	      	);

	}

	removeLoginCookie() {
		const { cookies } = this.props;
 
    	cookies.remove('userAuthToken',{path: '/'});
	}
    

	render() {
		return (
			<Router history={history}>
				<div className="App" role="main">
					<Header tags = {this.state.tags} catalogTypes={this.state.catalogTypes} removeLoginCookie = {this.removeLoginCookie}/>
					<Route exact path="/" 
						render={
							(props)=> <HomePage {...props} tags = {this.state.tags} articles = {this.state.articles} medicalArticles = {this.state.medicalArticles} articlesLoaded={this.state.articlesLoaded} medicalArticlesLoaded={this.state.medicalArticlesLoaded} tagsLoaded={this.state.tagsLoaded}/>
						} 
					/>
					<WebAccessibility />
					<Route path="/events" component={() => <EventsPage />} />
					<Route path="/contact" component={ContactPage} />
					<Route exact path="/terms" component={TermsPage} />
					<Route exact path="/terms_hcps" component={TermsHcpsPage} />
					<Route exact path="/privacy_policy" component={PrivacyPage} />
					<Route exact path="/privacy_policy_app" component={PrivacyPageApp} />

					<Route path="/medicalArticlesList" 
						render={
							(props)=><MedicalArticlesListPage {...props} tagsLoaded={this.state.tagsLoaded}/>
						} 
					/>
					<Route path="/catalogs/:id" component={CatalogPage} />

					<Route path="/hcp_profile/:id" render={(props)=> <HcpProfile {...props}/>} />
					
					<Switch>
						<Route path="/article/:id/:articleTitle" render={(props)=> <ArticlePage {...props}/>} />
						<Route path="/article/:id" render={({ match }) => (
							<Redirect to={`/article/${match.params.id}/articleTitle`} />
						)} />
						<Route path="/article/:id/" render={({ match }) => (
							<Redirect to={`/article/${match.params.id}/articleTitle`} />
						)} />
					</Switch>

					<Route path="/password_reset" render={(props)=> <PasswordResetPage {...props}/>} />
					{/* <Switch>
						<Route exact path="/password_reset/?:token" render={(props)=> <PasswordResetPage {...props}/>} />
						 <Route exact path="/password_reset" render={( props ) => (	
							this.props.userData.isLoggedIn ? <PasswordResetPage {...props}/> : <Redirect to={`/`} />
						)} /> 
					</Switch> */}
					{/*<Route path="/article/:id" render={(props) => <Redirect from="/article/:id" to="/article/:id/somewhere" />} />*/}
					
					{/*<Redirect to={{ pathname: '/article', state: { id: ':id' } }} />*/}
					<Route path="/medicalArticle/:id" render={(props)=> <MedicalArticlePage {...props}/>} />
					{/*<Route path="/category/:id" component={CategoryPage} />*/}
					<Route exact path="/category/:id" 
						render={
							(props)=><CategoryPage {...props} navigation={this.props.navigation} tags = {this.state.tags} articles = {this.state.articles} articlesLoaded={this.state.articlesLoaded} tagsLoaded={this.state.tagsLoaded}/>
						} 
					/>
					<Footer tags = {this.state.tags} catalogTypes={this.state.catalogTypes}/>
					<ScrollUpButton ToggledStyle={{right:10,zIndex:2}}/>
					<LoginModal loginUser = {this.loginUser} cookies = {this.props.cookies}/>
					<RegisterModal registerUser = {this.registerUser}/>
					<ResetPasswordModal/>
					<ErrorModal/>
					
					<CookieBanner
				      message="Για την καλύτερη εμπειρία σας στον ιστότοπό μας χρησιμοποιούνται cookies. Μπορείτε να μάθετε περισσότερα για τα cookies στο παράρτημα 6 της Πολιτικής Απορρήτου της ιστοσελίδας μας."
				      necessaryOptionText="Αναγκαία"
				      statisticsOptionText="Στατιστικά"
				      policyLink="/privacy_policy"
				      privacyPolicyLinkText="Πολιτική απορρήτου"
				      acceptButtonText="Αποδέχομαι"
				      showPreferencesOption={false}
				      showMarketingOption={false}
				      onAccept = {() => {}}
				      onAcceptPreferences = {() => {}}
				      onAcceptStatistics = {() => {}}
				      onAcceptMarketing = {() => {}}
				      styles={{
				      	dialog: {
						    left: "0px",
						    bottom: "0px",
						    right: "0px",
						    padding: "10px",    
						    position: "fixed",
						    zIndex: "100000",
						    backgroundColor: "#f8f7f7"
				      	}
				      }}
				    />
				    {/* <div className="pdfForEspa">
				    	<button type="button" className="close" data-dismiss="pdfForEspa" aria-label="Close" style={{color:"#000"}} onClick={(e) =>e.currentTarget.parentNode.remove()} >
					    	<span aria-hidden="true">&times;</span>
					  	</button>
					    <a href="/static/media/WEB_ΕΣΠΑ.6c682423.pdf" target="_blank" rel="noopener noreferrer" >
					    	<img src="/static/media/bannersdallETPA.5a89064e.JPG" className="align-top img-fluid" alt="ΕΣΠΑ" />
					    </a>
				    </div> */}
				</div>
			</Router>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUserLogin: userData => dispatch(loginUser(userData))
  };
};

const mapStateToProps = state => {
  return {
  	userData: {
  		isLoggedIn : state.user.isLoggedIn,
  	}
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);

export default withCookies(App);

