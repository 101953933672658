import { LOGIN_USER } from "./action-types";
import { LOGOUT_USER } from "./action-types";
import { LOAD_ARTICLE_COMMENTS } from "./action-types";
import { LOAD_ARTICLE_RATINGS } from "./action-types";
import { ERROR_MESSAGE } from "./action-types";

const initialState = {
  user: {
  	isLoggedIn: false,
  	data: {},
    token: "",
    isViewOnly: true
  },
  allArticleComments: [],
  allArticleRatings: [],
  error:{
    shouldShow: false,
    message: ''
  }
};

function rootReducer(state = initialState, action) {
  
  if (action.type === LOGIN_USER) {
    let viewOnly = true;

    if (action.payload.data.demo_mode===0) {
      viewOnly = false;
    }

    return {
    	...state,
    	user : {
    		...state.user,
    		isLoggedIn : true,
    		data : {
    			...state.user.data,
    			...action.payload.data
    		},
        token: action.payload.token,
        isViewOnly: viewOnly
    	}
    };
  }

  if (action.type === LOGOUT_USER) {
    return {
      ...state,
      user : {
        ...state.user,
        isLoggedIn : false,
        data : {  },
        token: "",
        isViewOnly: true
      }
    };
  }

  if (action.type === LOAD_ARTICLE_COMMENTS) {
    //let viewOnly = true;

    return {
      ...state,
      allArticleComments: [...action.payload]
    };
  }

  if (action.type === LOAD_ARTICLE_RATINGS) {
    //let viewOnly = true;

    return {
      ...state,
      allArticleRatings: [...action.payload]
    };
  }

  if (action.type === ERROR_MESSAGE) {
    //let viewOnly = true;

    return {
      ...state,
      error:{
        shouldShow: true,
        message: action.payload
      }
    };
  }

  return state;
};

export default rootReducer;