import React, {Component} from 'react';
//import $ from 'jquery';

class EventFilters extends Component {
	constructor() {
		super();

		this.state = {
			buttons: [
				{
					class: 'eventFilter selected',
					order: 'ascending',
					by: 'next',
					text: 'Επερχόμενες',					
				},
				{
					class: 'eventFilter',
					order: 'ascending',
					by: 'previous',
					text: 'Προηγούμενες',					
				}
			]
		}

		this.orderArticlesBy = this.orderArticlesBy.bind(this);		
	}

	orderArticlesBy(e) {		
		var order = e.currentTarget.getAttribute('order');
		var by = e.currentTarget.getAttribute('by');
		
		if (order==="ascending") {
			order="descending";
		} else {
			order="ascending";
		}

		var newButtons = [...this.state.buttons].map(button => {
			if (button.by === by) {
				return {
					...button,
					class: 'eventFilter selected',
					order: order
				};
			}else{
				return {
					...button,
					class: 'eventFilter'
				};
			}
		});

		this.setState({
			buttons: newButtons
		});

		var filter = {
			order:order,
			by:by
		}
		this.props.onSelectFilter(filter);
	}	

	render() {
		return (
			<div className="container">
				<div className="event-filters d-flex justify-content-center">					
					{this.state.buttons.map((button,index) => (
			          	<div className={button.class} order={button.order} by={button.by} onClick={this.orderArticlesBy} key={index}>
							{button.text} <span className="order-arrow"></span>
						</div>
			        ))}
				</div>
			</div>
		);
	}
}

export default EventFilters;