import React from 'react';
//import defaultEventImage from '../../Assets/img/default_event.jpg';
import { Link } from 'react-router-dom';
import Rating from '../basicComponents/rating';
import defaultMedicalArticleImage from '../../Assets/img/Icon_Studies.jpg';
import Highlight from 'react-highlighter';

function MedicalArticle(props) {  
    
    let self = {};    
    self.medicalArticleObj = props.medicalArticleObj;
    self.title = props.medicalArticleObj.subject;    
    self.image = (self.medicalArticleObj.cover) ? window.serverURL+"/serve_images/"+props.medicalArticleObj.cover : null; 
    if (self.image == null) {
        self.image = defaultMedicalArticleImage;
    }   

    var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let datePublish = (props.medicalArticleObj.publish_date) ? props.medicalArticleObj.publish_date : '';
    var datePublishFormatted = new Date(datePublish).toLocaleDateString('el-EL', dateOptions);
    self.datePublish = datePublishFormatted;
    self.publisher = props.medicalArticleObj.publisher;
    self.therapeutic_category = [props.medicalArticleObj.therapeutic_category];
    self.text = props.medicalArticleObj.abstract;
    self.link = props.medicalArticleObj.link;
    self.commentNames = (self.medicalArticleObj.comment_names)? self.medicalArticleObj.comment_names.split(',') : [];

    self.tags = [];
    self.tagNames = self.therapeutic_category;

    for (var i=0; i < self.tagNames.length; i++) {
        
        let tag = self.tagNames[i];
        const tagColor = '#000000';
        const tagObj = {
            'name':tag,
            'color':tagColor,
            'style':{backgroundColor:tagColor}
        }
        self.tags.push(tagObj);
    }

    self.divStyle = {
        //serve_images is configured from apache. Put it in front of imgUrl
        background: 'url(' + self.image + ') no-repeat center center',
        backgroundSize: 'cover',
        
    };

    self.divStyle2 = {
        //serve_images is configured from apache. Put it in front of imgUrl
        background: 'url(' + self.image + ') no-repeat center center',
        backgroundSize: 'cover',
        height: '100%'   
    };

    self.medicalLinkStyle = {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        display: 'block',
    }
    
    if (props.parentClassName === "medical-articles-list-page") {
        return (  
           <div className={props.class +" article"} style={self.divStyle}>
                <Link to={{pathname:"/medicalArticle/"+self.medicalArticleObj.id, state:{articleInfo:self.medicalArticleObj,tags:self.tags,dateFormatted:self.datePublish}}} >
                <div className="category-page-wrapper">                    
                    <div className="home_banner_text">                    
                        {/*<div className="banner-tag">
                            {self.tags.map((tag, index) => { 
                                return (
                                    <div key={index} className="tag" style={tag.style}>
                                        {tag.name}
                                    </div>
                                )
                            })}                    
                        </div>*/}
                        <h2><Link to={{pathname:"/medicalArticle/"+self.medicalArticleObj.id, state:{articleInfo:self.medicalArticleObj,tags:self.tags,dateFormatted:self.datePublish}}} >
                            <Highlight matchElement="span" ignoreDiacritics matchClass="highlightedTextSearch" search={props.searchValue}>{self.title}</Highlight>
                        </Link></h2>
                        <p>
                            <i className="fa fa-globe"></i>
                            <a href={self.link} target="_blank" rel="noopener noreferrer nofollow">
                                <Highlight matchElement="span" ignoreDiacritics matchClass="highlightedTextSearch" search={props.searchValue}>{self.publisher}</Highlight>
                            </a>
                            <i className="fa fa-clock-o"></i>{self.datePublish}</p>
                    </div>
                </div>
                </Link>
            </div>
            
        );
    } else if (props.parentClassName === "popular-medical-articles" || props.parentClassName === "popular-medical-articles popular-articles") {
        return (
            <div className={props.class+" article"}>
                <div className="row">
                    {/*<div className="col-12 col-md-5">                    
                        <Link to={{pathname:"/medicalArticle/"+self.medicalArticleObj.id, state:{articleInfo:self.medicalArticleObj,tags:self.tags,dateFormatted:self.datePublish}}} > 
                            <div style={self.divStyle2}>
                                <div className="d-md-none">
                                    <img src={self.image} className="img-fluid" width="100%" alt={self.medicalArticleObj.id}/>
                                </div>
                            </div>
                        </Link>
                        
                    </div>*/}
                    <div className="col-12">
                        <Link to={{pathname:"/medicalArticle/"+self.medicalArticleObj.id, state:{articleInfo:self.medicalArticleObj,tags:self.tags,dateFormatted:self.datePublish}}}  style={self.medicalLinkStyle}>{self.title} </Link>
                        <p style={{marginBottom:0}}>
                            <i className="fa fa-globe"></i><a href={self.link} target="_blank" rel="noopener noreferrer nofollow">{self.publisher}</a>
                            <i className="fa fa-clock-o"></i>{self.datePublish}
                            <span href="#" 
                                className="comment-tooltip d-inline-block ml-3" 
                                data-toggle="tooltip" 
                                data-placement="top" 
                                data-html="true" 
                                data-container="body" 
                                data-original-title={self.commentNames.join('<br>')}>
                                <i className="fa fa-commenting" aria-hidden="true"></i>{self.medicalArticleObj.comments_count + ' Comments'}
                            </span>
                        </p>
                        <div className="rating-container" style={{fontSize:"0.7em"}}>                           
                            {
                                (self.medicalArticleObj.article_rating > 0 && self.medicalArticleObj.hcps_ratings) ? 
                                <Rating rating={self.medicalArticleObj.article_rating} cannotUpdate={true} ratingNames={self.medicalArticleObj.hcps_ratings.split(",")} />
                                :
                                <Rating rating={0} cannotUpdate={true} ratingNames={[]} />
                            }
                        </div>
                        
                        {/*<div className="banner-tag">
                            {self.tags.map((tag, index) => {
                                return (
                                    <div key={index} className="tag" style={tag.style}>
                                        {tag.name}
                                    </div>
                                )
                            })}                    
                        </div>*/}
                    </div>
                </div>
                
            </div>
        );    
    } else{
        return null;
    }
}

export default MedicalArticle;