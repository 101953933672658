import React, {Component} from 'react';
//import $ from 'jquery';
//import axios from 'axios';
import RegisterForm from './registerForm';
import { connect } from "react-redux";
import { captureRequestError } from "../../redux/actions";

class ConnectedRegisterModal extends Component {

	constructor() {
        super()

        this.state={
	    	registerEmail: '',
	    	alertFailStyle: {},
			alertFailText: '',
			serverError: {
				shouldShow: false,
				msg: ''
			}
	    };

	    this.handleModal = this.handleModal.bind(this);
    }

    componentDidMount() {  
    	window.$('#registerFailAlert').alert();
    	window.$('#myModal').modal();
    }	

    handleModal(success,data){
    	if (!success) {
			this.setState({
				registerEmail: data.email,
				alertFailStyle: {
				  display: 'block'
			  },
			  alertFailText: data
			});
		  window.$('#registerModal').scrollTop(0);
			// if (data === 'User already exists.') {
			// 	this.setState({
			// 		registerEmail: data.email,
			// 		alertFailStyle: {
			// 		  display: 'block'
			// 	  },
			// 	  alertFailText: data
			// 	});
			//   window.$('#registerModal').scrollTop(0);
			// }else{
			// 	// this.props.dispatchError(data);
			// 	this.setState({ serverError: { shouldShow: true, msg: data } });
			// }    		
    	}else{
    		window.$('#registerModal').modal('hide');
    		window.$('#registerSuccessModal').modal('show');
    	}
    }

	render() {
		
		return(
			<div>
			<div className="modal" id="registerModal">
				<div className="modal-dialog">
					<div className="modal-content">	
						<div className="modal-body">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
					        	<span aria-hidden="true">&times;</span>
					        </button>
							<div className="container-fluid">
								<div className="row">
									<div className="col-12 " style={{zIndex:1}}>
										<div className="row">
											<div className="container content-container">
												<div className="d-flex flex-column justify-content-between">
													<div className="alert alert-danger .alert-dismissible" style={this.state.alertFailStyle} id="registerFailAlert" role="alert">
													  {'Ανεπιτυχής εγγραφή. ' + this.state.alertFailText}
													  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
													    <span aria-hidden="true">&times;</span>
													  </button>
													</div>
													{/*<div className="alert alert-danger .alert-dismissible" style={this.state.alertSuccessStyle} id="registerFailAlert" role="alert">
													  Ανεπιτυχής εγγραφή. Υπάρχει ήδη χρήστης με το email.
													  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
													    <span aria-hidden="true">&times;</span>
													  </button>
													</div>*/}
													<p>Παρακαλώ συμπληρώστε την παρακάτω φόρμα εγγραφής</p>
													<p>* Τα πεδία με αστερίσκο είναι υποχρεωτικά</p>
													<RegisterForm handleModalOnSubmit={this.handleModal}/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal" tabIndex="-1" role="dialog" id="registerSuccessModal">
			  <div className="modal-dialog" role="document">
			    <div className="modal-content">
			      <div className="modal-header">
			        <h5 className="modal-title">Επιτυχής εγγραφή</h5>
			        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
			          <span aria-hidden="true">&times;</span>
			        </button>
			      </div>
			      <div className="modal-body">
			        <p>Η εγγραφή ολοκληρώθηκε επιτυχώς. Θα λάβετε σύντομα ένα σχετικό email.</p>
			      </div>
			      <div className="modal-footer">
			        <button type="button" className="btn btn-secondary" data-dismiss="modal">ΟΚ</button>
			      </div>
			    </div>
			  </div>
			</div>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		dispatchError: errorData => dispatch(captureRequestError(errorData))
	};
};

const RegisterModal = connect(null, mapDispatchToProps)(ConnectedRegisterModal);

export default RegisterModal;