import React, {Component} from 'react';
//import $ from 'jquery';

class CustomFilters extends Component {
	constructor() {
		super();

		this.state = {
			buttons: [
				{
					class: 'customFilter selected',
					order: 'ascending',
					by: 'date',
					text: 'Πρόσφατα',					
				},
				{
					class: 'customFilter',
					order: 'ascending',
					by: 'eval',
					text: 'Προτεινόμενα',					
				},
				{
					class: 'customFilter',
					order: 'ascending',
					by: 'popul',
					text: 'Δημοφιλή',					
				}
			]
		}

		this.orderArticlesBy = this.orderArticlesBy.bind(this);		
	}

	orderArticlesBy(e) {		
		var order = e.currentTarget.getAttribute('order');
		var by = e.currentTarget.getAttribute('by');
		
		if (order==="ascending") {
			order="descending";
		} else {
			order="ascending";
		}

		var newButtons = [...this.state.buttons].map(button => {
			if (button.by === by) {
				return {
					...button,
					class: 'customFilter selected',
					order: order
				};
			}else{
				return {
					...button,
					class: 'customFilter'
				};
			}
		});

		this.setState({
			buttons: newButtons
		});

		var filter = {
			order:order,
			by:by
		}
		this.props.onSelectFilter(filter);
	}	

	render() {
		return (
			<div className="container">
				<div className="category-filters d-flex justify-content-center">
					{/*<div className="customFilter selected" order="descending" by="date" onClick={this.orderArticlesBy}>
						Πρόσφατα <span className="order-arrow"></span>
					</div>
					<div className="customFilter" order="descending" by="eval" onClick={this.orderArticlesBy}>
						Αξιολόγηση <span className="order-arrow"></span>
					</div>
					<div className="customFilter" order="descending" by="popul" onClick={this.orderArticlesBy}>
						Δημοφιλή <span className="order-arrow"></span>
					</div>*/}
					{this.state.buttons.map((button,index) => (
			          	<div className={button.class} order={button.order} by={button.by} onClick={this.orderArticlesBy} key={index}>
							{button.text} <span className="order-arrow"></span>
						</div>
			        ))}
				</div>
			</div>
		);
	}
}

export default CustomFilters;