import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../../Assets/img/logo.png';
/*import {add} from '../helper/Helper.js';*/
/*import $ from 'jquery';*/
import { connect } from "react-redux";
import { logoutUser, captureRequestError } from "../../redux/actions";

/*import getBrowserLocation from '../helper/Helper.js';*/

const mapStateToProps = state => {
  return {
  	userData: {
  		isLoggedIn : state.user.isLoggedIn,
  		userName : state.user.data.full_name,
  		userToken : state.user.token,
  		userId:state.user.data.id
  	}
  };
};

class ConnectedHeader extends Component {
	constructor(props) {
		super(props);
		
		var dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
		var newDate = new Date().toLocaleDateString('el-EL', dateOptions).toUpperCase();
		var newDateNormalized = newDate.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		this.state = {
			date: newDateNormalized,
			location: {
				city : '',
				country: ''
			},
			loginStyle: {
				loginButClass: 'show',
				loginNameClass: 'hidden',
			}

		};

		this.logoutUser = this.logoutUser.bind(this);
		this.changeLoginStyle = this.changeLoginStyle.bind(this);
		
	}

	logoutUser(){
		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/logout',{
			token:this.props.userData.userToken
		})
		    .then(response => {
		        //console.log(response);
		        return response;
		    })
		    .then(json => {
		    	if (json.data.success) {
		          //this.setState({ users: json.data.data });

		          //console.log(json.data.userData.data);

		          this.props.dispatchUserLogout();
		          this.props.removeLoginCookie();
		          
		          //alert("Login Successful!");
		        }else{
					this.props.dispatchError(json.data.data);
				}
		    })
		    .catch(error => {
		        alert(`An Error Occured! ${error}`);
		    });
	}

	changeLoginStyle(){
		var loginStyle = Object.assign({}, this.state.loginStyle);
    	if (this.props.userData.isLoggedIn) {
    		loginStyle.loginButClass = 'hidden';
			loginStyle.loginNameClass = 'show';
    	}else {
    		loginStyle.loginButClass = 'show';
			loginStyle.loginNameClass = 'hidden';
    	}
    	this.setState({loginStyle: loginStyle });
	}

	componentDidMount() {
		/*var self = this;
		var locationResponse = Object.assign({}, this.state.location);
		$.ajax('http://ip-api.com/json')
		.then(
			function success(response) {
				locationResponse.city = response.city;
				locationResponse.country = response.country;
				self.setState({location: locationResponse });
			},

			function fail(data, status) {
				locationResponse.city = '';
				locationResponse.country = '';
				self.setState({location: locationResponse });
			}
		)*/

	}

	componentDidUpdate(prevProps){
		if(prevProps.userData !== this.props.userData){ 
	    	this.changeLoginStyle();
	    	//console.log(this.state);
	    }
	}
	
	render() {
		return (
			<header>
				<div className="container-fluid" id="top_section">
					<div className="container-fluid">
				    	<div className="row">
				    		<div className="col-3">
							    <NavLink to="/" className="navbar-brand">
						        	<img src={logo} className="logo-top align-top img-fluid" alt="All4Diabetes logo"/>
						        </NavLink>
					        </div>
					        <div className="col-9">
					        	<i className="fa fa-clock-o" aria-hidden="true"></i> {this.state.date.replace(',', '')} {/*<i className="fa fa-cloud" aria-hidden="true"></i> {this.state.location.city}, {this.state.location.country} */}
					        </div>
				        </div>
			        </div>
		        </div>
				<nav className="navbar navbar-expand-lg navbar-light bg-light">		        
			        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			          <span className="navbar-toggler-icon"></span>
			        </button>

			        <div className="collapse navbar-collapse" id="navbarSupportedContent">
			        	<div className="container">
				        	<div className="row">
					    		<div className="col-3">
					    		</div>
					    		<div className="col-9">
						          <ul className="navbar-nav mr-auto">
						            <li className="nav-item dropdown">
						            	<a className="nav-link dropdown-toggle" href="/" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											ΚΑΤΗΓΟΡΙΕΣ
										</a>
										<div className="dropdown-menu" aria-labelledby="navbarDropdown1">
											{
												this.props.tags.map((tag, index) => {													
								                    return (
								                       <NavLink className="dropdown-item" key={index} to={"/category/"+tag.category_id}>{tag.title}</NavLink> 
								                    )
								                })
							                }   											
										</div>
						            	
						            </li>						            
						            <li className="nav-item dropdown">
						              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						                ΦΟΡΕΙΣ ΥΓΕΙΑΣ
						              </a>
						              <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
						              	{
											this.props.catalogTypes.map((catalog, index) => {													
							                    return (
							                       <NavLink className="dropdown-item" key={index} to={"/catalogs/"+catalog.id}>{catalog.name}</NavLink> 
							                    )
							                })
						                }
						                
						              </div>
						            </li>
						            {	
						        		this.props.userData.isLoggedIn
						        		?   <li className="nav-item">
						        				<NavLink to="/medicalArticlesList" className="nav-link" activeClassName="active">
										        	ΔΗΜΟΣΙΕΥΣΕΙΣ
										        </NavLink>
								            </li>
								        : ''
								    }
						            <li className="nav-item">
						              {/*<a className="nav-link" href="/events">ΕΚΔΗΛΩΣΕΙΣ <span className="sr-only"></span></a>*/}
						              	<NavLink to="/events" className="nav-link" activeClassName="active">
								        	ΕΚΔΗΛΩΣΕΙΣ
								        </NavLink>
						            </li>
						            <li className="nav-item">
						              {/*<a className="nav-link" href="/">ΕΠΙΚΟΙΝΩΝΙΑ <span className="sr-only"></span></a>*/}
						              <NavLink to="/contact" className="nav-link" activeClassName="active">
								        	ΕΠΙΚΟΙΝΩΝΙΑ
								        </NavLink>
						            </li>
						            <li className={"nav-item "+this.state.loginStyle.loginButClass}>
						              <a data-toggle="modal" data-target="#loginModal" className="nav-link hcpLoginBut" href="/">ΣΥΝΔΕΣΗ ΕΥ <i className="fa fa-user-md" aria-hidden="true" ></i><span className="sr-only"></span></a>
						            </li>
						            <li className={"nav-item dropdown "+this.state.loginStyle.loginNameClass}>
						              <a href="/" className="nav-link hcpLoginBut" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.props.userData.userName ? this.props.userData.userName.split(' ')[0] : ''} <i className="fa fa-user-md" aria-hidden="true" ></i><span className="sr-only"></span></a>
						              <div className="dropdown-menu" aria-labelledby="navbarDropdown3">
						              		<Link className="dropdown-item" key="" to={{pathname:"/hcp_profile/"+this.props.userData.userId}}>Προφίλ</Link>
						              		<div className="dropdown-item hcpLogoutBut" onClick={this.logoutUser}>Αποσύνδεση</div>
						              </div>
						            </li>
						            {/*<form className="form-inline my-2 my-lg-0" id="websiteSearchForm">
							            <input className="form-control mr-sm-2 d-none" type="search" placeholder="Search" aria-label="Search" />
							            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
							        </form>*/}
						          </ul>
						          
						        </div>
					        </div>
					    </div>
			        </div>
			    </nav>
			    
			</header>
			
		);
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUserLogout: () => dispatch(logoutUser()),
    dispatchError: () => dispatch(captureRequestError())
  };
}

const Header = connect(mapStateToProps,mapDispatchToProps,null,{ pure: false})(ConnectedHeader);

export default Header;
