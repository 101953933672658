import React, { Component } from "react";
import Article from "../../basicComponents/article";
import MedicalArticle from "../../basicComponents/medicalArticle";
import CustomFilters from "../../basicComponents/customFilters";
import ReactPaginate from "react-paginate";
import axios from "axios";

class ArticleColumns extends Component {
	constructor(props) {
		super(props);

		this.state = {
			articlesData: [],
			medicalArticlesData: [],
			currentArticles: [],
			currentMedicalArticles: [],
			currentArticlePage: 1,
			currentMedicalArticlePage: 1,
			lastArticlePage: 0,
			lastMedicalArticlePage: 0,
			articleOffset: 0,
			medicalArticleOffset: 0,
			perPage: 5,
			perPageMedical: 10,
			maxPages: 20,
			articleLoading: true,
			medicalArticleLoading: true
		};

		this.handleArticlePageClick = this.handleArticlePageClick.bind(this);
		this.handleMedicalArticlePageClick = this.handleMedicalArticlePageClick.bind(this);
		this.getNewArticles = this.getNewArticles.bind(this);
		this.getNewMedicalArticles = this.getNewMedicalArticles.bind(this);
		this.handleArticleFilterChange = this.handleArticleFilterChange.bind(this);
		this.handleMedicalArticleFilterChange = this.handleMedicalArticleFilterChange.bind(this);
	}

	componentDidMount() {
		this.getNewArticles();
		this.getNewMedicalArticles();

		window.$('body').tooltip({
    		selector: '[data-toggle="tooltip"]'
    	});
	}

	getNewArticles(sortBy = "", direction = "") {
		axios
			.post(window.serverURL + "/all4diabetes/admin/public/admin/api/ajax_requests/paginatedArticles", { sort_by: sortBy, direction: direction })
			.then(res => {
				//console.log(res.data.data)
				this.setState({
					articlesData: res.data.data,
					currentArticles: res.data.data.slice(this.state.articleOffset, this.state.articleOffset + this.state.perPage),
					lastArticlePage: Math.round(res.data.data.length / this.state.perPage),
					articleLoading: false
				});
			})
			.catch(err => console.log(err));
	}

	getNewMedicalArticles(sortBy = "", direction = "") {
		axios
			.post(window.serverURL + "/all4diabetes/admin/public/admin/api/ajax_requests/paginatedMedicalArticles", { sort_by: sortBy, direction: direction })
			.then(res => {
				// console.log(res.data.data)
				this.setState({
					medicalArticlesData: res.data.data,
					currentMedicalArticles: res.data.data.slice(this.state.medicalArticleOffset, this.state.medicalArticleOffset + this.state.perPageMedical),
					lastMedicalArticlePage: Math.round(res.data.data.length / this.state.perPageMedical),
					medicalArticleLoading: false
				});
			})
			.catch(err => console.log(err));
	}

	handleArticlePageClick(data) {
		const selectedPage = data.selected;
		const offset = selectedPage * this.state.perPage;

		let newPageArticles = this.state.articlesData.slice(offset, offset + this.state.perPage);

		this.setState({
			currentArticles: [...newPageArticles],
			currentArticlePage: selectedPage,
			articleOffset: offset
		});
	}

	handleMedicalArticlePageClick(data) {
		const selectedPage = data.selected;
		const offset = selectedPage * this.state.perPageMedical;

		let newPageMedicalArticles = this.state.medicalArticlesData.slice(offset, offset + this.state.perPageMedical);

		this.setState({
			currentMedicalArticles: [...newPageMedicalArticles],
			currentMedicalArticlePage: selectedPage,
			medicalArticleOffset: offset
		});
	}

	handleArticleFilterChange(filter) {
		var newArticleData = this.handleArticleSorting(JSON.parse(JSON.stringify(this.state.articlesData)), filter);

		let newCurrentArticles = newArticleData.slice(this.state.articleOffset, this.state.articleOffset + this.state.perPage);

		this.setState({
			articlesData: newArticleData,
			currentArticles: [...newCurrentArticles]
		});
	}

	handleMedicalArticleFilterChange(filter) {
		var newMedicalArticleData = this.handleMedicalArticleSorting(JSON.parse(JSON.stringify(this.state.medicalArticlesData)), filter);

		let newCurrentMedicalsArticles = newMedicalArticleData.slice(this.state.articleOffset, this.state.articleOffset + this.state.perPageMedical);

		this.setState({
			medicalArticlesData: newMedicalArticleData,
			currentMedicalArticles: [...newCurrentMedicalsArticles]
		});
	}

	handleArticleSorting(articlesToSort, filter) {
		let isReversed = filter.order === "descending" ? false : true;
		let orderKey;
		debugger;
		if (filter.by === "date") {
			orderKey = "created_at";
		} else if (filter.by === "eval") {
			orderKey = "article_rating";
		} else if (filter.by === "popul") {
			orderKey = "views_count";
		}

		articlesToSort.sort(function(a, b) {
			var keyA,keyB;
			if (orderKey === "created_at") {
				keyA = new Date(a.created_at);
				keyB = new Date(b.created_at);
			} else {
				keyA = a[orderKey] ? parseFloat(a[orderKey]) : 0.0;
				keyB = b[orderKey] ? parseFloat(b[orderKey]) : 0.0;
			}

			// Compare the 2 dates

			if (!isReversed) {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			} else {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			}

			return 0;
		});

		return articlesToSort;
	}

	handleMedicalArticleSorting(articlesToSort, filter) {
		let isReversed = filter.order === "descending" ? false : true;
		let orderKey;
		
		if (filter.by === "date") {
			orderKey = "publish_date";
		} else if (filter.by === "eval") {
			orderKey = "article_rating";
		} else if (filter.by === "popul") {
			orderKey = "views_count";
		}

		articlesToSort.sort(function(a, b) {
			var keyA,keyB;
			if (orderKey === "publish_date") {
				keyA = new Date(a.publish_date);
				keyB = new Date(b.publish_date);
			} else {
				keyA = a[orderKey] ? parseFloat(a[orderKey]) : 0.0;
				keyB = b[orderKey] ? parseFloat(b[orderKey]) : 0.0;
			}

			// Compare the 2 dates

			if (!isReversed) {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			} else {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			}

			return 0;
		});

		return articlesToSort;
	}

	render() {
		const h2Styles = {
			textAlign:'center',
			padding:'1em 3em',
			width:'100%',
			color: '#0071BC',
		}
		if (this.state.currentArticles.length > 0 || this.state.currentMedicalArticles.length > 0) {
			return (
				<section className="article-columns" id="article-columns-section">
					<div className="container">
						<div className="row">
							<div className="col-sm-6 col-12">
								<section className="articles-column">
									<div className="container">
										<div className="section-title">
											<h2>Άρθρα</h2>											
										</div>
										<CustomFilters onSelectFilter={this.handleArticleFilterChange} />
										<div className="row">
											<div className="col-12">
												{this.state.currentArticles.map((article, index) => (
													<Article {...this.props} articleObj={article} class="item-small" key={index} />
												))}
											</div>
										</div>
										<ReactPaginate previousLabel={"Προηγούμενο"} nextLabel={"Επόμενο"} breakLabel={"..."} breakClassName={"break-me"} pageCount={Math.min(this.state.maxPages, this.state.lastArticlePage)} marginPagesDisplayed={2} pageRangeDisplayed={this.state.perPage} onPageChange={this.handleArticlePageClick} pageClassName={"pages"} previousClassName={"previous"} nextClassName={"next"} containerClassName={"pagination"} subContainerClassName={"pages pagination"} activeClassName={"active"} />
										{this.state.articleLoading ? 
											<h3 className="article-title" style={h2Styles}>Γίνεται εύρεση άρθρων....</h3> 
											:
											null 
										}
									</div>
								</section>
							</div>
							<div className="col-sm-6 col-12">
								<section className="medical-articles-column">
									<div className="container">
										<div className="section-title">
											<h2>Δημοσιεύσεις</h2>											
										</div>
										<CustomFilters onSelectFilter={this.handleMedicalArticleFilterChange} />
										<div className="row">
											<div className="col-12">
												{this.state.currentMedicalArticles.map((article, index) => (
													<MedicalArticle {...this.props} medicalArticleObj={article} class="item-small" key={index} />
												))}
											</div>
										</div>
										<ReactPaginate previousLabel={"Προηγούμενο"} nextLabel={"Επόμενο"} breakLabel={"..."} breakClassName={"break-me"} pageCount={Math.min(this.state.maxPages, this.state.lastMedicalArticlePage)} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={this.handleMedicalArticlePageClick} pageClassName={"pages"} previousClassName={"previous"} nextClassName={"next"} containerClassName={"pagination"} subContainerClassName={"pages pagination"} activeClassName={"active"} />
										{this.state.medicalArticleLoading ? 
											<h3 className="article-title" style={h2Styles}>Γίνεται εύρεση άρθρων....</h3> 
											:
											null 
										}
									</div>
								</section>
							</div>
						</div>
					</div>
				</section>
			);
		} else {
			return <section className="article-columns" />;
		}
	}
}

export default ArticleColumns;
