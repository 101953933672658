import React, {Component} from 'react';
import '../../Assets/css/contactpage.min.css';
import axios from 'axios';
//import g2w_map from '../../Assets/img/g2w_map.JPG';
import g2w_map_row_logo from '../../Assets/img/g2w_map_row_logo.png';
import emailSending from '../../Assets/img/email_sending.png';
import { Formik, /*FormikProps,*/ Form, Field/*, ErrorMessage*/  } from 'formik';
import * as Yup from 'yup';
import PaginationArrow from '../../Assets/img/pagination_arrow_left.png';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const formikInitialValues={
	name:"",
	email:"",
	subject:"",
	message:"",
	privacy:false
}

const SignupSchema = Yup.object().shape({  	
  name: Yup.string()
    .required('Required'),  
  privacy: Yup.boolean(true).required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'), 
  subject: Yup.string()
    .required('Required'),
  message: Yup.string()
    .required('Required'),
});

const recaptchaRef = React.createRef();

class ContactPage extends Component {

	constructor() {
		super();
		
		this.state = {
			isEmailSending:false,
			emailWasSent:"",
			verifiedReCaptcha:false
		};		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.sendContactEmail = this.sendContactEmail.bind(this);
		this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
	}

	componentDidMount() {		
		window.scrollTo(0,0);
	}
	
	componentDidUpdate() {				
			
	}

	sendContactEmail(formData,setSubmitting) {
		/*for (var pair of formData.entries()) {
		    console.log(pair[0]+ ', ' + pair[1]); 
		}*/
		axios
			.post(window.serverURL + "/all4diabetes/admin/public/admin/api/ajax_requests/sendContactEmailForm", formData)
			.then(response => {
				//console.log(response);
				return response;
			})
			.then(json => {
				
				if (json.data.status === "success") {
					this.setState(
						{
							emailWasSent: "true",
							isEmailSending:false,
							verifiedReCaptcha:false
						}
					);
				} else {
					this.setState(
						{
							emailWasSent: "false",
							isEmailSending:false,
							verifiedReCaptcha:false
						}
					);
				}
				setSubmitting(false)
				recaptchaRef.current.reset();
			})
			.catch(error => {
				this.setState(
					{
						emailWasSent: "false",
						isEmailSending:false,
						verifiedReCaptcha:false
					}
				)
				setSubmitting(false)
				recaptchaRef.current.reset();
			});
		
	}

	handleSubmit (values, { 
      props = this.props, 
      setSubmitting 
    }) { 
    	var self = this;
    	self.setState({
    		isEmailSending:true
    	})
    	setSubmitting(true);
		let formData = new FormData();

		for (var key in values) {
			formData.append(key, values[key]);
		}

		formData.set('name',values["name"]);
		formData.set('email',values["email"]);
		formData.set('subject',values["subject"]);
		formData.set('message',values["message"]);
		
		//formData.set('secondarySpecialty',specialties.find(obj => obj.value == values.secondarySpecialty).label);

		this.sendContactEmail(formData,setSubmitting);
		/*setTimeout(function(){
			setSubmitting(false);
		}, 5000)*/
		//done submitting, set submitting to false
		
		return;
	}

	onChangeRecaptcha(value) {
		if (value) {
			this.setState({
				verifiedReCaptcha:true
			})	
		} else {
			this.setState({
				verifiedReCaptcha:false
			})
		}
		
	}
	
	render() {
		return (
			
			<div className="container-fluid contactPage">
				<Helmet
						title={"Στοιχεία επικοινωνίας | All4Diabetes"}
						meta={[
						
						]}
					/>
				<h3 className="contact-title">Επικοινωνία</h3>
				<div className="row infoRow">					
					<div className="col-md-3 col-sm-0"></div>
					<div className="col-md-9 col-sm-12">
						<div className="container d-flex align-items-center justify-content-center infoDetails">
							<a href={"tel:+302111069373"}><i className="fa fa-phone" aria-hidden="true"></i> 2111069373 </a>
							{/*<i className="fa fa-fax" aria-hidden="true"></i> 2106838221*/}
							<a href="https://www.google.com/maps/place/Xanthippis+26,+Athina+104+44/@37.9977411,23.7174319,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1a2d4f6dce6e5:0xd7944e814c8c5648!8m2!3d37.9977369!4d23.7196259"
							target="_blank" rel="noopener noreferrer nofollow" title="Δείτε μας στο google maps"><i className="fa fa-map-marker" aria-hidden="true"></i> Ξανθίππης 26, 10444, Αθήνα</a>
							<a href={"mailto:contact@all4diabetes.gr"}><i className="fa fa-envelope-o" aria-hidden="true"></i> contact@all4diabetes.gr</a>
						</div>
					</div>
					
				</div>
				<div className="row g2w_map_row">
					<div className="col-5">
						<img src={g2w_map_row_logo} className="img-fluid" alt="get2Work-all4diabetes logo"/>
					</div>
					<div className="col-7 d-flex align-items-center justify-content-center">
						<a href="https://www.google.com/maps/place/Xanthippis+26,+Athina+104+44/@37.9977411,23.7174319,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1a2d4f6dce6e5:0xd7944e814c8c5648!8m2!3d37.9977369!4d23.7196259"
							target="_blank" rel="noopener noreferrer nofollow" title="Δείτε μας στο google maps" aria-label="Get2Work google maps"><div className="d-none">hidden text</div></a>
					</div>
				</div> 
				<div className="row form-indicator-row">
					<div className="col-md-6 col-sm-12 d-flex justify-content-center">
						<div className="text-center form-indicator">
							Μπορείτε να επικοινωνήσετε μαζί μας <br/>συμπληρώνοντας την παρακάτω φόρμα.
							<img src={PaginationArrow} className="arrow arrow-down" width="16" height="16" alt="Arrow down" />
						</div>
					</div>
				</div>
				<div className="row form-container-row">
					<div className="col-md-6 col-sm-12">
						<Formik
						initialValues={formikInitialValues}
				        validationSchema={SignupSchema}
				        onSubmit={this.handleSubmit}
				        >
				        {({errors, isSubmitting, values, isValid }) => (				        	
				        	<Form>
				        		<div className="container form-container">
					        		<div className="form-group row">
										<div className="col-12">
											<Field aria-label="Full name" required type="text" name="name" className='form-control' placeholder="Όνομα"/> 
										</div>
									</div>
									<div className="form-group row">
										<div className="col-12">
											<Field aria-label="Email" required type="email" name="email" className='form-control' placeholder="E-mail" /> 
										</div>
									</div>
									<div className="form-group row">
										<div className="col-12">
											<Field aria-label="subject" required type="text" name="subject" className='form-control' placeholder="Θέμα" /> 
										</div>
									</div>
									<div className="form-group row">
										<div className="col-12">
											<Field aria-label="Message" required type="text" component="textarea" name="message" className='form-control' placeholder="Μήνυμα" /> 
										</div>
									</div>
									<div className="form-group row">
										<div className="form-check">
											<label className="form-check-label">
											<Field type="checkbox" name="privacy" className='form-check-input' required/>
											Συμφωνώ με την <Link to="/privacy_policy" target="_blank" >Πολιτική Απορρήτου</Link>
											</label>
											
										</div>
									</div>
									{/*console.log(isSubmitting||!isValid||!this.state.verifiedReCaptcha)*/}
									<ReCAPTCHA
										ref={recaptchaRef}
									    sitekey="6LdhOsAUAAAAAFvg5E_koU6je3ql1UrBsyk2QHje"
									    onChange={this.onChangeRecaptcha}
									    size="compact"
									/>
									
									<div className="text-right">
										<button 
											type="submit" 
											disabled={isSubmitting||!isValid||!this.state.verifiedReCaptcha}
											className="btn btn-primary">
											Αποστολή
										</button>
					                </div>
								</div>
							</Form>
							)}
						</Formik>
					</div>
					<div className="col-md-6 col-sm-12  d-flex align-items-center email-animation-container">
						<div className="container text-center">
							{(() => {
								if (this.state.isEmailSending) {return null;}
								switch(this.state.emailWasSent) {
									case 'true':
									return <h3 style={{padding:'20px 0'}}>Το αίτημά σας στάλθηκε επιτυχώς!</h3>;
									case 'false':
									return <h3 style={{padding:'20px 0'}}>Το αίτημά σας δεν απεστάλλει! Προσπαθείστε αργότερα.</h3>;									
									default:
									return null;
								}
							})()}
							
							<img alt="Email icon" width="60%" src={emailSending} className={this.state.isEmailSending ? 'img-fluid email-sending-image sending' : "img-fluid email-sending-image"}/>
						</div>
					</div>
				</div>
			</div>
		);
		
	}
}

export default ContactPage;