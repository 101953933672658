import React from 'react';

function Advertisement(props) {
	
    if (props.adObj) {
    	return (
	        <div className="container">
	        	<div className={"ad-type-"+props.adObj.position}>
		        	<a href={props.adObj.link} target="_blank" rel="noopener noreferrer nofollow">
		        		<img src={window.serverURL+"/serve_images/"+props.adObj.image} className="img-fluid ad-image" alt={props.adObj.title} align="middle"/>
		        	</a>	
	        	</div>
	        </div>
	    );	
    }
    return null; 
    
}

export default Advertisement;