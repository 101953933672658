import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import '../../Assets/css/articlepage.min.css';
//import defaultEventImage from '../../Assets/img/default_event_wide.jpg';
//import Tag from '../basicComponents/tag';
import Rating from '../basicComponents/rating';
import axios from 'axios';
import Iframe from '../helper/Iframe.js';
//import { TrackerProvider, Tracker } from 'react-tracker';
import { withTracking } from 'react-tracker';
import { connect } from "react-redux";
import getTrackArticlePageView from '../../tracking/events';
//import $ from 'jquery';
import CommentsList from './articleComponents/commentList';
import { renderToStaticMarkup } from 'react-dom/server';

const decodeHtml = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

const stripHtml = (html) => {
	var doc = new DOMParser().parseFromString(html, 'text/html');
   	return doc.body.textContent || "";
}

class ConnectedMedicalArticlePage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			medicalArticleObj:{},
			tags:[],
			date:'',
			comments:[],
			rating:0,
			clientInfo:{type:"medicalArticle"},
			articleComments: [],
			articleRatings: [],
			commentCount: 0,
			commentNames: []
		}
		this.styleFullText = this.styleFullText.bind(this);
		this.getMedicalArticleById = this.getMedicalArticleById.bind(this);
		this.buildStateFromDB = this.buildStateFromDB.bind(this);
		this.getArticleComments = this.getArticleComments.bind(this);
		this.getCommentCount = this.getCommentCount.bind(this);

		this.updateRating = this.updateRating.bind(this);
		this.calculateRating = this.calculateRating.bind(this);
		this.getArticleRatings = this.getArticleRatings.bind(this);
	}

	componentDidMount() {	
		
		if(this.props.location.state) {
			this.setState({
				medicalArticleObj:this.props.location.state.articleInfo,
				tags:this.props.location.state.tags,
				date:this.props.location.state.dateFormatted
			}, function () {
				this.getArticleComments();
				this.getArticleRatings();
			} );
		} else {		
			this.getMedicalArticleById();
		}		

		this.props.trackArticlePageView('medicalArticle',this.props.match.params.id);

		window.$('body').tooltip({
    		selector: '[data-toggle="tooltip"]'
    	});
	}


	componentDidUpdate(prevProps, prevState) {
		
	}

	styleFullText() {
		const firstLetterTextStyles = {			
			color: this.state.tags[0].color,
			fontSize:'5em',
			lineHeight: '0.7em',
			float:'left',
			padding:'0.1em'
		};

		//I had to do this because of unknown document format from Tiny text Editor from the admin
		var htmlStrippedText = stripHtml(this.state.medicalArticleObj.abstract);
		var firstLetter = htmlStrippedText.charAt(0);
		var decodedHtml = decodeHtml(this.state.medicalArticleObj.abstract);
		var indexOfFirstLetter = decodedHtml.indexOf(firstLetter);
		var restText = decodedHtml.substring(indexOfFirstLetter+1);
		return (<p> <span style={firstLetterTextStyles}>{firstLetter}</span>{restText}</p>);
	}

	getMedicalArticleById() {		
		let article = [];
    	let articleId = {
    		id: this.props.match.params.id
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/medical_articles', articleId)
	      .then(res => {	      	
	      	
	        article = res.data.data;
	        console.log(article);
	        let stateData = {
	        	medicalArticleObj : article[0],
	        };
	        
	        this.getArticleComments(stateData);
	    }).catch(err => console.log('Login: ', err));
	}

	buildStateFromDB(stateData) {
		var self = stateData;

		if (self.hasOwnProperty('medicalArticleObj')) {
			/*self.medicalArticleObj = this.state.medicalArticleObj;
			self.allTags = this.state.allTags;*/
			
			self.tags = [];
			self.tagNames = self.medicalArticleObj.therapeutic_category;
			const tagObj = {
					'name':self.tagNames,
					'color': '#000000',
					'style': {backgroundColor:'#000000'}
				}
			self.tags.push(tagObj);
			/*for (var i=0; i < self.tagNames.length; i++) {
				let tag = self.tagNames[i];
				const tagObj = {
					'name':tag,
					'color': '#000000',
					'style': {backgroundColor:'#000000'}
				}
				self.tags.push(tagObj);
		   }*/

		    //console.log(new Date(Date.parse(self.medicalArticleObj.created_at)));
		    //console.log(new Date(Date.parse(self.created_at.toString().replace(' ','T'))));
		    var dateOptions = { /*weekday: 'long',*/ year: 'numeric', month: 'short', day: 'numeric' };
		    var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

			self.date = new Date(self.medicalArticleObj.publish_date.replace(' ','T')).toLocaleDateString('el-EL', dateOptions);
		}	    
	    
		this.setState({
			...this.state,
			...self
		});
	}

	getArticleComments(stateData = {}){
		let articleComments = [];

		let articleData = {
			article_id: stateData.hasOwnProperty('medicalArticleObj') ? stateData.medicalArticleObj.id : this.state.medicalArticleObj.id, 
			article_type: 'medical_article'
		};

		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/comments', articleData)
		.then(res => {
			
			/*console.log(window.serverURL);*/
			articleComments = res.data.data;
			//console.log(articleComments);

			let formatedArticleComments = [];

			//var dateOptions = { /*weekday: 'long',*/ year: 'numeric', month: 'short', day: 'numeric' };

			articleComments.forEach(function(item, index) {
			  let comment = {
					userData: {
						id: item.user_id,
						full_name: item.full_name,
						social: [
							item.facebook,
							item.twitter,
							item.instagram
						],
						image: null
					},
					commentData: {
						id: item.id,
						parentCommentID: item.parent_id,
						articleId: item.article_id,
						articleType: item.article_type,
						text: item.text,
						created_at: item.created_at,
						updated_at: item.updated_at,
						is_deleted: item.is_deleted
					},
					replies: []
				}

				formatedArticleComments.push(comment)
			});

			let sortedArticleComments = this.buildArticleCommentsArray(formatedArticleComments);

			stateData = {
	        	...stateData,
				articleComments : sortedArticleComments,
				commentCount:formatedArticleComments.length,
				commentNames: [...new Set(formatedArticleComments.map(comment => comment.userData.full_name))]
	        };

	        this.getArticleRatings(stateData);	

		}).catch(err => console.log('Login: ', err));
	}

	buildArticleCommentsArray(articleComments){
		var i;
		let newArticleComments = articleComments.map(comment => {
			return {
				...comment,
				unique_id: '_' + Math.random().toString(36).substr(2, 9)
			}
		});

		for (i = newArticleComments.length - 1; i >= 0; i--) {
			if (newArticleComments[i].commentData.parentCommentID !== 0) {
				let parentIndex = newArticleComments.findIndex(comment => comment.commentData.id === newArticleComments[i].commentData.parentCommentID);

				newArticleComments[parentIndex].replies.push(newArticleComments.splice(i,1)[0]);
			}
		}

		for (i = 0; i < newArticleComments.length; i++) {
			newArticleComments[i].replies.sort(function(a,b) {
				return Date.parse(a.commentData.created_at) - Date.parse(b.commentData.created_at);
			});
		}

		return newArticleComments;
	}

	getArticleRatings(stateData = {}){
		let articleRatings = [];

		let articleData = {
			article_id: stateData.hasOwnProperty('medicalArticleObj') ? stateData.medicalArticleObj.id : this.state.medicalArticleObj.id, 
			article_type: 'medical_article'
		};

    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/ratings', articleData)
		.then(res => {
			/*console.log(window.serverURL);*/
			articleRatings = res.data.data;
			//console.log(articleRatings);

			for (let i = articleRatings.length - 1; i > 0; i--) {
		        const j = Math.floor(Math.random() * (i + 1));
		        [articleRatings[i], articleRatings[j]] = [articleRatings[j], articleRatings[i]];
		    }

			let newArticleRatings = this.calculateRating(articleRatings);

			stateData = {
	        	...stateData,
	        	...newArticleRatings
	        };

	        this.buildStateFromDB(stateData);
		}).catch(err => console.log('Login: ', err));
	}

	calculateRating(articleRatings){
		let articleRating = articleRatings.reduce((sum, { rating }) => sum + parseInt(rating), 0) / articleRatings.length;

		return {
			rating: isNaN(articleRating) ? 0 : articleRating,
			articleRatings: articleRatings
		};
	}

	updateRating(userRating){
		let ratingData={
			article_id: this.state.medicalArticleObj.id,
			article_type: 'medical_article',
			user_id: this.props.currentUserId,
			rating: userRating
		}

		let headers = {
    		'Authorization': 'Bearer '+this.props.currentUserToken
    	}

		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/addRating', ratingData, {headers: headers})
		.then(res => {			
			//console.log(res.data);

			let newArticleRatings = this.calculateRating([
				res.data.data,
				...this.state.articleRatings
			]);

			this.setState({
				...this.state,
				...newArticleRatings
			});
		}).catch(err => console.log('Login: ', err));
	}

	getCommentCount(newCount, newNames){
		this.setState({
			commentCount: newCount,
			commentNames: newNames
		});
	}
	
	render() {
		
		if (this.state.tags.length>0 && this.props.isUserLoggedIn) {
			const h2Styles = {
				textAlign:'center',
				padding:'1em 3em',
				width:'100%',
				backgroundColor:this.state.tags[0].color+"55"
			}

			const readArticleStyles = {
				padding:'0.7em 1.5em',
				backgroundColor:this.state.tags[0].color,
				margin:'0 auto',
				color:'white',
				borderRadius:'1em'
			}

			const fullText = [this.styleFullText()]
			
			return (
				<div className="container-fluid articlePage">
					
					<h3 className="article-title" style={h2Styles}>{this.state.medicalArticleObj.subject}</h3>				
					
					
					<div className="container">
						<div className="row">
							{/*<div className="col-md-6 col-12">
								<img src={
									(this.state.medicalArticleObj.cover) ? window.serverURL+"/serve_images/"+this.state.medicalArticleObj.cover : defaultEventImage
								} className="img-fluid" style={{minWidth: '100%'}} alt={this.state.medicalArticleObj.subject}/>
							</div>*/}
							<div className="col-12 d-flex align-items-end">
								<div className="">
									<div className="d-block url">
										<i className="fa fa-globe"></i><a href={this.state.medicalArticleObj.link} target="_blank" rel="noopener noreferrer nofollow">{this.state.medicalArticleObj.publisher}</a>	
									</div>
									<div className="banner-tag d-block">
					                	{this.state.tags.map((tag, index) => {
						                    return (
						                        <div key={index} className="tag" style={tag.style}>
													{tag.name}
												</div>
						                    )
						                })}                    
					                </div>
					                <div className="d-block">
					                	<div><i className="fa fa-clock-o"></i>{this.state.date}</div>
					                	<div>
											<span href="#" 
												className="comment-tooltip" 
												data-toggle="tooltip" 
												data-placement="top" 
												data-html="true" 
												data-container="body" 
												data-original-title={this.state.commentNames.join('<br>')}><i className="fa fa-commenting" aria-hidden="true"></i>{this.state.commentCount + ' Comments'}
											</span>
										</div>
					                	<div className='rating-area'>
					                		<Rating rating={this.state.rating} updateRating={this.updateRating} ratingNames={this.state.articleRatings.map(rating => rating.full_name)}/>
					                	</div>
					                </div>
				                </div>
							</div>
						</div>
					</div>

					<div className="container" style={{marginTop:'30px'}}>
						<h2>Περίληψη</h2>
					</div>
					
					<div className="container full-text" dangerouslySetInnerHTML={{ __html: decodeHtml(fullText.map(renderToStaticMarkup).join("")) }}>
						
					</div>
					<div className="container" style={{textAlign:'center'}}>
						<a href={this.state.medicalArticleObj.link} style={readArticleStyles} target="_blank" rel="noopener noreferrer nofollow">Διαβάστε το άρθρο</a>
						{/*<a href="/" data-toggle="modal" data-target="#iframeModal"  style={readArticleStyles} target="_blank" rel="noopener noreferrer nofollow">Διαβάστε το άρθρο</a>*/}
					</div>
					<div className="container" style={{marginTop:'30px'}}>
						<div className="section-title">
							<h2>Σχόλια</h2>
						</div>
						<CommentsList depth={0} maxDepth={1} comments={this.state.articleComments} articleId={this.state.medicalArticleObj.id} articleType='medical_article' returnCommentCount={this.getCommentCount}/>
					</div>
					{/*<iframe src="http://google.com" style={{width: "100%",height: "100%"}}/>*/}
					<Iframe source={this.state.medicalArticleObj.link} ratingsCallback={this.updateRating} ratingsData={{rating: this.state.rating, ratingNames: this.state.articleRatings.map(rating => rating.full_name)}} isUserLoggedIn={this.props.isUserLoggedIn} />
				</div>
			);
		} else {
			return (
				<div className="container-fluid articlePage">
					{/*<h2 >{this.state.medicalArticleObj.subject}</h2>*/}
					<div className="container">
						
					</div>
					
					
				</div>
			);	
		}
		
	}
}

const mapTrackingToProps = trackEvent => {
	return {
		trackArticlePageView: (type, articleId) => {
			trackEvent(getTrackArticlePageView(type, articleId))
		}
	}
}

const mapStateToProps = state => {
  return {
  	currentUserId: state.user.data.id,
  	currentUserToken: state.user.token,
	isUserLoggedIn: state.user.isLoggedIn
  };
};

const TrackedMedicalArticlePage = withTracking(mapTrackingToProps)(ConnectedMedicalArticlePage);

const MedicalArticlePage = connect(mapStateToProps)(TrackedMedicalArticlePage);

export default MedicalArticlePage;

