import React, { Component } from "react";
import axios from "axios";
//import $ from "jquery";
import { connect } from "react-redux";
import { captureRequestError } from "../../redux/actions";

import "../../Assets/css/resetPasswordModal.min.css";

class ConnectedResetPasswordModal extends Component {
	constructor() {
		super();

		this.state = {
			alertTitle: "",
			alertBody: "",
			email:""
		};

		this.handleModal = this.handleModal.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
	}

	componentDidMount() {}

	handleModal(success, email) {}

	handleEmailChange(e) {
		this.setState({
			email:e.target.value
		})
	}

	handleSubmit(e) {
		e.preventDefault();

		axios
			.post(window.serverURL + "/all4diabetes/admin/public/admin/api/ajax_requests/forgotPassword", { email: this.state.email })
			.then(response => {
				//console.log(response);
				return response;
			})
			.then(json => {
				if (json.data.success) {
					this.setState(
						{
							alertBody: "Έχει σταλεί ένα μήνυμα στο email σας με οδηγίες για την αλλαγή του κωδικού."
						},
						() => {
							window.$("#changePasswordSuccessModal").modal("show");
							window.$("#resetPasswordModal").modal("hide");
						}
					);
				} else {
					if (json.data.data === 'User not found') {
						this.setState(
							{
								alertBody: "Δεν βρέθηκε χρήστης με αυτό το email."
							},
							() => {
								window.$("#changePasswordSuccessModal").modal("show");
								window.$("#resetPasswordModal").modal("hide");
							}
						);	
					}else{
						this.props.dispatchError(json.data.data);
					}
				}
			})
			.catch(error => {
				alert(`An Error Occured! ${error}`);
			});
	}

	render() {
		return (
			<div>
				<div className="modal" id="resetPasswordModal">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-body">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
								<div className="container-fluid">
									<div className="row">
										<div className="col-12 " style={{ zIndex: 1 }}>
											<div className="row">
												<div className="container content-container">
													<div className="d-flex flex-column justify-content-between">
														<p className="title">Επαναφορά κωδικού</p>
														<p>Παρακαλώ εισάγετε το email για την επαναφορά του κωδικού.</p>
														<form onSubmit={this.handleSubmit}>
															<div className="form-group">
																<label htmlFor="resetInputEmail">Email</label>
																<input type="email" onChange={this.handleEmailChange} name="email" className="form-control" id="resetInputEmail" placeholder="Εισαγωγή email" />
															</div>
															<button type="submit" className="btn btn-primary d-flex m-auto hcp_connect">
																Επαναφορά
															</button>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal" tabIndex="-1" role="dialog" id="changePasswordSuccessModal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{this.state.alertTitle}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>{this.state.alertBody}</p>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal"
									onClick={() => {
										window.location = "/";
									}}
								>
									ΟΚ
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		dispatchError: errorData => dispatch(captureRequestError(errorData))
	};
};

const ResetPasswordModal = connect(null, mapDispatchToProps)(ConnectedResetPasswordModal);

export default ResetPasswordModal;
