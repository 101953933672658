import React, {Component} from 'react';
/*import '../../Assets/css/eventspage.min.css';
import Event from '../basicComponents/event';*/

import { NavLink/*, Link*/ } from 'react-router-dom'

class TermsPage extends Component {

	constructor() {
		super();
		
		this.state = {
			
		};		
	}

	componentDidMount() {		
		window.scrollTo(0,0);
	}
	
	componentDidUpdate() {				
			
	}
	
	render() {
		return (
			
			<div className="container-fluid termsPage">				
				<h3 className="event-page-title d-flex justify-content-center align-items-center">ΟΡΟΙ ΧΡΗΣΗΣ ΚΟΙΝΟΥ</h3>
				<div className="container">					
					<p>
					    Η παρούσα ιστοσελίδα (στο εξής «<strong>Δικτυακός Τόπος</strong>») ανήκει
					    στην εταιρεία με την επωνυμία «GET2WORK ΜΟΝΟΠΡΟΣΩΠΗ ΙΚΕ» (Γ.Ε.ΜΗ.: 128127603000) και τον διακριτικό
					    τίτλο «GET2WORK», η οποία εδρεύει στην Αθήνα επί της οδού Ξανθίππης 26,
					    10444 (στο εξής «Εταιρεία»). Η χρήση του Δικτυακού Τόπου υπόκειται στους
					    παρόντες όρους χρήσης. Η εκ μέρους του χρήστη επίσκεψη και χρήση του
					    Δικτυακού Τόπου σημαίνει ότι ο επισκέπτης/χρήστης («εσείς») έχετε
					    μελετήσει, κατανοήσει και αποδεχτεί όλους τους όρους χρήσης. Σε περίπτωση
					    που δεν συμφωνείτε με τους όρους χρήσης, οφείλετε να μην κάνετε χρήση των
					    υπηρεσιών και του περιεχομένου του Δικτυακού Τόπου. Η Εταιρεία διατηρεί το
					    δικαίωμα να τροποποιεί οποτεδήποτε τους πιο κάτω Όρους Χρήσης χωρίς νεότερη
					    ενημέρωσή σας. Για τον λόγο αυτόν, σας προτρέπουμε να επισκέπτεστε τακτικά
					    τον Δικτυακό Τόπο και να ενημερώνεστε για κάθε αλλαγή τους. Σε οποιαδήποτε
					    τροποποίηση αυτών των Όρων Χρήσης, η χρήση των υπηρεσιών του Δικτυακού
					    Τόπου σε μεταγενέστερο της τροποποίησης χρόνο σημαίνει και την εκ νέου
					    αποδοχή των νεότερων Όρων Χρήσης.
					</p>
					<p>
					    <strong>1. </strong>
					    <strong>ΧΡΗΣΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ </strong>
					</p>
					<p>
					    <strong></strong>
					</p>
					<p>
					    <strong>1.1 </strong>
					    Ο Δικτυακός Τόπος συγκεντρώνει πληροφορίες και άρθρα από τρίτες πηγές σχετικά με τον διαβήτη. Οι πληροφορίες του Δικτυακού Τόπου παρέχονται δωρεάν και χωρίς εγγραφή. Μπορείτε να επισκεφθείτε τον Δικτυακό Τόπο, να περιηγηθείτε σε αυτόν και να διαβάσετε τις σχετικές πληροφορίες χωρίς εγγραφή ή χρέωση.
					</p>
					<p>
					    <strong>1.2 </strong>
					    Η δυνατότητα εγγραφής μέσω της φόρμας δημιουργίας λογαριασμού του Δικτυακού Τόπου παρέχεται μόνο στους Επαγγελματίες Υγείας. Εάν είστε Επαγγελματίας Υγείας και επιθυμείτε την προβολή σας μέσω του Δικτυακού Τόπου (εμφάνιση στην καρτέλα «Κατάλογοι Υγείας») και τον σχολιασμό/αξιολόγηση των άρθρων που φιλοξενεί ο Δικτυακός Τόπος θα πρέπει να εγγραφείτε <NavLink to="/" data-toggle="modal" data-target="#loginModal">εδώ</NavLink> 
					</p>
					<p>
					    <strong></strong>
					</p>
					<p>
					    <strong>2. </strong>
					    <strong>ΔΙΚΑΙΩΜΑΤΑ ΒΙΟΜΗΧΑΝΙΚΗΣ ΚΑΙ ΠΝΕΥΜΑΤΙΚΗΣ ΙΔΙΟΚΤΗΣΙΑΣ</strong>
					</p>
					<p>
					    <strong>2.1 </strong>
					    Ο Δικτυακός Τόπος αποτελεί πνευματική ιδιοκτησία της Εταιρείας σύμφωνα με το νόμο 2121/1993 "Περί Πνευματικής Ιδιοκτησίας", όπως αυτός έχει τροποποιηθεί και ισχύει σήμερα και τις διεθνείς συμβάσεις που έχει υπογράψει η Ελλάδα.
					</p>
					<p>
					    <strong>2.2 </strong>
					    Η Εταιρεία διατηρεί τα αποκλειστικά δικαιώματα πνευματικής ιδιοκτησίας στον σχεδιασμό, το περιεχόμενο, τον πηγαίο κώδικα, τα γραφικά, σχέδια, εικόνες και παρεχόμενες υπηρεσίες του Δικτυακού Τόπου στον βαθμό που τα δικαιώματα αυτά δεν ανήκουν ήδη σε τρίτα πρόσωπα. Τυχόν παραβίαση των δικαιωμάτων αυτών με οποιονδήποτε τρόπο επισύρει τις ευθύνες και ποινές που ορίζει ο νόμος. Ενδεικτικά και όχι περιοριστικά απαγορεύεται η αντιγραφή, διανομή, αναπαραγωγή, εκμετάλλευση, δημοσίευση ή με οποιονδήποτε τρόπο μη εξουσιοδοτημένη χρήση του περιεχομένου και του λογισμικού του Δικτυακού Τόπου χωρίς την προηγούμενη άδεια της Εταιρείας. 
					</p>
					<p>
					    <strong>2.3 </strong>
					    Τα άρθρα του Δικτυακού Τόπου αποτελούν πνευματική ιδιοκτησία τρίτων φυσικών ή/και νομικών προσώπων, τα οποία η Εταιρεία καθιστά προσβάσιμα στο κοινό μέσω υπερσυνδέσμων (hyperlinks) προς τις αυθεντικές πηγές της κάθε πληροφορίας. Η ευθύνη για το περιεχόμενο των δημοσιεύσεων και των σχολίων ανήκει εξολοκλήρου στους συντάκτες τους. Η αναπαραγωγή, αντιγραφή, πώληση, μεταπώληση ή/και εμπορική εκμετάλλευση με οποιονδήποτε τρόπο του συνόλου ή μέρους του περιεχομένου του Δικτυακού Τόπου απαγορεύονται απολύτως.
					</p>
					<p>
					    <strong>2.4 </strong>
					    Το σήμα all4Diabetes και τα υπόλοιπα εμπορικά σήματα που εμφανίζονται στον Δικτυακό Τόπο αποτελούν προστατευόμενα διακριτικά γνωρίσματα και κατατεθειμένα εμπορικά σήματα της Εταιρείας ή τρίτων οργανισμών, εταιρειών ή φορέων που είναι δικαιούχοι και προστατεύονται από τους ελληνικούς, κοινοτικούς και διεθνείς νόμους περί εμπορικών σημάτων και βιομηχανικής ιδιοκτησίας. Σε κάθε περίπτωση η εμφάνιση και έκθεσή τους στον Δικτυακό Τόπο δεν θα πρέπει κατά κανένα τρόπο να εκληφθεί ως παραχώρηση άδειας χρήσης τους. Τυχόν παραβίαση των δικαιωμάτων τους με οποιονδήποτε τρόπο επισύρει τις ευθύνες και ποινές που ορίζει ο νόμος. 
					</p>
					<p>
					    <strong>2.5 </strong>
					    Χωρίς να αναιρούνται ή περιορίζονται τα προηγούμενα, εάν πιστεύετε ότι κάποιο έργο σας αντιγράφεται ή καταχωρείται με τρόπο που να παραβιάζονται τα δικαιώματα της προσωπικότητας ή της πνευματικής/βιομηχανικής ιδιοκτησίας σας, παρακαλείστε να αποστείλετε γραπτή ειδοποίηση, στην διεύθυνση contact@all4diabetes.gr
					</p>
					<p>
					    <strong></strong>
					</p>
					<p>
					    <strong>3. </strong>
					    <strong>ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ</strong>
					</p>
					<p>
					    Η επεξεργασία των προσωπικών δεδομένων όλων των χρηστών γίνεται σύμφωνα με
					    την <NavLink to="/privacy_policy">Πολιτική Προστασίας Προσωπικών Δεδομένων του Δικτυακού τόπου.</NavLink>
					</p>
					<p>
					    <strong></strong>
					</p>
					<p>
					    <strong>4. </strong>
					    <strong>ΕΞΩΤΕΡΙΚΕΣ ΣΥΝΔΕΣΕΙΣ</strong>
					    <strong> (links)</strong>
					</p>
					<p>
					    <strong>4.1 </strong>
					    Ο Δικτυακός Τόπος αποτελείται από συνδέσμους ("links") προς άλλους δικτυακούς τόπους, οι οποίοι ελέγχονται από τρίτους φορείς (content aggregator). Σας συνιστούμε κατά την επίσκεψή σας σε αυτούς να επισκέπτεστε και να διαβάζετε προσεκτικά τους όρους χρήσης και την πολιτική απορρήτου καθενός από αυτούς. Σε καμία περίπτωση δεν ευθύνεται η Εταιρεία για το περιεχόμενο των υπερσυνδέσμων που περιλαμβάνει στον Δικτυακό Τόπο, ούτε για οποιαδήποτε οικονομική ή άλλης φύσεως βλάβη υποστεί ο χρήστης που τους ακολουθεί. Σε καμία περίπτωση η Εταιρεία δεν εγγυάται την καταλληλότητα ή την εγκυρότητα του περιεχομένου τους ή ότι οι σύνδεσμοι αυτοί θα λειτουργούν αδιάκοπα. H Εταιρεία διατηρεί το δικαίωμα να απομακρύνει ή και να προσθέσει υπερσυνδέσμους χωρίς οιαδήποτε προειδοποίηση προς οιονδήποτε και να αλλάξει το περιεχόμενο του Δικτυακού Τόπου οποιαδήποτε στιγμή.
					</p>
					<p>
					    <strong>4.2 </strong>
					    Προβαίνοντας σε χρήση του Δικτυακού Τόπου, αποδέχεστε ότι η Εταιρεία σε καμία περίπτωση δεν ευθύνεται  για το περιεχόμενο των υπερσυνδέσμων που περιλαμβάνει, τις υπηρεσίες που προσφέρουν ή τις διαφημίσεις που περιέχουν. Εφιστούμε την προσοχή σας και επισημαίνουμε ότι οι δημοσιεύσεις του Δικτυακού Τόπου δεν αποτελούν ιατρική συμβουλή ή παραίνεση για συγκεκριμένη ενέργεια ή αποχή απο ενέργεια. Για οποιοδήποτε ιατρικό θέμα ή ζήτημα υγείας θα πρέπει να απευθύνεστε στον ιατρό σας ή στον κατάλληλο επαγγελματία υγείας.
					</p>
					<p>
					    <strong>5. </strong>
					    <strong>ΣΧΟΛΙΑΣΜΟΣ ΔΗΜΟΣΙΕΥΣΕΩΝ</strong>
					</p>
					<p>
					    <strong>5.1 </strong>
					    Ο σχολιασμός των άρθρων του Δικτυακού Τόπου επιτρέπεται μόνο σε Επαγγελματίες Υγείας. Οι σχολιαστές είναι αποκλειστικά υπεύθυνοι για τα σχόλια που αναρτούν. Η Εταιρεία σε καμία περίπτωση δεν μπορεί να θεωρηθεί ότι αποδέχεται ή ενστερνίζεται καθ' οιονδήποτε τρόπο τις εκφραζόμενες στα σχόλια προσωπικές ιδέες ή αντιλήψεις των Επαγγελματιών Υγείας, οι οποίοι διατηρούν στο ακέραιο την ευθύνη σχετικά με το περιεχόμενο που δημοσιοποιούν καθώς και για τυχόν παραβίαση της ισχύουσας νομοθεσίας και οιονδήποτε δικαιωμάτων πνευματικής ιδιοκτησίας. Η Εταιρεία δεν ευθύνεται σε καμία περίπτωση για τυχόν ζημία που μπορεί να προκύψει από την αξιολόγηση ή τον σχολιασμό των δημοσιεύσεων.
					</p>
					<p>
					    <strong>5.2 </strong>
					    Οι διαχειριστές του Δικτυακού Τόπου δεν έχουν νομική υποχρέωση να ελέγχουν τα σχόλια, ούτε είναι εφικτός ο πλήρης και διαρκής έλεγχος. Παρ’ όλα αυτά διατηρούν το δικαίωμα να παρακολουθούν το υλικό κάθε μορφής επικοινωνίας μέσα στο Δικτυακό Τόπο και να αφαιρούν περιεχόμενο χωρίς καμία ειδοποίηση ή εξήγηση κατά την απόλυτη κρίση τους. Οποιαδήποτε αντίρρηση σχετικά με την αφαίρεση ανάρτησης μπορείτε να την απευθύνετε στην διεύθυνση contact@all4diabetes.gr
					</p>
					<p>
					    <strong>5.3 </strong>
					    Εάν θεωρείτε ότι το περιεχόμενο κάποιου σχολίου είναι προσβλητικό ή λανθασμένο, παραβιάζει τα προσωπικά δεδομένα ή τα δικαιώματα πνευματικής ιδιοκτησίας σας ή σας θίγει καθ’οιονδήποτε τρόπο, παρακαλείστε να επικοινωνήσετε άμεσα με τον Δικτυακό Τόπο και την Εταιρεία στην διεύθυνση contact@all4diabetes.gr
					</p>
					<p>
					    <strong>5.4 </strong>
					    Σε περίπτωση παραβίασης οποιουδήποτε από τους παραπάνω κανόνες, η Εταιρεία διατηρεί το δικαίωμα να απομακρύνει το προσβλητικό περιεχόμενο κατά την απόλυτη κρίση της, επιφυλασσόμενη για οποιαδήποτε άλλη αξίωσή της.
					</p>
					<p>
					    <strong>6. </strong>
					    <strong>ΑΠΟΥΣΙΑ ΕΓΓΥΗΣΗΣ </strong>
					</p>
					<p>
					    <strong>6.1</strong>
					    Το περιεχόμενο του Δικτυακού Τόπου παρέχεται «όπως ακριβώς είναι» χωρίς καμία εγγύηση ρητή ή τεκμαιρόμενη με οποιοδήποτε τρόπο, σχετικά με την εγκυρότητα, εμπορευσιμότητα ή την καταλληλότητα του για συγκεκριμένο σκοπό.
					</p>
					<p>
					    <strong>6.2 </strong>
					    Δεν παρέχεται καμία ρητή εγγύηση, ότι οι σελίδες, οι υπηρεσίες, οι λειτουργίες, οι επιλογές και τα περιεχόμενά του Δικτυακού Τόπου θα παρέχονται χωρίς διακοπή, χωρίς σφάλματα και ότι τα λάθη θα διορθώνονται. Επίσης μολονότι καταβάλλουμε κάθε δυνατή προσπάθεια, δεν παρέχεται καμία εγγύηση ότι ο Δικτυακός Τόπος δεν περιέχει "ιούς" ή άλλα επιζήμια συστατικά.
					</p>
					<p>
					    <strong>6.3 </strong>
					    Σε καμία περίπτωση η Εταιρεία και τα πρόσωπα που συνδέονται με αυτήν δεν είναι υπεύθυνοι απέναντί στο χρήστη για οποιαδήποτε τυχόν ζημία προκληθεί σε αυτόν από την χρήση του Δικτυακού Τόπου, στην χρήση του οποίου προβαίνει με δική του πρωτοβουλία και ευθύνη.
					</p>
					<p>
					    <strong>7. </strong>
					    <strong>ΠΕΡΙΟΡΙΣΜΟΣ ΕΥΘΥΝΗΣ</strong>
					</p>
					<p>
					    <strong>7.1 </strong>
					    Τονίζεται ότι η Εταιρεία, δεν προσφέρει ιατρικές συμβουλές μέσω του παρόντος Δικτυακού Τόπου. Μην αναζητάτε ιατρικές συμβουλές στον Δικτυακό Τόπο, καθώς σε αυτόν προσφέρονται μόνο γενικής φύσης ιατρικές πληροφορίες και πληροφορίες σχετικά με την υγεία σε επίπεδο γενικής πληροφορίας και όχι προσωπικής ιατρικής συμβουλής.
					</p>
					<p>
					    <strong>7.2 </strong>
					    Μέσω της φόρμας του Δικτυακού Τόπου «Ρωτήστε τον ειδικό» μπορείτε να επικοινωνήσετε με διαθέσιμους επαγγελματίες υγείας για να λάβετε αξιόπιστα και άμεσα απαντήσεις σε βασικές ιατρικές ερωτήσεις. Η δυνατότητα αυτή δεν αντικαθιστά τη φυσική παρουσία στο ιατρείο. Αποκλειστικά υπεύθυνος για τις απαντήσεις που δίνονται στους χρήστες που απευθύνουν τα ερωτήματά τους μέσω της ειδικά διαμορφωμένης φόρμας είναι ο επαγγελματίας υγείας. 
					</p>
					<p>
					    <strong>7.3 </strong>
					    Δεδομένης της φύσης και του όγκου των πληροφοριών του Διαδικτύου, υπό οποιεσδήποτε συνθήκες και από οποιαδήποτε αιτία, συμπεριλαμβανομένης και της περίπτωσης αμέλειας, η Εταιρεία δεν ευθύνεται για οποιασδήποτε μορφής ζημία, προκύψει από τη χρησιμοποίηση, διαθεσιμότητα ή μη διαθεσιμότητα των υπηρεσιών και περιεχομένων του Δικτυακού Τόπου, στην χρήση των οποίων ο επισκέπτης προβαίνει με αποκλειστικά δική του πρωτοβουλία και ευθύνη. 
					</p>
					<p>
					    <strong>8. </strong>
					    <strong>ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ ΚΑΙ ΛΟΙΠΟΙ ΟΡΟΙ</strong>
					</p>
					<p>
					    Οι ανωτέρω όροι και προϋποθέσεις χρήσης, καθώς και οποιαδήποτε τροποποίηση τους, διέπονται και συμπληρώνονται από το ελληνικό δίκαιο, το δίκαιο της Ευρωπαϊκής Ένωσης και τις σχετικές διεθνείς συνθήκες, που έχει υπογράψει η Ελλάδα. Όλοι οι ανωτέρω όροι συνομολογούνται ως ουσιώδεις. Οποιαδήποτε διάταξη των ανωτέρω όρων καταστεί αντίθετη προς το νόμο, παύει αυτοδικαίως να ισχύει και αφαιρείται από το παρόν, χωρίς σε καμία περίπτωση να θίγεται η ισχύς των λοιπών όρων.
					</p>
					<p>
					    Εάν δεν συμφωνείτε με τους παρόντες Όρους Χρήσης, οφείλετε να μη χρησιμοποιείτε τον Δικτυακό Τόπο.  
					</p>
					<p align="right">
					    Copyright &copy; { new Date().getFullYear()}, GET2WORK
					</p>
				</div>
			</div>
		);
		
	}
}

export default TermsPage;