import React, { Component } from 'react';
import HcpAvatar from '../../basicComponents/hcpAvatar';
import axios from 'axios';

class ActiveHcps extends Component {
	constructor() {
		super();
		
		this.state = {
			hcps:[],
			animationName: '',
			animationDuration:'',
			startAnimating:false
		};	
		
		this.onWindowResize = this.onWindowResize.bind(this);	
	}

	componentDidMount() {
		this.getActiveHcps();

		window.addEventListener("resize", this.onWindowResize);
		
		this.onWindowResize();
	}

	componentDidUpdate() {
		if (!this.state.startAnimating) {
			this.onWindowResize();
		}
	}

	onWindowResize() {
		//console.log('resize start');
		var section = document.getElementById('activehcps');
		if (section === undefined || section === null) {return};	
		var marquee = section.getElementsByClassName('marquee')[0];
		if (marquee === undefined) {return};		
		var marqueeContent = document.getElementById('marqueeElementsContainerForActiveHcps');
		var marqueeContents = marqueeContent.getElementsByClassName('hcpAvatar');

		let marqueeContentWidth = 0;
		
		if (marqueeContents.length < this.state.hcps.length || marqueeContents.length <=0 ) {
			return;
		} else {
			var lastElement = marqueeContents[(marqueeContents.length-1)].getElementsByClassName('hcpAvatarName')[0].getElementsByTagName('a')[0];			
			// lastElement is needed to check if last child component has been mounted WITH props
			if (!this.state.startAnimating && lastElement.offsetWidth>0) {
				this.setState({
					startAnimating: true
				})	
			} 
		}
		for (var i =0; i<marqueeContents.length; i++) {
			marqueeContentWidth+= marqueeContents[i].offsetWidth;
		}
		
		let styleSheet = document.styleSheets[0];
 
	    let animationName = 'marquee-active-hcps';

	    let animationSpeed = 150;

	    let animationDuration = parseInt( (marqueeContentWidth+marquee.offsetWidth) / (animationSpeed ) ) + 's';

	    var toMove = -(marqueeContentWidth+marquee.offsetWidth);
	    //console.log(toMove);
	    let keyframes =
	    `@keyframes ${animationName} {
	        0% {transform:translate3d(0px, 0px,0px)} 
	        100% {transform:translate3d(${toMove}px, 0px,0px)}
	    }`;	 	
	    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
	    keyframes =
	    `@-webkit-keyframes ${animationName} {
	        0% {-webkit-transform:translate3d(0px, 0px,0px)} 
	        100% {-webkit-transform:translate3d(${toMove}px, 0px,0px)}
	    }`;	 	
	    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
	    //console.log('resize end');
	   /* var rules = styleSheet.rules;
	    var i =rules.length;
	    var allKeyFrames;
	    var myKeyFrame;

	    while (i--) {
		    allKeyFrames = rules.item(i);
		    
		    if (
		        (
		               allKeyFrames.type === allKeyFrames.KEYFRAMES_RULE
		            || allKeyFrames.type === allKeyFrames.WEBKIT_KEYFRAMES_RULE
		        )
		        && allKeyFrames.name === animationName
		    ) {
		        rules = allKeyFrames.cssRules;
		        i = rules.length;
		        while (i--) {
		            myKeyFrame = rules.item(i);
		            if (
		                (
		                       myKeyFrame.type === myKeyFrame.KEYFRAME_RULE
		                    || myKeyFrame.type === myKeyFrame.WEBKIT_KEYFRAME_RULE
		                )
		                && myKeyFrame.keyText === "100%"
		            ) {
		                myKeyFrame.style.webkitTransform =
		                myKeyFrame.style.transform =
		                    "translate3d(0, " + toMove + "px, 0)";
		                break;
		            }
		        }
		        break;
		    }
		}*/

		this.setState({
			animationName: animationName,
			animationDuration: animationDuration
		});
		
    }

	getActiveHcps() {
		let hcps = [];
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/activeHcps', {})
	      .then(res => {
	        hcps = res.data.data;	        
	        this.setState({
	        	hcps : hcps	        	
			});			
	    }).catch(err => console.log('Login: ', err));
	}
	
	render() {	
		let style = {
			animationName: this.state.animationName,
			animationTimingFunction: 'linear',
			animationDuration: this.state.animationDuration,
			animationDelay: '0.0s',
			animationIterationCount: 'infinite',
			animationDirection: 'normal',
			animationFillMode: 'forwards'
		};

		return (
			<section id="activehcps" className="active-hcps">
				<div className="container">
					<div className="d-flex flex-row col p-0">
						<div className="d-flex marquee-title">Ενεργοί Επαγγελματίες Υγείας</div>
						<div className='marquee w-100'>
							<div id="marqueeElementsContainerForActiveHcps" style={style}>
								<div className="d-flex align-items-center justify-content-left">
									{ this.state.hcps.length > 0 ?
										this.state.hcps.map((hcp, index) => {
											return <HcpAvatar hcpObj={hcp} key={hcp.id}/>			                    
										})
										:<span>No active HCPs</span>	
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		);
	}
}

export default ActiveHcps;
