import React, { Component } from 'react';
import Article from '../../basicComponents/article';
import Advertisement from '../../basicComponents/advertisement';

class IndicatedArticles extends Component {
	
	render() {
		
		if (this.props.articles.length > 0) {
			var mostRecentArticleWithoutPositionIndex = 0;			
			var articleInPosition1 = this.props.articles.find(a => a.indicated_position === 1);
			var articleInPosition2 = this.props.articles.find(a => a.indicated_position === 2);
			var articleInPosition3 = this.props.articles.find(a => a.indicated_position === 3);
			var mostRecentArticlesWithoutPositions = this.props.articles.filter(a => a.indicated_position === null);
			if (articleInPosition1 === undefined) {
				articleInPosition1 = mostRecentArticlesWithoutPositions[mostRecentArticleWithoutPositionIndex];
				mostRecentArticleWithoutPositionIndex++;
			}
			if (articleInPosition2 === undefined) {
				articleInPosition2 = mostRecentArticlesWithoutPositions[mostRecentArticleWithoutPositionIndex];
				mostRecentArticleWithoutPositionIndex++;
			}
			if (articleInPosition3 === undefined) {
				articleInPosition3 = mostRecentArticlesWithoutPositions[mostRecentArticleWithoutPositionIndex];
				mostRecentArticleWithoutPositionIndex++;
			}
			
			return (				
				<section className="indicated-articles" id='indicated-articles-section'>
					<div className="container">
						<div className="row">							
							<div className={(this.props.isLoggedIn && this.props.adObj) ? "col-12 col-md-9" : "col-12"}>
								<div className="section-title">
									<h2>Επιλεγμένα άρθρα</h2>
								</div>
								<div className="row">
									<div className="col-xl-6 col-md-12 col-sm-12">
										<Article {...this.props} articleObj={articleInPosition1} class="item-large"/>
									</div>
									<div className="col-xl-3 col-md-6 col-sm-12">
										<Article {...this.props} articleObj={articleInPosition2} class="item-medium"/>
									</div>
									<div className="col-xl-3 col-md-6 col-sm-12">
										<Article {...this.props} articleObj={articleInPosition3} class="item-medium"/>
									</div>
								</div>
							</div>
							<div className={(this.props.isLoggedIn && this.props.adObj) ? "col-12 col-md-3 d-flex align-items-center" : "d-none"}><Advertisement adObj = {this.props.adObj}/></div>
						</div>
					</div>
				</section>
				
			);
		} else {
			return (
				<section className="indicated-articles">
					
				</section>				
			);
		}
	}
}

export default IndicatedArticles;