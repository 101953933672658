import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import logo from '../../Assets/img/logo.png';
/*import espaBanner from '../../Assets/img/bannersdallETPA.JPG';
import espaPdf from '../../Assets/files/WEB_ΕΣΠΑ.pdf';*/
import possasdiaBanner from '../../Assets/img/possasdia_banner2.png';
import { HashLink } from 'react-router-hash-link';
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
  	userData: {
  		isLoggedIn : state.user.isLoggedIn
  	}
  };
};

class ConnectedFooter extends Component {	
	

	componentDidUpdate(prevProps){		
		if(prevProps.userData !== this.props.userData){ 
	    	/*
				Οτι ειναι εδω μεσα τρεχει καθε φορα που αλλαζει το isloggedin στο store. Η δομη του prop που περναει ειναι οτι βλεπεις πανω στο mapStateToProps (this.props.userData.isLoggedIn)
	    	*/
	    }
	}

	render() {	
		
		return (
			<footer>
				<div className="container">
					<div className="row">
						<NavLink to="" onClick={() => window.scrollTo(0, 0)}  className="footer-brand">
				        	<img src={logo} className="align-top img-fluid" alt="All4Diabetes logo"/>
				        </NavLink>
					</div>
			        <div className="row grid-divider">
			        	<div className="col col-lg-3 col-6 w-20">
			        		<div className="col-padding">
			        		<ul className="navbar-nav mr-auto">
			        			<li className="nav-item">
					        		<HashLink smooth  to="/#proposed-articles-section" >
							        	ΠΡΟΤΕΙΝΟΜΕΝΑ ΑΡΘΡΑ
							        </HashLink>
						        </li>
						        <li className="nav-item">
							        <HashLink smooth  to="/#popular-articles-section">
							        	ΔΗΜΟΦΙΛΗ ΑΡΘΡΑ
							        </HashLink>
						        </li>
						        <li className="nav-item">
							        <HashLink smooth to="/#activehcps" >
							        	ΕΝΕΡΓΟΙ ΕΥ
							        </HashLink>
						        </li>
						        {/*<li className="nav-item">
							        <NavLink to="/" className="" activeClassName="active">
							        	ΑΝΑΖΗΤΗΣΗ
							        </NavLink>
						        </li>*/}
					        </ul>
					        </div>
			        	</div>
			        	<div className="col col-lg-3 col-6 w-20">
			        		<div className="col-padding">
			        		<ul className="navbar-nav mr-auto">
				        		<li className="nav-item dropdown">
					            	<a className="nav-link dropdown-toggle" href="/" id="navbarDropdown4" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										ΚΑΤΗΓΟΡΙΕΣ
									</a>
									<div className="dropdown-menu" aria-labelledby="navbarDropdown4">
										{
											this.props.tags.map((tag, index) => {													
							                    return (
							                       <NavLink className="dropdown-item" key={index} to={"/category/"+tag.category_id}>{tag.title}</NavLink> 
							                    )
							                })
						                }   											
									</div>
					            	
					            </li>
					            <li className="nav-item dropdown">
							        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown5" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						                ΦΟΡΕΙΣ ΥΓΕΙΑΣ
									</a>
									<div className="dropdown-menu" aria-labelledby="navbarDropdown5">
										{
											this.props.catalogTypes.map((catalog, index) => {													
							                    return (
							                       <NavLink className="dropdown-item" key={index} to={"/catalogs/"+catalog.id}>{catalog.name}</NavLink> 
							                    )
							                })
						                }
									</div>
						        </li>
						        {	
			        				this.props.userData.isLoggedIn ? 
								        <li className="nav-item">
									        <NavLink to="/medicalArticlesList" className="nav-link" activeClassName="active">
									        	ΔΗΜΟΣΙΕΥΣΕΙΣ
									        </NavLink>
								        </li>
								    : ''
								}
								<li className="nav-item">
							        <NavLink to="/events" className="nav-link" activeClassName="active">
							        	ΕΚΔΗΛΩΣΕΙΣ
							        </NavLink>
						        </li>
						    </ul>
						    </div>
			        	</div>
			        	
			        	
			        	<div className="col col-lg-3 col-6 w-20">
			        		<div className="col-padding">
			        		<ul className="navbar-nav mr-auto">
			        			<li className="nav-item">
					        		<NavLink to="/" data-toggle="modal" data-target="#loginModal" className="" activeClassName="active">
							        	ΕΓΓΡΑΦΗ
							        </NavLink>
						        </li>
						        <li className="nav-item">
							        <NavLink to="/contact" className="nav-link" activeClassName="active">
							        	ΕΠΙΚΟΙΝΩΝΙΑ
							        </NavLink>
						        </li>
						        <li className="nav-item">
							        <NavLink to="/terms" className="nav-link" activeClassName="active">
							        	ΟΡΟΙ ΧΡΗΣΗΣ ΚΟΙΝΟΥ
							        </NavLink>
						        </li>
						        <li className="nav-item">
							        <NavLink to="/terms_hcps" className="nav-link" activeClassName="active">
							        	ΟΡΟΙ ΧΡΗΣΗΣ ΕΠΑΓΓΕΛΜΑΤΙΩΝ ΥΓΕΙΑΣ
							        </NavLink>
						        </li>
						        <li className="nav-item">
							        <NavLink to="/privacy_policy" className="nav-link" activeClassName="active">
							        	ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ
							        </NavLink>
						        </li>
					        </ul>
					        </div>
			        	</div>
			        	<div className="col col-lg-3 col-6 w-20">
			        		<div className="col-padding">
			        			{/*<a href={espaPdf} target="_blank" rel="noopener noreferrer nofollow"><img src={espaBanner} className="align-top img-fluid" alt="ΕΣΠΑ"/></a>*/}

			        			<a id="possasdiaLink" href="https://glikos-planitis.gr/" target="_blank" rel="noopener noreferrer nofollow"><img  src={possasdiaBanner} className="align-top img-fluid mb-2" alt="Υπό την αιγίδα της Πανελλήνιας Ομοσπονδίας Σωματείων - Συλλόγων Ατόμων με Σακχαρώδη Διαβήτη (Π.Ο.Σ.Σ.Α.Σ.ΔΙΑ.)" />Υπό την αιγίδα της Πανελλήνιας Ομοσπονδίας Σωματείων - Συλλόγων Ατόμων με Σακχαρώδη Διαβήτη (Π.Ο.Σ.Σ.Α.Σ.ΔΙΑ.)</a>
					        </div>
			        	</div>
			    	</div>
			    	{/*<div className="row">
			    		<a href={espaPdf} target="_blank"><img src={espaBanner} className="align-top img-fluid" alt="ΕΣΠΑ"/></a>
			    	</div>  */}
			    	<div className="row">
			    		<div className="col-6 d-flex justify-content-center copyrights">
			    			Copyright &copy; { new Date().getFullYear()} GET2WORK - All Rights reserved
			    		</div>
			    	</div>			    	
			    </div>
			    
			</footer>
			
		);
	}
}

const Footer = connect(mapStateToProps,null,null,{pure:false})(ConnectedFooter);

export default Footer;
