import React, { Component } from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
//import { browserHistory } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

import './Assets/css/default.min.css';
import './Assets/css/homepage.min.css';
import './Assets/js/main.js';

import {array_move} from './Assets/js/main.js';
import {getIndex} from './Assets/js/main.js';

import CategoryPage from './componentsMobile/pages/categoryPage';
import CatalogPage from './components/pages/catalogPage';
import ArticlePage from './componentsMobile/pages/articlePage';
import HomePage from './componentsMobile/pages/homePage';


class App extends Component {
	constructor() {
        super()
        this.state = {
        	loggedInUser: {},
            articles: [],
            medicalArticles: [],
            articlesLoaded: false,
            medicalArticlesLoaded: false,
            tags: [],
            tagsLoaded: false,
            catalogTypes:[]
        };
        this.getAllArticles = this.getAllArticles.bind(this);        
        this.getAllTags = this.getAllTags.bind(this);
        this.getAllCatalogTypes = this.getAllCatalogTypes.bind(this);
      
    }

    componentWillMount() {

    }

    componentWillUnmount() {
    	/*window.onpopstate  = (e) => {
			debugger;
		}*/
    }

    componentDidUpdate(){
    	
    }


    componentDidMount() {  
    	

		this.getAllTags();
    	
		
    }

    handleBackPress(event) {
    	
    	if ( $('.modal-backdrop').hasClass('show') ) {
    		
			event.preventDefault();
			event.stopPropagation();
			
			window.$('.modal').modal('hide');			
			$('.modal-backdrop').remove();
			$("body").removeClass('modal-open');
			$("body").css('padding-right',0);
			setTimeout(function(){
				window.history.go(1);
			}, 10);
			
			return false;
    	}
    	
	}

    getAllArticles() {
    	let allArticles = [];
		this.setState({
	        	articles : allArticles,
	        	articlesLoaded : true,
	        });	
	    this.getAllCatalogTypes();

	    // NO NEED TO FETCH 500 articles. Each component makes its own specific requests for articles
    	/*axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/articles', {})
	      .then(res => {
	      	
	      	console.log(window.serverURL);
	        allArticles = res.data.data;
	        // console.log(allArticles);
	        this.setState({
	        	articles : allArticles,
	        	articlesLoaded : true,
	        });	
	        this.getAllCatalogTypes();
	      }).catch(err => console.log('Login: ', err));*/
    }

    getAllTags() {
    	let allTags = [];
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/tags', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allTags = res.data.data;

	        var allTagsSorted = allTags.sort((a, b) => (a.title < b.title) ? -1 : 1);
			array_move(allTagsSorted,getIndex(16,allTagsSorted,'id'),(allTagsSorted.length-1));
	        
	        this.setState({
	        	tags : allTagsSorted,
	        	tagsLoaded: true
	        });	        
	        this.getAllArticles();
	      }).catch(err => 
	      		console.log('Login: ', err)
	      );
	}

	getAllCatalogTypes() {
		let allCatalogTypes = [];
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/catalog_types', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allCatalogTypes = res.data.data;
	        //console.log(allTags);
	        this.setState({
	        	catalogTypes : allCatalogTypes,	        	
	        });
	        
	      }).catch(err => 
	      	console.log('Login: ', err)
	      	);
	}
    

	render() {
		return (
			<Router>
				<div className="AppMobile" role="main">
					<Route exact path="/" 
						render={
							(props)=> <HomePage {...props} tags = {this.state.tags} articles = {this.state.articles} medicalArticles = {this.state.medicalArticles} articlesLoaded={this.state.articlesLoaded} medicalArticlesLoaded={this.state.medicalArticlesLoaded} tagsLoaded={this.state.tagsLoaded}/>
						} 
					/>
					<Route path="/catalogs/:id" component={CatalogPage} />					
					<Route exact path="/category/:id" 
						render={
							(props)=><CategoryPage {...props} navigation={this.props.navigation} tags = {this.state.tags} articles = {this.state.articles} articlesLoaded={this.state.articlesLoaded} tagsLoaded={this.state.tagsLoaded}/>
						} 
					/>

					<Switch>
						<Route path="/article/:id/:articleTitle" render={(props)=> <ArticlePage {...props}/>} />
						<Route path="/article/:id" render={({ match }) => (
							<Redirect to={`/article/${match.params.id}/articleTitle`} />
						)} />
						<Route path="/article/:id/" render={({ match }) => (
							<Redirect to={`/article/${match.params.id}/articleTitle`} />
						)} />
					</Switch>

				</div>
			</Router>
		);
	}
}


export default App;

