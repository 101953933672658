import React, {Component} from 'react';
import '../../Assets/css/catalogpage.min.css';
import {array_move} from '../../Assets/js/main.js';
import {getIndex} from '../../Assets/js/main.js';
import Catalog from '../basicComponents/catalog';
import axios from 'axios';
import {Helmet} from "react-helmet";

class CatalogPage extends Component {

	constructor() {
		super();
		
		this.state = {
			catalogs:[],			
		    catalogTypeId: 0, //Catalog Type ID
		    catalogTypeName: '',
		    catalogTypeIcons: [
		    	"",
		    	"fa fa-user-md",
		    	"fa fa-hospital-o",
		    	"fa fa-building"
		    ],
		    searchValue:''
		};		
	}

	componentDidMount() {		
		window.scrollTo(0,0);		
		this.state.catalogTypeId = this.props.match.params.id;
		this.getCatalogsForCategory()
		
		
	}
	
	componentDidUpdate() {				
		if (this.props.match.params.id !== this.state.catalogTypeId) {
			this.state.catalogs.length = 0;			
			this.state.catalogTypeId = this.props.match.params.id;
			this.getCatalogsForCategory();	
		}		
	}	

	filterCatalogs(e) {
		let inputValue = e.target.value;		
		this.setState({
			searchValue: inputValue
		})
	}

	getCatalogsForCategory() {		
		let catalogs = [];
    	let category = {
    		catalog_id: this.state.catalogTypeId
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/catalogs', category)
	      .then(res => {
	        catalogs = res.data.data;	        
	        this.setState({
	        	catalogs : catalogs	        	
			});
			if (catalogs.length>0) {
				this.setState({
					catalogTypeName:catalogs[0].catalog_type_name
				});
			}
	    }).catch(err => 
	    	console.log('Login: ', err)
	    );

	}

	/*array_move(arr, old_index, new_index) {
	    if (new_index >= arr.length) {
	        var k = new_index - arr.length + 1;
	        while (k--) {
	            arr.push(undefined);
	        }
	    }
	    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);	    
	};

	getIndex(value, arr, prop) {
	    for(var i = 0; i < arr.length; i++) {
	        if(arr[i][prop] === value) {
	            return i;
	        }
	    }
	    return -1; 
	}*/
	
	render() {
		const catalogTypeIcon = (this.state.catalogTypeIcons[this.state.catalogTypeId]) ? <i className={this.state.catalogTypeIcons[this.state.catalogTypeId]} aria-hidden="true"></i> : '';
		let filteredCatalogs = this.state.catalogs.filter(
				(catalog) => {
					let inTitle = (catalog.title) ? catalog.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.state.searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1 : false;
					let inAddress = (catalog.address) ? catalog.address.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.state.searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1 : false;
					let inPhone = (catalog.phone) ? catalog.phone.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.state.searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1 : false;
					let inCategory = (catalog.category) ? catalog.category.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.state.searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1 : false;
					let inEmail = (catalog.email) ? catalog.email.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.state.searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1 : false;

					return inTitle || inAddress || inPhone || inCategory || inEmail;
					
				}
			);
		if (this.state.catalogs) {			
			if (this.state.catalogs.length>0) {
				const catalogTypeIcon = (this.state.catalogTypeIcons[this.state.catalogTypeId]) ? <i className={this.state.catalogTypeIcons[this.state.catalogTypeId]} aria-hidden="true"></i> : '';
				
				if (this.state.catalogTypeId === "3") {
					var possasdiaCatalogs = filteredCatalogs.filter(it => it.category==="ΠΟΣΣΑΣΔΙΑ");				
					array_move(possasdiaCatalogs,getIndex(14,possasdiaCatalogs,'id'),0);

					var elodiCatalogs = filteredCatalogs.filter(it => it.category==="ΕΛΟΔΙ");
					array_move(elodiCatalogs,getIndex(15,elodiCatalogs,'id'),0);
					array_move(elodiCatalogs,getIndex(215,elodiCatalogs,'id'),0);
					
					return (
						<div className="container-fluid catalogPage associations" id="catalog_page_parent">
							<Helmet
								title={this.state.catalogTypeName + " | All4Diabetes"}
								meta={[
								
								]}
							/>
							<h3 className="catalog-page-title d-flex justify-content-center align-items-center">
								<div className="container">
									<div className="row d-flex justify-content-between align-items-center">
									    <div className="container d-flex align-items-center justify-content-center col-12 col-lg-6 mt-3 mt-lg-0 mb-2 mb-lg-0">
									    	{catalogTypeIcon}	{this.state.catalogTypeName}
									    </div>
									    <div className="container d-flex justify-content-center col-12 col-lg-6 mb-3 mb-lg-0">
									    	<input aria-label="Search" name="search" className='form-control' placeholder="Αναζήτηση..." type="text" value={this.state.searchValue} onChange={this.filterCatalogs.bind(this)} />
									    </div>
									</div>
								</div>
							</h3>
							
				        	<div className="container d-flex flex-row justify-content-between flex-wrap">

				        		<div className="col-12 col-lg-6">
				        			<h3 className="text-center"><strong></strong></h3>
									{possasdiaCatalogs.map((catalog, index) => {	
										if (catalog)  {
											return 	<Catalog catalogCategory={this.state.catalogTypeName} searchValue={this.state.searchValue} catalogObj={catalog} parentClassName="catalog-page" class="catalog-page-article" key={catalog.id}/>
										} else {

											return null;
										}
									})}	
								</div>
								<div className="col-12 col-lg-6">
									<h3 className="text-center"><strong></strong></h3>
									{elodiCatalogs.map((catalog, index) => {
										if (catalog)  {
											return 	<Catalog catalogCategory={this.state.catalogTypeName} searchValue={this.state.searchValue} catalogObj={catalog} parentClassName="catalog-page" class="catalog-page-article" key={catalog.id}/>
										} else {

											return null;
										}
									})}	
								</div>
							</div>					
							
							
						</div>
						
					);	
				} else {
					return (
						<div className="container-fluid catalogPage" id="catalog_page_parent">
							<Helmet
								title={this.state.catalogTypeName + " | All4Diabetes"}
								meta={[
								
								]}
							/>
							
							<h3 className="catalog-page-title d-flex justify-content-center align-items-center">

								<div className="container">
									<div className="row d-flex justify-content-between align-items-center">
									    <div className="container d-flex align-items-center justify-content-center col-12 col-lg-6 mt-3 mt-lg-0 mb-2 mb-lg-0">
									    	{catalogTypeIcon}	{this.state.catalogTypeName}
									    </div>
									    <div className="container d-flex justify-content-center col-12 col-lg-6 mb-3 mb-lg-0">
									    	<input aria-label="Search" name="search" className='form-control' placeholder="Αναζήτηση..." type="text" value={this.state.searchValue} onChange={this.filterCatalogs.bind(this)} />
									    </div>
									</div>
								</div>
								
							</h3>
				        	<div className="container d-flex flex-row justify-content-between flex-wrap">
								{filteredCatalogs.map((catalog, index) => {
									return <Catalog catalogCategory={this.state.catalogTypeName} searchValue={this.state.searchValue} catalogObj={catalog} parentClassName="catalog-page" class="catalog-page-article" key={catalog.id}/>			                    
								})}	
							</div>					
							
							
						</div>
						
					);	
				}
				
			}
		}		
		
		return (
			
			<div className="container-fluid catalogPage" id="catalog_page_parent">
				
				<h3 className="catalog-page-title d-flex justify-content-center align-items-center">{catalogTypeIcon}	Δεν υπάρχουν κατάλογοι για αυτήν την κατηγορία.</h3>
		        		
			</div>
		);
		
	}
}

export default CatalogPage;