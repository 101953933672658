import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import $ from 'jquery';
import Article from '../../basicComponents/article';

class HomeBanner extends Component {
	constructor() {
		super();		
		this.mySwiper = React.createRef();		
		this.onWindowResize = this.onWindowResize.bind(this);
	}

	componentDidMount() {
		this.onWindowResize();		
		window.addEventListener("resize", this.onWindowResize);		
	}

	componentDidUpdate() {
		this.onWindowResize();		
	}

	onWindowResize() {
		const windowWidth = $(window).width();	
		
		if (this.mySwiper.current) {
			//console.log('here');
			if (windowWidth < 991 ) {			
				this.mySwiper.current.swiper.autoplay.start();			
				$(this.mySwiper.current.swiper.el).find('.swiper-button-next, .swiper-button-prev').show();			
			} else {			
				this.mySwiper.current.swiper.autoplay.stop();			
				$(this.mySwiper.current.swiper.el).find('.swiper-button-next, .swiper-button-prev').hide();
			}	
		} else {
			
			if (windowWidth < 991 ) {
				$('#homeBanner').find('.swiper-button-next, .swiper-button-prev').show();
			} else {
				$('#homeBanner').find('.swiper-button-next, .swiper-button-prev').hide();
			}
		}
		
        
    }
	
	render() {	
		//var interleaveOffset = 0.5;
		const params = {	
			loop: true,	
			loopedSlides:4,
			allowSlidePrev: false,
			allowSlideNext: false,
			autoplay:{
				disableOnInteraction:false
			},	
			speed: 1500,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
			},			
			fadeEffect: {
				crossFade: true
			},			
			slidesPerView: 'auto',
			spaceBetween: 0,
			breakpoints: {
				991: {					
					slidesPerView: 1,
					spaceBetween:0,
					allowSlidePrev: true,
					allowSlideNext: true
				}
			}
			
		};	
		
		if (this.props.articles.length > 0) {
			const windowWidth = $(window).width();
			if (windowWidth < 991 ) {	
				return (
					<section id="homeBanner">
						<Swiper ref={this.mySwiper} {...params} key={'swiper1'} id="swiper1">
							<div className="swiperItem middleItem col-12 col-lg-6">
								
								<Article {...this.props} articleObj={this.props.articles[0]}/>
								
							</div>
							<div className="swiperItem col-12 col-lg-3">
								
								<Article {...this.props} articleObj={this.props.articles[1]}/>
								
								
								<Article {...this.props} articleObj={this.props.articles[2]}/>
								
							</div>
							
							<div className="swiperItem col-12 col-lg-3">
								
								<Article {...this.props} articleObj={this.props.articles[3]}/>
							
							
								<Article {...this.props} articleObj={this.props.articles[4]}/>
								
							</div>
						</Swiper>
					</section>				
				);
			} else {
				return (
					<section id="homeBanner">
						<Swiper ref={this.mySwiper} {...params} key={'swiper1'} id="swiper1">
							<div className="swiperItem col-12 col-lg-3">
								
								<Article {...this.props} articleObj={this.props.articles[1]}/>
								
								
								<Article {...this.props} articleObj={this.props.articles[2]}/>
								
							</div>
							<div className="swiperItem middleItem col-12 col-lg-6">
								
								<Article {...this.props} articleObj={this.props.articles[0]}/>
								
							</div>
							<div className="swiperItem col-12 col-lg-3">
								
								<Article {...this.props} articleObj={this.props.articles[3]}/>
							
							
								<Article {...this.props} articleObj={this.props.articles[4]}/>
								
							</div>
						</Swiper>
					</section>				
				);
			}	
		} else {
			return (
				<section id="homeBanner">
					<Swiper ref={this.mySwiper} {...params}>
						<div className="swiperItem col-12 col-md-3">
							
							
							
						</div>
						<div className="swiperItem middleItem col-12 col-md-6">
							
							
							
						</div>
						<div className="swiperItem col-12 col-md-3">
							
							
							
						</div>
					</Swiper>
				</section>				
			);	
		}
		
	}
}

export default HomeBanner;
