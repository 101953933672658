import React, { Component } from 'react';
import Article from '../../basicComponents/article';
import Advertisement from '../../basicComponents/advertisement';

class ProposedArticles extends Component {
	
	render() {
		if (this.props.articles.length > 0) {
			return (
				
				<section className="proposed-articles" id="proposed-articles-section">
					<div className="container">
						
						<div className="section-title">
							<h2>Προτεινόμενα άρθρα</h2>
						</div>
						<div className="row">							
							<div className="col-sm-6 col-12">
								<Article {...this.props} articleObj={this.props.articles[0]} class="item-small"/>
								<Article {...this.props} articleObj={this.props.articles[1]} class="item-small"/>
								<Article {...this.props} articleObj={this.props.articles[2]} class="item-small"/>
							</div>
							<div className="col-sm-6 col-12">
								<Article {...this.props} articleObj={this.props.articles[3]} class="item-small"/>
								<Article {...this.props} articleObj={this.props.articles[4]} class="item-small"/>
								<Article {...this.props} articleObj={this.props.articles[5]} class="item-small"/>
							</div>
						</div>
						
					</div>
				</section>
				
			);
		} else {
			return (
				<section className="proposed-articles">
					
				</section>				
			);
		}
	}
}

export default ProposedArticles;