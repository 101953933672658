import React, { Component } from "react";
// import $ from "jquery";
import { connect } from "react-redux";

class ConnectedErrorModal extends Component {
	constructor() {
		super();

		this.state = {
			message: "",
			isModalOpen: false,
			modalElement: null
		};

		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {  
    	this.setState({
			modalElement: window.$("#error-modal").modal('hide')
		});
    }

	componentDidUpdate(prevProps){
		if (prevProps.errorData.shouldShow !== this.props.errorData.shouldShow) {
			this.setState({
				isModalOpen: this.props.errorData.shouldShow
			},()=>{
				if (this.state.isModalOpen) {
					this.state.modalElement.modal('show');
				}else{
					this.state.modalElement.modal('hide');
				}
			});
		}

		if (prevProps.errorData.message !== this.props.errorData.message) {
			this.setState({
				message: this.props.errorData.message
			});
		}
	}

	closeModal(e){
		this.setState({
			isModalOpen: false
		});
	}

	render() {
		return (
			<div id="error-modal" className="modal" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<p>{this.state.message}</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		errorData:{
			shouldShow: state.error.shouldShow,
			message: state.error.message
		}
	};
};

const ErrorModal = connect(mapStateToProps)(ConnectedErrorModal);

export default ErrorModal;
