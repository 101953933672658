import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import '../../Assets/css/categorypage.min.css';
import Article from '../basicComponents/article';
import CustomFilters from '../basicComponents/customFilters';
import InfiniteScroll from "react-infinite-scroll-component";
import axios from 'axios';
import loadingSpinner from '../../Assets/img/loading_articles_spinner1.gif';
import $ from 'jquery';
import {Helmet} from "react-helmet";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

/*axios.get(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/articles', {
  cancelToken: source.token
}).catch(function (thrown) {
  if (axios.isCancel(thrown)) {
    console.log('Request canceled', thrown.message);
  } else {
    // handle error
  }
});*/

class CategoryPage extends Component {

	constructor() {
		super();
		
		this.state = {
			articles:[],
			articlesLoaded:false,
			tags:[],
			categoryColor:'',
			categoryTitle:'',
			items: [],
			chunks:[],
		    isLoading: true,
		    cursor: 0,
		    hasMore: true,
		    categoryId: 0,
		    fetchingArticlesFromDB: true,
		    totalArticlesCount:0,
		    lastLoadedArticleId:0,
		    latestArticleId: 0,
		    filterArticlesOrder:'descending',
		    filterArticlesBy:'date',
		    requestForMoreArticles:false
		};
		this.getTotalArticlesCount = this.getTotalArticlesCount.bind(this);
		this.getArticlesForCategory = this.getArticlesForCategory.bind(this);
		this.getMoreArticlesForCategory = this.getMoreArticlesForCategory.bind(this);
		
		this.handleArticleSorting = this.handleArticleSorting.bind(this);
		
	}

	componentDidMount() {
		window.scrollTo(0,0);
		this.state.categoryId = this.props.match.params.id;
		this.getTotalArticlesCount();
		this.getArticlesForCategory();
		
		/*this.makeChunks();
	    this.loadMore();*/
	}
	componentWillMount() {
		
	}
	componentDidUpdate() {	
		//console.log(this.props.match.params.id,this.state.categoryId )			
		if (this.props.match.params.id !== this.state.categoryId) {
			if (this.state.fetchingArticlesFromDB) {
				source.cancel('Operation canceled by the user.');	
			}
			
			this.state.articles.length = 0;
			this.state.articles = [];
			this.state.articlesLoaded = false;
			this.state.categoryId = this.props.match.params.id;
			this.state.lastLoadedArticleId = 0;
			this.state.latestArticleId = 0;
			this.state.filterArticlesOrder = 'descending';
		    this.state.filterArticlesBy = 'date';
		    this.state.requestForMoreArticles = false;
			this.getTotalArticlesCount();
			this.getArticlesForCategory();	
		} else {
			const node = ReactDOM.findDOMNode(this);

			if (node instanceof HTMLElement) {
				$('.customFilter').removeClass('selected');
			    const filterSelected = node.querySelector('.customFilter[by="'+this.state.filterArticlesBy+'"]');
			    if (filterSelected) {
			    	filterSelected.classList.add('selected');
			    	filterSelected.setAttribute('order',this.state.filterArticlesOrder);
			    }
			    
			    

			}
		}
		/*console.log(this.props);
		console.log(this.state);*/
		/*if (nextProps.match.params.id !== prevState.categoryId){
			console.log(nextProps);
			console.log(prevState);
			const currentCategoryId = nextProps.match.params.id;			
			return {
				articles:[],
				articlesLoaded:false,
				tags:[],
				categoryColor:'',
				items: [],
				chunks:[],
			    isLoading: true,
			    cursor: 0,
			    hasMore: true,
			    categoryId: currentCategoryId

			}
		}
		return null;*/
	}	

	

	getTotalArticlesCount() {
		let allArticlesCount = 0;
    	let parameters = {
    		category_id: this.state.categoryId    		
    	}

    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/articles_count', parameters)
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allArticlesCount = res.data.data;
	        
	        this.setState({
	        	totalArticlesCount : allArticlesCount[0].count,
	        	latestArticleId: allArticlesCount[0].lastArticle
			});	        
			
	    }).catch(err => 
	    	console.log('Login: ', err)
	    );
	}

	getArticlesForCategory() {		

		let allArticles = [];
		var lastArticle = 0;
		if (this.state.requestForMoreArticles === true) {			
			lastArticle = (this.state.filterArticlesOrder ==='descending') ? (this.state.lastLoadedArticleId-1) : this.state.lastLoadedArticleId;
		} else {
			lastArticle = (this.state.filterArticlesOrder ==='descending') ? this.state.latestArticleId : 0;
		}
		//console.log(lastArticle);
    	let parameters = {
    		category_id: this.state.categoryId,
    		rating: 1,
    		comments:1,
    		/*views: 1,*/
    		limitFetch:30,
    		lastLoadedArticleId: lastArticle,
    		order:this.state.filterArticlesOrder,
    		by:this.state.filterArticlesBy
    	} 

    	this.setState({ fetchingArticlesFromDB: true });
    	var self = this;
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/articles', parameters, 
    		{
				cancelToken: source.token
			}).catch(function (thrown) {
				if (axios.isCancel(thrown)) {					
					//console.log('Request canceled', thrown.message);
					//console.log(self.state);
					window.location.reload(false);
				} else {
				// handle error	
				}
			})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allArticles = res.data.data;	        
	        var lastLoadedArticleId = (this.state.filterArticlesOrder ==='descending') ? Math.min.apply(Math, allArticles.map(function(o) { return o.id; })) : Math.max.apply(Math, allArticles.map(function(o) { return o.id; }));
	       	
	        this.setState({
	        	articles : [...this.state.articles,...allArticles],
	        	articlesLoaded : true,
	        	tags:this.props.tags,
	        	lastLoadedArticleId:lastLoadedArticleId,
	        	requestForMoreArticles:false
			});	        
			if (allArticles.length>0) {
				if (this.state.categoryId === 'all') {
					this.setState({
						categoryColor:this.state.tags[0].color,
						categoryTitle:this.state.tags[0].title
					});
				} else {
					for (var i=0; i< this.state.tags.length;i++) {
						if (this.state.tags[i].title === this.state.articles[0].main_category) {
							this.setState({
								categoryColor:this.state.tags[i].color,
								categoryTitle:this.state.articles[0].main_category
							});
							break;
						}
					}
				}
			}
			this.setState({ fetchingArticlesFromDB: false });
			//console.log(this.state);
	      	
	    }).catch(err => 
	    	console.log('Error on trying to load articles for category')
	    );

	}

	getMoreArticlesForCategory() {
		this.setState({
			requestForMoreArticles:true
		},() => {			
			this.getArticlesForCategory()	
		})
	}

	handleFilterChange = (filter) => {	
		
		//var filteredArticles = this.handleArticleSorting(JSON.parse(JSON.stringify(this.state.articles)), filter);
		var allArticles = this.state.articles;

		this.setState({
			articles: [],
			lastLoadedArticleId: (filter.order == 'descending') ? Math.max.apply(Math, allArticles.map(function(o) { return o.id; })) : Math.min.apply(Math, allArticles.map(function(o) { return o.id; })),
			filterArticlesOrder:filter.order,
			filterArticlesBy:filter.by			
		},() => {
			//console.log(this.state);
			this.getArticlesForCategory()	
		});
	}

	handleArticleSorting(articlesToSort, filter) {
		let isReversed = filter.order === "descending" ? false : true;
		let orderKey;

		if (filter.by === "date") {
			orderKey = "created_at";
		} else if (filter.by === "eval") {
			orderKey = "article_rating";
		} else if (filter.by === "popul") {
			orderKey = "comments_count";
		}

		articlesToSort.sort(function(a, b) {
			var keyA,keyB;
			if (orderKey === "created_at") {
				keyA = new Date(a.created_at);
				keyB = new Date(b.created_at);
			} else {
				keyA = a[orderKey] ? parseFloat(a[orderKey]) : 0.0;
				keyB = b[orderKey] ? parseFloat(b[orderKey]) : 0.0;
			}

			// Compare the 2 dates

			if (!isReversed) {
				if (keyA > keyB) return -1;
				if (keyA < keyB) return 1;
			} else {
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
			}

			return 0;
		});

		return articlesToSort;
	}
	
	render() {
		const h2Styles = {
				textAlign:'center',
				padding:'1em 3em',
				width:'100%',
				backgroundColor:this.state.categoryColor+"55"
			}
		if (this.state.articles.length>0) {
			
			return (
				<div className="container-fluid categoryPage" id="catecory_page_parent">
					<Helmet
						title={this.state.categoryTitle + " | Άρθρα | All4Diabetes"}
						meta={[
						
						]}
					/>
					<h3 className="article-title" style={h2Styles}>{this.state.categoryTitle}</h3>
					<CustomFilters onSelectFilter={this.handleFilterChange} />
					
		        	<div className="container d-flex flex-row justify-content-between flex-wrap">
						{this.state.articles.map((article, index) => {
							return <Article articleObj={this.state.articles[index]} tags={this.state.tags} parentClassName="category-page" class="category-page-article" key={article.id}/>			                    
						})}	

					</div>	
					<div className="container d-flex flex-row justify-content-between flex-wrap">	
						{
							(this.state.totalArticlesCount > this.state.articles.length) ?
								<button id="loadMoreArticlesForCategory" onClick={this.getMoreArticlesForCategory} disabled={this.state.fetchingArticlesFromDB}> 
									Περισσότερα... {this.state.fetchingArticlesFromDB ? <img id="loadingSpinner" width="22" height="22" src={loadingSpinner} /> : ''}
								</button>
							: 
								<div className="d-flex text-center m-auto">Τέλος των άρθρων για αυτήν την κατηγορία</div>
						}
						
					</div>
				</div>
				
			);
		}
		return (
			<div className="container-fluid categoryPage" id="catecory_page_parent">
				<Helmet
						title={this.state.categoryTitle + " | Άρθρα | All4Diabetes"}
						meta={[
						
						]}
					/>
				{this.state.fetchingArticlesFromDB ? 
					<h3 className="article-title" style={h2Styles}>Γίνεται εύρεση άρθρων για αυτήν την κατηγορία....</h3> 
					:
					<h3 className="article-title" style={h2Styles}>Δεν υπάρχουν άρθρα για αυτήν την κατηγορία.</h3> 
				}
		        		
			</div>
		);
		
	}
}

export default CategoryPage;