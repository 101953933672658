import React, {Component,Fragment} from 'react';
//import $ from 'jquery';
import { connect } from "react-redux";

class ConnectedRating extends Component {

	constructor(props) {
        super(props);
        
        this.state = {
			ratingStars: [
				{
					class: 'fa fa-star-o',
					starId: 1
				},
				{
					class: 'fa fa-star-o',
					starId: 2
				},
				{
					class: 'fa fa-star-o',
					starId: 3
				},
				{
					class: 'fa fa-star-o',
					starId: 4
				},
				{
					class: 'fa fa-star-o',
					starId: 5
				}
			],
			rating: 0,
			actualRating: parseFloat(this.props.rating).toFixed(1),
			ratingNames: []
			//ratingNames: ["testName testSurname","testName testSurname","testName testSurname","testName testSurname","testName testSurname","testName testSurname","testName testSurname"]
		};

		//console.log(this.props.ratingNames.join('<br>'))

		this.handleRating = this.handleRating.bind(this);
		this.handleRatingHover = this.handleRatingHover.bind(this);
		this.handleRatingLeave = this.handleRatingLeave.bind(this);
		this.calculateRatingStars = this.calculateRatingStars.bind(this);
		this.changeRatingStars = this.changeRatingStars.bind(this);
    }

    componentDidMount() { 

    	this.calculateRatingStars(this.props.rating);

    	this.setState({
    		ratingNames: this.props.ratingNames.length > 9 ? [...this.props.ratingNames.slice(0, 10),'Περισσότερα...'] : this.props.ratingNames
    	});
    	
    	window.$('body').tooltip({
    		selector: '[data-toggle="tooltip"]'
    	});
    }

    componentDidUpdate(prevProps){
    	if(prevProps.rating !== this.props.rating){ 
	    	this.calculateRatingStars(this.props.rating);

	    	this.setState({
		    	actualRating: Number(this.props.rating).toFixed(1)
		    });
	    }

	    //console.log(this.props.ratingNames.join('<br>'))

	    if(prevProps.ratingNames !== this.props.ratingNames){ 
	    	this.setState({
		    	ratingNames: this.props.ratingNames.length > 9 ? [...this.props.ratingNames.slice(0, 10),'Περισσότερα...'] : this.props.ratingNames
		    });
	    }	    
    }

    handleRating(e){
    	let userRating = parseInt(e.target.getAttribute('starid'));

    	if (this.props.isLoggedIn) {
    		this.props.updateRating(userRating);
    	} else {
    		window.$('#loginModal').modal('show');
    	}
	}
	
	handleRatingHover(e){
		let targetKey = parseInt(e.target.getAttribute('starid'));

		if (this.props.isLoggedIn) {
    		let newRatingStars = this.changeRatingStars(targetKey);

			this.setState({
				ratingStars: newRatingStars
			});
    	}
	}
	
	handleRatingLeave(e){
		this.calculateRatingStars(this.state.rating);
	}

	calculateRatingStars(articleRating) {
		let newRating = Math.round(articleRating);
		let newRatingStars = this.changeRatingStars(newRating);

		this.setState({
			ratingStars: newRatingStars,
			rating: newRating
		});
	}

	changeRatingStars(starId){
		let ratingStars = this.state.ratingStars;

		for (var i = 0; i < ratingStars.length; i++) {
			if ((i+1)<=starId) {
				ratingStars[i].class = 'fa fa-star';
			}else{
				ratingStars[i].class = 'fa fa-star-o';
			}
		}

		return ratingStars;
	}

	render() {
		var style={
			cursor: 'pointer'
		}

		return(
			<Fragment>
				{this.state.ratingStars.map((item) => (
		          <i className={item.class} onMouseOver={(this.props.cannotUpdate) ? null : this.handleRatingHover} onMouseLeave={ (this.props.cannotUpdate) ? null : this.handleRatingLeave} onClick={(this.props.cannotUpdate) ? null : this.handleRating} aria-hidden="true" starid={item.starId} key={item.starId} style={style}></i>
		        ))}
		        <div href="#" 
		        	className="exact-rating d-inline-block ml-3" 
		        	data-toggle="tooltip" 
		        	data-placement="top" 
		        	data-html="true" 
		        	data-container="body" 
		        	data-original-title={this.state.ratingNames.join('<br>')}>{this.state.actualRating}</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
 return({
   	isLoggedIn: state.user.isLoggedIn
 });
};

const Rating = connect(mapStateToProps)(ConnectedRating);

export default Rating;
