import React, {Component/*, Fragment */} from 'react';

function AskTheExpertButton(props) {
	return (
		<a href="/" title="Επικοινωνία με τον αρθρογράφο / ειδικό για περισσότερες και πιο εξειδικευμένες πληροφορίες" className="d-flex align-items-center" id="askTheExpertButton" data-toggle="modal" data-target="#askTheExpertModal" target="_blank" rel="noopener noreferrer">
		Ρωτήστε τον ειδικό!
		</a>
	);
}

export default AskTheExpertButton;