import React from 'react';
import { Link } from 'react-router-dom';

//import Rating from '../../components/basicComponents/rating';

function Article(props) {
	var self = {};
	self.articleObj = props.articleObj;
	self.allTags = props.tags;
	
    
    //console.log(new Date(Date.parse(self.articleObj.created_at)));
    //console.log(new Date(Date.parse(self.created_at.toString().replace(' ','T'))));
    var dateOptions = { /*weekday: 'long',*/ year: 'numeric', month: 'short', day: 'numeric' };
	self.date = new Date(self.articleObj.created_at.replace(' ','T')).toLocaleDateString('el-EL', dateOptions);	
	
	self.divStyle = {
		//serve_images is configured from apache. Put it in front of imgUrl
        background: 'url(' + window.serverURL+"/serve_images/"+self.articleObj.images + ') no-repeat center center',
        backgroundSize: 'cover',
        
    };

	self.divStyle2 = {
		//serve_images is configured from apache. Put it in front of imgUrl
        background: 'url(' + window.serverURL+"/serve_images/"+self.articleObj.images + ') no-repeat center center',
        backgroundSize: 'cover',
        height: '100%'   
    };
       
    if (props.parentClassName === "homeBanner") {
    	return null;
    } else if (props.parentClassName === "proposed-articles") {
    	return null;	
    } else if (props.parentClassName === "popular-articles" || props.parentClassName === "popular-medical-articles popular-articles") {
    	return null;	
    } else if (props.parentClassName === "categories") {
    	return (
	    
	        <div className="swiper-slide">
	        	<div className={props.class+" article"} style={self.divStyle}>
		        	<div className="home_banner_text">	 
		        		<div className="image-overlay"></div>
		        		<div style={{position:'relative'}}>
			                <h3><Link to={{pathname:"/category/"+self.articleObj.catid}} >{self.articleObj.main_category} </Link></h3>	                
			                <p><Link to={{pathname:"/article/"+self.articleObj.id+"/"+self.articleObj.title, state:{articleInfo:self.articleObj,tags:self.tags,dateFormatted:self.date}}} >{self.articleObj.title} </Link></p>
			                <p><i className="fa fa-globe"></i><a href={self.articleObj.urls} target="_blank" rel="noopener noreferrer">{self.articleObj.source_name}</a>	<i className="fa fa-clock-o"></i>{self.date}</p>
		                </div>
		            </div>
		        </div>
	            

	        </div>
	    );	
    } else if (props.parentClassName === "category-page") {
    	return (
		    <div className={props.class +" article"} style={self.divStyle}>
		    	<Link to={{pathname:"/article/"+self.articleObj.id+"/"+self.articleObj.title, state:{articleInfo:self.articleObj,tags:self.tags,dateFormatted:self.date}}} >
		    	<div className="category-page-wrapper">		    		
	                <div className="home_banner_text">	        		
		                <div className="banner-tag">
		                	                 
		                </div>
		                <h2><Link to={{pathname:"/article/"+self.articleObj.id+"/"+self.articleObj.title, state:{articleInfo:self.articleObj,tags:self.tags,dateFormatted:self.date}}} >{self.articleObj.title} </Link></h2>
		                <p><i className="fa fa-globe"></i><a href={self.articleObj.urls} target="_blank" rel="noopener noreferrer">{self.articleObj.source_name}</a>	<i className="fa fa-clock-o"></i>{self.date}</p>
		            </div>
		    	</div>
	        	</Link>
	        </div>
	    );	
    }
    
}

export default Article;