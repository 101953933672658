import React, {Component} from 'react';
import { NavLink } from 'react-router-dom'

class TermsHcpsPage extends Component {

	constructor() {
		super();
		
		this.state = {
			
		};		
	}

	componentDidMount() {		
		window.scrollTo(0,0);
	}
	
	componentDidUpdate() {				
			
	}
	
	render() {
		return (
			
			<div className="container-fluid termsHcpsPage">
				<h3 className="event-page-title d-flex justify-content-center align-items-center">ΟΡΟΙ ΧΡΗΣΗΣ ΕΠΑΓΓΕΛΜΑΤΙΩΝ ΥΓΕΙΑΣ</h3>
				<div className="container">
					<p>
					    Η παρούσα ιστοσελίδα (στο εξής «<strong>Δικτυακός Τόπος</strong>») ανήκει
					    στην εταιρεία με την επωνυμία «GET2WORK ΜΟΝΟΠΡΟΣΩΠΗ ΙΚΕ» (Γ.Ε.ΜΗ.: 128127603000) και τον διακριτικό
					    τίτλο «GET2WORK», η οποία εδρεύει στην Αθήνα επί της οδού Ξανθίππης 26,
					    10444 (στο εξής «Εταιρεία»). Η χρήση του Δικτυακού Τόπου υπόκειται στους
					    παρόντες όρους χρήσης. Για την εγγραφή σας απαιτείται η συνάινεση σε
					    αυτούς, ενώ ακόμη και η απλή επίσκεψή σας χωρίς εγγραφή διέπεται από τους 
					    <NavLink to="/terms"> Όρους Χρήσης των επισκεπτών του Δικτυακού Τόπου</NavLink>. Σε περίπτωση που δεν συμφωνείτε με τους όρους χρήσης, οφείλετε
					    να μην ολοκληρώσετε την εγγραφή σας.
					</p>
					<p>
					    Σε περίπτωση που οι παρόντες Όροι Χρήσης τροποποιηθούν σε μεταγενέστερο της
					    εγγραφής σας χρόνο, θα ειδοποιηθείτε και σχετικά και θα σας ζητηθεί να τους
					    διαβάσετε και εγκρίνετε εκ νέου.
					</p>
					<p>
					    <strong>1. </strong>
					    <strong>ΧΡΗΣΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ – ΕΓΓΡΑΦΗ</strong>
					</p>
					<p>
					    <strong>1.1 </strong>
					    Οι πληροφορίες του Δικτυακού Τόπου παρέχονται στο κοινό δωρεάν. Μπορείτε και εσείς να επισκεφθείτε τον Δικτυακό Τόπο, να περιηγηθείτε σε αυτόν και να διαβάσετε τις σχετικές πληροφορίες χωρίς εγγραφή ή χρέωση.
					</p>
					<p>
					    <strong>1.2 </strong>
					    Με την εγγραφή σας ως Επαγγελματίας Υγείας, μπορείτε να προβληθείτε μέσω του Δικτυακού Τόπου (εμφάνιση στην καρτέλα «Κατάλογοι Υγείας»), να λαμβάνετε εβδομαδιαία newsletters για την πιο πρόσφατη αρθρογραφία που φιλοξενεί ο Δικτυακός Τόπος με τη δυνατότητα unsubscribe και να αξιολογείτε ή και να σχολιάζετε τα άρθρα που φιλοξενεί ο Δικτυακός Τόπος. Κατά την εγγραφή θα σας ζητηθεί να δηλώσετε το ονοματεπώνυμό σας, την ειδικότητά σας, μια έγκυρη ηλεκτρονική διεύθυνση (e-mail), τηλέφωνο, την πλήρη ταχυδρομική σας διεύθυνση και ένα κωδικό πρόσβασης (password). Προαιρετικά μπορείτε επίσης να δηλώσετε και τους λογαριασμούς σας στα μέσα κοινωνικής δικτύωσης (facebook, twitter, Instagram). Τα παραπάνω στοιχεία σας θα χρειαστούν για την επιβεβαίωση της ιδιότητάς σας ως Επαγγελματία του χώρου της Υγείας.
					</p>
					<p>
					    <strong>2. </strong>
					    <strong>ΛΟΓΑΡΙΑΣΜΟΣ ΕΠΑΓΓΕΛΜΑΤΙΑ ΥΓΕΙΑΣ</strong>
					</p>
					<p>
					    <strong>2.1 </strong>
					    Μετά την ολοκλήρωση της εγγραφής σας, θα λάβετε μήνυμα επιβεβαίωσης στην διεύθυνση ηλεκτρονικού ταχυδρομείου που έχετε δηλώσει με τα στοιχεία που έχετε δηλώσει. Το μήνυμα περιλαμβάνει σύνδεσμο ενεργοποίησης της εγγραφής σας.
					</p>
					<p>
					    <strong>2.2 </strong>
					    Παραμένετε προσωπικά και αποκλειστικά υπεύθυνος/η για όλες τις ενέργειες που πραγματοποιούνται μέσω του λογαριασμού σας. Οφείλετε να φροντίζετε την διασφάλιση του κωδικού πρόσβασης (password) και την τυπική έξοδό σας από τον Δικτυακό Τόπο (logout). Αν διαπιστωθεί οποιαδήποτε μη εξουσιοδοτημένη χρήση του λογαριασμού σας ή οποιαδήποτε γενόμενη ή πιθανή παραβίαση ασφαλείας, οφείλετε να ενημερώσετε άμεσα τους διαχειριστές του Δικτυακού Τόπου. Η Εταιρεία δεν ευθύνεται για οποιαδήποτε βλάβη ή ζημία προκύψει από την αδυναμία των χρηστών να σεβαστούν και να ακολουθήσουν την παρούσα ρήτρα. Η Εταιρεία διατηρεί το δικαίωμα να διαγράψει οποιονδήποτε λογαριασμό χρήστη, εφόσον αποδεικνύεται παραβίαση κάποιου από τους παρόντες Όρους.
					</p>
					<p>
					    <strong>2.3 </strong>
					    Εάν επιθυμείτε την απενεργοποίηση του λογαριασμού σας, μπορείτε να απευθύνεστε στην διεύθυνση contact@all4diabetes.gr
					</p>
					<p>
					    <strong> </strong>
					</p>
					<p>
					    <strong>3. </strong>
					    <strong>ΔΙΚΑΙΩΜΑΤΑ ΒΙΟΜΗΧΑΝΙΚΗΣ ΚΑΙ ΠΝΕΥΜΑΤΙΚΗΣ ΙΔΙΟΚΤΗΣΙΑΣ</strong>
					</p>
					<p>
					    <strong>3.1 </strong>
					    Ο Δικτυακός Τόπος αποτελεί πνευματική ιδιοκτησία της Εταιρείας σύμφωνα με το νόμο 2121/1993 "Περί Πνευματικής Ιδιοκτησίας", όπως αυτός έχει τροποποιηθεί και ισχύει σήμερα και τις διεθνείς συμβάσεις που έχει υπογράψει η Ελλάδα.
					</p>
					<p>
					    <strong>3.2 </strong>
					    Η Εταιρεία διατηρεί τα αποκλειστικά δικαιώματα πνευματικής ιδιοκτησίας στον σχεδιασμό, τον πηγαίο κώδικα, τα γραφικά, σχέδια, εικόνες και παρεχόμενες υπηρεσίες του Δικτυακού Τόπου στον βαθμό που τα δικαιώματα αυτά δεν ανήκουν ήδη σε τρίτα πρόσωπα. Τυχόν παραβίαση των δικαιωμάτων αυτών με οποιονδήποτε τρόπο επισύρει τις ευθύνες και ποινές που ορίζει ο νόμος. Ενδεικτικά και όχι περιοριστικά απαγορεύεται η αντιγραφή, δημοσίευση, αναδημοσίευση, διανομή, πώληση, εκμετάλλευση, παρέμβαση, αποσυμπίληση ή με οποιονδήποτε τρόπο μη εξουσιοδοτημένη χρήση του περιεχομένου και του λογισμικού του Δικτυακού Τόπου. 
					</p>
					<p>
					    <strong>3.3 </strong>
					    Τα άρθρα του Δικτυακού Τόπου αποτελούν πνευματική ιδιοκτησία τρίτων φυσικών ή/και νομικών προσώπων, τα οποία η Εταιρεία καθιστά προσβάσιμα στο κοινό μέσω υπερσυνδέσμων (hyperlinks) για λόγους ενημέρωσης των χρηστών (content aggregator). Η ευθύνη για το περιεχόμενό τους ανήκει εξολοκλήρου στους συντάκτες τους. Η αναπαραγωγή, αντιγραφή, πώληση, μεταπώληση ή/και εμπορική εκμετάλλευση με οποιονδήποτε τρόπο του συνόλου ή μέρους του περιεχομένου του Δικτυακού Τόπου απαγορεύονται απολύτως.
					</p>
					<p>
					    <strong>3.4 </strong>
					    Το σήμα all4Diabetes και τα υπόλοιπα εμπορικά σήματα που εμφανίζονται στον Δικτυακό Τόπο αποτελούν προστατευόμενα διακριτικά γνωρίσματα και κατατεθειμένα εμπορικά σήματα της Εταιρείας ή τρίτων οργανισμών, εταιρειών ή φορέων που είναι δικαιούχοι και προστατεύονται από τους ελληνικούς, κοινοτικούς και διεθνείς νόμους περί εμπορικών σημάτων και βιομηχανικής ιδιοκτησίας. Σε κάθε περίπτωση η εμφάνιση και έκθεσή τους στον Δικτυακό Τόπο δεν θα πρέπει κατά κανένα τρόπο να εκληφθεί ως παραχώρηση άδειας χρήσης τους. Τυχόν παραβίαση των δικαιωμάτων τους με οποιονδήποτε τρόπο επισύρει τις ευθύνες και ποινές που ορίζει ο νόμος. 
					</p>
					<p>
					    <strong>3.5 </strong>
					    Χωρίς να αναιρούνται ή περιορίζονται τα προηγούμενα, εάν πιστεύετε ότι κάποιο έργο σας αντιγράφεται ή καταχωρείται με τρόπο που να παραβιάζονται τα δικαιώματα της προσωπικότητας ή της πνευματικής/βιομηχανικής ιδιοκτησίας σας, παρακαλείστε να αποστείλετε γραπτή ειδοποίηση, στη διεύθυνση contact@all4diabetes.gr
					</p>
					<p>
					    <strong> </strong>
					</p>
					<p>
					    <strong>4. </strong>
					    <strong>ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ</strong>
					</p>
					<p>
					    Η επεξεργασία των προσωπικών δεδομένων όλων των χρηστών γίνεται σύμφωνα με
					    την <NavLink to="/privacy_policy">Πολιτική Προστασίας Προσωπικών Δεδομένων του Δικτυακού τόπου.</NavLink>
					</p>
					<p>
					    <strong> </strong>
					</p>
					<p>
					    <strong>5. </strong>
					    <strong>ΚΑΝΟΝΕΣ ΔΕΟΝΤΟΛΟΓΙΑΣ ΣΧΟΛΙΑΣΜΟΥ</strong>
					</p>
					<p>
					    <strong>5.1 </strong>
					    Ως Επαγγελματίας Υγείας που έχετε δημιουργήσει λογαριασμό στο Δικτυακό μας Τόπο, έχετε την δυνατότητα να σχολιάζετε τα άρθρα που αναρτώνται. Κατά την ανάρτηση σχολίων και την ανταλλαγή απόψεων με άλλους Επαγγελματίες Υγείας, οφείλετε να τηρείτε τους κανόνες δεοντολογίας του παρόντος άρθρου.
					</p>
					<p>
					    <strong>5.2 </strong>
					    Αποδέχεστε ότι, παρ’ όλο που στον Δικτυακό Τόπο παρέχεται η απαραίτητη τεχνολογική υποδομή και τα μέσα για δημοσίευση περιεχομένου, είστε αποκλειστικά υπεύθυνος/η για το κάθε είδους περιεχόμενο που αναρτάτε. Η Εταιρεία σε καμία περίπτωση δεν μπορεί να θεωρηθεί ότι αποδέχεται ή ενστερνίζεται καθ' οιονδήποτε τρόπο τις εκφραζόμενες στα σχόλια προσωπικές ιδέες ή αντιλήψεις των Επαγγελματιών Υγείας. Διατηρείτε στο ακέραιο την ευθύνη σχετικά με το περιεχόμενο που δημοσιοποιείτε καθώς και για τυχόν παραβίαση της ισχύουσας νομοθεσίας και οιονδήποτε δικαιωμάτων πνευματικής ιδιοκτησίας. Σε περίπτωση που δημοσιεύσετε περιεχόμενο ή απόψεις και σχόλια που ενδεχομένως έρχονται σε αντίθεση με τους παρόντες όρους, η Εταιρεία δεν θα φέρει καμία απολύτως ευθύνη για οποιασδήποτε φύσης ζημία τυχόν υποστεί τρίτος από αυτό.
					</p>
					<p>
					    <strong>5.3 </strong>
					    Συνιστούμε αυστηρά να εκφράζεστε με ακρίβεια και μετριοπάθεια. Προσβλητικές ή υβριστικές εκφράσεις μπορεί να προκαλέσουν την αφαίρεση ολόκληρου του σχολίου. Σε καμία περίπτωση δεν επιτρέπεται να θίγετε την τιμή, αξιοπρέπεια ή προσωπικότητα οποιουδήποτε φυσικού ή νομικού προσώπου. Επίσης δεν επιτρέπεται η σύνταξη αναρτήσεων σε greeklish (ελληνικά με λατινικούς χαρακτήρες) ή σε κεφαλαία γράμματα.
					</p>
					<p>
					    <strong>5.4 </strong>
					    Οι διαχειριστές του Δικτυακού Τόπου δεν έχουν νομική υποχρέωση να ελέγχουν τα σχόλια και τις τυχόν συζητήσεις, ούτε είναι εφικτός ο πλήρης και διαρκής έλεγχος. Παρ’ όλα αυτά διατηρούν το δικαίωμα να παρακολουθούν το υλικό κάθε μορφής επικοινωνίας μέσα στο Δικτυακό Τόπο και να αφαιρούν περιεχόμενο χωρίς καμία ειδοποίηση ή εξήγηση κατά την απόλυτη κρίση τους. Οποιαδήποτε αντίρρηση σχετικά με την αφαίρεση ανάρτησης πρέπει να απευθύνεται στους διαχειριστές μέσω προσωπικού μηνύματος και να μην διατυπώνεται στο χώρο του δημόσιου σχολιασμού.
					</p>
					<p>
					    <strong>5.5 </strong>
					    Σε περίπτωση που η Εταιρεία ειδοποιηθεί ότι το περιεχόμενο σε κάποιο σχόλιο που φιλοξενεί, θίγει τρίτα πρόσωπα ή παραβιάζει τα προσωπικά δεδομένα ή τα δικαιώματα πνευματικής ιδιοκτησίας τρίτων προσώπων, καθώς και για κάθε εν γένει αθέμιτη ή βλαβερή χρήση του Δικτυακού Τόπου, διατηρεί το δικαίωμα να προβεί στην άμεση και χωρίς προειδοποίηση διαγραφή του αθέμιτου ή προσβλητικού περιεχομένου.
					</p>
					<p>
					    <strong>5.6 </strong>
					    Ευθύνεστε απέναντι στην Εταιρεία και κάθε τρίτο, για οποιαδήποτε βλάβη ή ζημία προκύψει από την χρήση εκ μέρους σας των υπηρεσιών του Δικτυακού Τόπου με αθέμιτο τρόπο ή μη σύμφωνο με τους παρόντες όρους. Ενδεικτικά και όχι περιοριστικά, απαγορεύεται να χρησιμοποιείτε τις υπηρεσίες του Δικτυακού Τόπου για:
					</p>
					<p>
					    Α) Δημοσίευση, αποστολή, μεταφορά ή εγκατάσταση παράνομου, επιβλαβούς, πορνογραφικού, απειλητικού, προσβλητικού, επιζήμιου, δυσφημιστικού, χυδαίου, βίαιου, υβριστικού, ρατσιστικού ή άλλως αποδοκιμαστέου περιεχομένου ή περιεχομένου που προκαλεί γενική ανησυχία στο κοινό ή παραβιάζει την προσωπικότητα και τα προσωπικά δεδομένα άλλων ή προκαλεί συναισθήματα μίσους ή τρόμου, ή/και οποιοδήποτε άλλο συνιστάμενο ποινικό αδίκημα. 
					</p>
					<p>
					    Β) Μίμηση οποιουδήποτε νομικού ή φυσικού προσώπου ή ψευδή δήλωση για την ταυτότητά σας ή παραπλανητική δήλωση αναφορικά με τη σχέση σας με κάποιο άλλο νομικό ή φυσικό πρόσωπο. 
					</p>
					<p>
					    Γ) Μίμηση με παραχάραξη ή καθ' οιονδήποτε άλλο τρόπο αλλοίωση των στοιχείων του Δικτυακού Τόπου με σκοπό την παραπλάνηση ως προς την προέλευση του περιεχομένου που μεταδίδεται μέσω των υπηρεσιών του.
					</p>
					<p>
					    Δ) Δημοσίευση, αποστολή, μεταφορά ή εγκατάσταση περιεχομένου από άτομο που δεν έχει δικαίωμα να διαθέσει το συγκεκριμένο περιεχόμενο βάσει νόμου ή βάσει εμπιστευτικής σχέσης. 
					</p>
					<p>
					    Ε) Δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για την εγκατάσταση λογισμικού ή περιεχομένου κάθε μορφής (κειμένου, εικόνας, ήχου, video, animation) που παραβιάζει οποιαδήποτε δικαιώματα πνευματικής ιδιοκτησίας οποιουδήποτε τρίτου.
					</p>
					<p>
					   ΣΤ) Δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για την εγκατάσταση αυτόκλητης ή μη εξουσιοδοτημένης διαφήμισης ή άλλου περιεχομένου προώθησης προϊόντων, την αποστολή ανεπιθύμητων και μη προσκαλούμενων από τον λήπτη ηλεκτρονικών μηνυμάτων (spam) και οποιαδήποτε άλλη μορφή ανεπιθύμητης προώθησης περιεχομένου. 
					</p>
					<p>
					    Ζ) Ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για την εγκατάσταση, προώθηση ή διάθεση περιεχομένου που περιέχει ψηφιακούς ιούς ή οποιοδήποτε άλλο ηλεκτρονικό κώδικα, αρχείο ή πρόγραμμα σχεδιασμένο να παρεμβληθεί, καταστρέψει ή περιορίσει την λειτουργία οποιουδήποτε λογισμικού ή εξοπλισμού ηλεκτρονικών υπολογιστών ή τηλεπικοινωνιακού εξοπλισμού.
					</p>
					<p>
					    Η) Παρενόχληση με οποιονδήποτε τρόπο της ιδιωτικής ζωής και των ατομικών και κοινωνικών δικαιωμάτων άλλων χρηστών με οποιονδήποτε τρόπο. 
					</p>
					<p>
					    Στ) Παροχή εξειδικευμένων και προσωπικών ιατρικών συμβουλών ή συμβουλών που κατά το νόμο παρέχονται με συγκεκριμένο τρόπο βάσει της ιατρικής επιστήμης και δεοντολογίας.  
					</p>
					<p>
					    <strong>5.7 </strong>
					    Σε περίπτωση παραβίασης οποιουδήποτε από τους παραπάνω κανόνες, οι διαχειριστές του Δικτυακού Τόπου διατηρούν το δικαίωμα να απομακρύνουν το προσβλητικό περιεχόμενο, επιφυλασσόμενοι για οποιαδήποτε άλλη αξίωσή τους έναντι του χρήστη που παραβιάζει τους παρόντες Όρους Χρήσης.
					</p>
					<p>
					    <strong>6. </strong>
					    <strong>ΕΞΩΤΕΡΙΚΕΣ ΣΥΝΔΕΣΕΙΣ</strong>
					    <strong> (links)</strong>
					</p>
					<p>
					    <strong>6.1 </strong>
					    Ο Δικτυακός Τόπος περιλαμβάνει συνδέσμους ("links") προς άλλους δικτυακούς τόπους, οι οποίοι ελέγχονται από τρίτους φορείς. Σε καμία περίπτωση δεν ευθύνεται η Εταιρεία για το περιεχόμενό τους, ούτε για οποιαδήποτε οικονομική ή άλλης φύσεως βλάβη υποστεί ο χρήστης που τους ακολουθεί. Σε καμία περίπτωση η Εταιρεία μας δεν εγγυάται ότι οι σύνδεσμοι αυτοί θα λειτουργούν αδιάκοπα. H Εταιρεία διατηρεί το δικαίωμα να απομακρύνει ή και να προσθέσει συνδέσμους χωρίς οιαδήποτε προειδοποίηση προς οιονδήποτε και να αλλάξει το περιεχόμενο του Δικτυακού Τόπου οποιαδήποτε στιγμή.
					</p>
					<p>
					    <strong>6.2 </strong>
					    Προβαίνοντας σε χρήση του Δικτυακού Τόπου, αποδέχεστε ότι η Εταιρεία σε καμία περίπτωση δεν ευθύνεται  για το περιεχόμενο των υπερσυνδέσμων που περιλαμβάνει, τις υπηρεσίες που προσφέρουν ή τις διαφημίσεις που περιέχουν. 
					</p>
					<p>
					    <strong>7. </strong>
					    <strong>ΑΠΟΥΣΙΑ ΕΓΓΥΗΣΗΣ </strong>
					</p>
					<p>
					    <strong>7.1 </strong>
					    Το περιεχόμενο του Δικτυακού Τόπου παρέχεται «όπως ακριβώς είναι» χωρίς καμία εγγύηση ρητή ή τεκμαιρόμενη με οποιοδήποτε τρόπο, σχετικά με την εμπορευσιμότητα ή την καταλληλότητα του για συγκεκριμένο σκοπό.
					</p>
					<p>
					    <strong>7.2 </strong>
					    Δεν παρέχεται καμία ρητή εγγύηση, ότι οι σελίδες, οι υπηρεσίες, οι λειτουργίες, οι επιλογές και τα περιεχόμενά του Δικτυακού Τόπου θα παρέχονται χωρίς διακοπή, χωρίς σφάλματα και ότι τα λάθη θα επιδιορθώνονται. Επίσης μολονότι καταβάλλουμε κάθε δυνατή προσπάθεια, δεν παρέχεται καμία εγγύηση ότι ο Δικτυακός Τόπος δεν περιέχει "ιούς" ή άλλα επιζήμια συστατικά.
					</p>
					<p>
					    <strong>7.3 </strong>
					    Σε καμία περίπτωση η Εταιρεία και τα πρόσωπα που συνδέονται με αυτήν δεν είναι υπεύθυνοι απέναντί σας για οποιαδήποτε τυχόν ζημία προκληθεί σε εσάς από την χρήση του Δικτυακού Τόπου.
					</p>
					<p>
					    <strong>8. </strong>
					    <strong>ΠΕΡΙΟΡΙΣΜΟΣ ΕΥΘΥΝΗΣ</strong>
					</p>
					<p>
					    <strong>8.1 </strong>
					    Διασαφηνίζουμε ότι στον Δικτυακό Τόπο δεν παρέχονται ιατρικές συμβουλές. Εξαίρεση αποτελεί η υπηρεσία του Δικτυακού Τόπου «Ρωτήστε τον ειδικό», μέσω της οποίας οι χρήστες μπορούν να επικοινωνήσουν μαζί σας και να λάβουν άμεσα και αξιόπιστα απαντήσεις σε βασικές ιατρικές ερωτήσεις αποκλειστικά σε προσωπικό επίπεδο. Κατά την χρήση αυτή της υπηρεσίας, οι χρήστες ενδέχεται να κοινοποιήσουν σε εσάς ευαίσθητα προσωπικά τους δεδομένα. Σε κάθε περίπτωση οφείλετε να συμμορφώνεστε πλήρως με τους όρους της ιατρικής επιστήμης και δεοντολογίας καθώς και με την εφαρμοστέα νομοθεσία σχετικά με την προστασία των προσωπικών δεδομένων των χρηστών. 
					</p>
					<p>
					    <strong>8.2 </strong>
					    Η Εταιρεία δεν ευθύνεται και δεν δεσμεύεται για λάθη που οφείλονται σε λανθασμένη καταχώρηση των δεδομένων σας στον Δικτυακό Τόπο.
					</p>
					<p>
					    <strong>8.3 </strong>
					    Δεδομένης της φύσης και του όγκου των πληροφοριών του Διαδικτύου, υπό οποιεσδήποτε συνθήκες και από οποιαδήποτε αιτία, συμπεριλαμβανομένης και της περίπτωσης αμέλειας, η Εταιρεία δεν ευθύνεται για οποιασδήποτε μορφής ζημία, προκύψει από την χρήση, διαθεσιμότητα ή μη διαθεσιμότητα των υπηρεσιών και περιεχομένων του Δικτυακού Τόπο, στην χρήση των οποίων προβαίνετε με αποκλειστικά δική σας πρωτοβουλία και ευθύνη. 
					</p>
					<p>
					    <strong>9. </strong>
					    <strong>ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ ΚΑΙ ΛΟΙΠΟΙ ΟΡΟΙ</strong>
					</p>
					<p>
					    Οι ανωτέρω όροι και προϋποθέσεις χρήσης του Δικτυακού Τόπου, καθώς και οποιαδήποτε τροποποίηση τους, διέπονται και συμπληρώνονται από το ελληνικό δίκαιο, το δίκαιο της Ευρωπαϊκής Ένωσης και τις σχετικές διεθνείς συνθήκες, που έχει υπογράψει η Ελλάδα. Όλοι οι ανωτέρω όροι συνομολογούνται ως ουσιώδεις. Οποιαδήποτε διάταξη των ανωτέρω όρων καταστεί αντίθετη προς το νόμο, παύει αυτοδικαίως να ισχύει και αφαιρείται από το παρόν, χωρίς σε καμία περίπτωση να θίγεται η ισχύς των λοιπών όρων.
					</p>
					<p>
					    Εάν δεν συμφωνείτε με τους παρόντες Όρους Χρήσης, οφείλετε να μην χρησιμοποιείτε τις υπηρεσίες του Δικτυακού Τόπου.  
					</p>
					<p align="right">
					    Copyright &copy; { new Date().getFullYear()}, GET2WORK
					</p>
				</div>
			</div>
		);
		
	}
}

export default TermsHcpsPage;