/*import $ from 'jquery';*/
/*
let axiosConfig = {
  headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
  }
};*/

/*const user = {
      name: "George"
    };*/
/*axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/all_articles', { user })
      .then(res => {
        console.log(res);
        console.log(res.data);
      })*/
/*axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/articles', { user }, axiosConfig)
      .then(res => {
        allArticles = res.data;
        console.log(res);
        console.log(allArticles);
      }).catch(err => console.log('Login: ', err));*/
/*$.ajax(window.serverURL+'/all4diabetes/all4diabetes_website/test_ajax.php')
        .then(
            function success(response) {
                console.log(response);
            },

            function fail(data, status) {
                console.log(data);
                console.log(status);
            }
        )*/
/*fetch(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/all_articles', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    user 
  }),
});*/
export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);      
};

export function getIndex(value, arr, prop) {
    for(var i = 0; i < arr.length; i++) {
        if(arr[i][prop] === value) {
            return i;
        }
    }
    return -1; 
}