import React, {Component} from 'react';
import axios from 'axios';
import '../../Assets/css/hcpPage.min.css';
import defaultAvatar from '../../Assets/img/catalog_avatar.jpg';
import { Formik, /*FormikProps,*/ Form, Field/*, ErrorMessage*/  } from 'formik';
import * as Yup from 'yup';
//import TextareaAutosize from 'react-textarea-autosize';
import CommentsAndRatingsList from './hcpProfileComponents/commentsAndRatingsList';
import { connect } from "react-redux";
import {Helmet} from "react-helmet";

let formikInitialValues={
	about:"",
	address:"",
	city:"",
	comment:"",
	county:"",
	area:"",
	email:"",
	facebook:"",
	instagram:"",
	name:"",
	password:"",
	passwordConfirm:"",
	phone:"",
	postcode:"",
	primarySpecialty:0,	
	surname:"",
	terms:false,
	title:0,
	twitter:""
}

const SignupSchema = Yup.object().shape({	
	email: Yup.string()
		.email('Invalid email')
		.required('Required')
	/*.test('passwords-match', 'Passwords must match ya fool', function(value) {
	  return this.parent.password === value;
	})*/
	
});

class ConnectedHcpProfile extends Component {

	constructor() {
		super();

		this.state = {
			hcpObj: {},			
			authenticatedHcp: false,
			isEditingForm: false,
			isUpdatingForm:false,
			imageLoaded:'',
			imageToUpload:'',
			imageUploaded:false,
			imageUploadError:false,
			imagePreviewUrl:false,
			mimeType: '',
			fileSize : 0
			
		}
		this.getHcp = this.getHcp.bind(this);
		this.handleOnActivateForm = this.handleOnActivateForm.bind(this);
		this.handleOnResetForm = this.handleOnResetForm.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.imageUpload = this.imageUpload.bind(this);
		this.onChangeImage = this.onChangeImage.bind(this);
		/*this.getHcpComments = this.getHcpComments.bind(this);
		this.getHcpRatings = this.getHcpRatings.bind(this);*/
		//this.getHcpCommentsAndRatings = this.getHcpCommentsAndRatings.bind(this);
	}

	componentDidMount() {	
		window.scrollTo(0,0);
		this.getHcp();
		if (this.props.currentUser.data.id === this.props.match.params.id && this.props.currentUser.isLoggedIn === true) {
			this.setState({
				authenticatedHcp:true
			})
		}
		/*this.getHcpComments();
		this.getHcpRatings();	*/
		
	}



	componentDidUpdate(prevProps, prevState) {
		
		if(prevProps.currentUser !== this.props.currentUser){			
			if (this.props.currentUser.data.id === this.props.match.params.id && this.props.currentUser.isLoggedIn === true) {
				this.setState({
					authenticatedHcp:true
				})
			} else {
				this.setState({
					authenticatedHcp:false
				})
			}
		} 
		
	}


	getHcp() {
		let hcp = [];
    	let hcpId = {
    		id: this.props.match.params.id
    	}
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/hcpProfile', hcpId)
	      .then(res => {
	      	
	      	
	        hcp = res.data.data;	        
	        hcp[0].image = (hcp[0].image) ? window.serverURL+"/serve_images/"+hcp[0].image : defaultAvatar;
	        
	        formikInitialValues = hcp[0];	        
	        
	        this.setState({
	        	imageLoaded: hcp[0].image, 
	        	hcpObj : hcp[0],
	        	authenticatedHcp: (this.props.currentUser.isLoggedIn && this.props.currentUser.data.id === hcp[0].id)
	        })

	   
	               
			
	    }).catch(err => console.log('Login: ', err));
		
	}

	handleOnActivateForm(e) {
		this.setState({
			isEditingForm:true,
		})
	}

	handleOnResetForm(e) {
		this.setState({
			isEditingForm:false,
			imagePreviewUrl: false,
			imageLoaded : this.state.imageLoaded,
			imageToUpload : ''
		})
	}


	handleSubmit(values, { 
      props = this.props, 
      setSubmitting 
    }) {
    	var self = this;    	
    	self.setState({
    		isUpdatingForm:true
    	})
    	setSubmitting(true);
		let formData = new FormData();

		for (var key in values) {
			formData.append(key, values[key]);
		}

		formData.set('id',this.props.currentUser.data.id);
		formData.set('about',values["about"]);
		formData.set('full_name',values["full_name"]);
		formData.set('specialty_primary',values["specialty_primary"]);
		formData.set('phone',values["phone"]);
		formData.set('address',values["address"]);
		formData.set('postcode',values["postcode"]);
		formData.set('email',values["email"]);
		formData.set('facebook',(values["facebook"])? values["facebook"] : '');
		formData.set('twitter',(values["twitter"])? values["twitter"] : '');
		formData.set('instagram',(values["instagram"])? values["instagram"] : '');
		formData.set('linkedin',(values["linkedin"])? values["linkedin"] : '');
		formData.set('website',(values["website"])? values["website"] : '');
		
		//formData.set('secondarySpecialty',specialties.find(obj => obj.value == values.secondarySpecialty).label);

		this.saveFormDataToDB(formData,setSubmitting);
	}

	saveFormDataToDB(formData,setSubmitting) {
		axios
			.post(window.serverURL + "/all4diabetes/admin/public/admin/api/ajax_requests/saveFormDataToDB", formData)
			.then(response => {				
				return response;
			})
			.then(json => {				
				if (json.data.status === "success") {
					this.setState(
						{							
							isUpdatingForm:false,
							isEditingForm:false,
							imagePreviewUrl:false
						}
					);
				} else {
					this.setState(
						{							
							isUpdatingForm:false,
							imagePreviewUrl:false
						}
					);
				}
				setSubmitting(false)
			})
			.catch(error => {
				this.setState(
					{						
						isUpdatingForm:false,
						imagePreviewUrl:false
					}
				)
				setSubmitting(false)
			});
		this.imageUpload();
	}

	onChangeImage(e) {
		let files = e.target.files || e.dataTransfer.files;
		if (!files.length) return;
		var mimeType=files[0].type.toLowerCase();		
		var fileSize = files[0].size;
		if (mimeType !== "image/png" && mimeType !== "image/jpeg" && mimeType !== "image/jpg"){
			e.target.value = null;
			alert('Παρακαλούμε χρησιμοποιείστε κατάλληλο τύπο εικόνας (jpg ή png).');
			return;
		} 

		if (fileSize > (4*1024*1024) ) {
			e.target.value = null;
			alert('Το μέγεθος της εικόνας ξεπερνά τα 4ΜΒ! Χρησιμοποιείστε άλλη εικόνα.');
			return;
		}
		this.createImage(files[0]);
	}
	createImage(file) {
		let reader = new FileReader();
		reader.onload = (e) => {
			this.setState({
				imageToUpload: e.target.result,
				imagePreviewUrl:true,
				fileSize: file.size,
				mimeType : file.type
			})
		};
		reader.readAsDataURL(file);
	}

	imageUpload = async () =>{		
		if (this.state.imageToUpload === "") {
			return;
		}
		const values = {
			data: this.state.imageToUpload,
			id: this.props.currentUser.data.id,
			mimeType : this.state.mimeType,
			fileSize : this.state.fileSize
		}
		var self = this;
		await axios.post(window.serverURL + "/all4diabetes/admin/public/admin/api/ajax_requests/updateImageOfHcp",  values).then((res) => {
			if (res.data.status === "success") {
				self.setState( { 
					imageUploaded : true,
					imageLoaded: window.serverURL+"/serve_images/"+res.data.data,
					imagePreviewUrl:false,
					imageToUpload: ''
				});
			//window.location.replace("/dashboard");
			} else {
				alert('Υπάρχει κάποιο πρόβλημα στην αλλαγή της εικόνας σας. Παρακαλούμε προσπαθείστε αργότερα.');
				self.setState( { imageUploadError : true});
			}
			}).catch((e)=>{
				console.log(e);
				/*window.sessionStorage.clear();
				window.location.replace('/'); */
		});
	}

	/*getHcpComments() {
		let hcpComments = [];
    	let hcpId = {
    		hcp_id: this.props.match.params.id
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/hcpComments', hcpId)
	      .then(res => {
	      	
	        hcpComments = res.data.data;
	        console.log(hcpComments);
	        this.setState({
	        	hcpComments : hcpComments
	        })
	               
			
	    }).catch(err => console.log('Login: ', err));
	}

	getHcpRatings() {
		let hcpRatings = [];
    	let hcpId = {
    		hcp_id: this.props.match.params.id
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/hcpRatings', hcpId)
	      .then(res => {
	      	
	        hcpRatings = res.data.data;

	        this.setState({
	        	hcpRatings : hcpRatings
	        })
	        
			
	    }).catch(err => console.log('Login: ', err));
	}*/

	
	
	render() {		
		
		return (
			<div className="container-fluid hcpPage">
				<Helmet
					title={this.state.hcpObj.specialty_primary + " | " + this.state.hcpObj.full_name + " | All4Diabetes"}
					meta={[
					
					]}
				/>
				<h3 className="hcp-title">{this.state.hcpObj.full_name}</h3>

				<div className="container hcpInfo">					
					<Formik
					enableReinitialize 
					initialValues={formikInitialValues}
			        validationSchema={SignupSchema}
			        onSubmit={this.handleSubmit}
			        >
			        {({errors, isSubmitting, values, isValid }) => (
			        	<Form className="form-horizontal" encType="multipart/form-data">
			        		<div className="row">
								<div className="col-md-6 col-12">
									<div className="profileImageContainer container">
										{
											this.state.authenticatedHcp && this.state.isEditingForm ? 
											<div className="form-group row">
												<label className="label_imagem_artigo"> Αλλάξτε την εικόνα σας: </label>
												<input className="input_imagem_artigo" type="file" onChange={this.onChangeImage} />
											</div>
											:
											''
										}
										
										{ this.state.imagePreviewUrl ? 
											<img className="img-fluid m-auto profileImage" name="add_imagem" alt={this.state.hcpObj.specialty_primary + " | " + this.state.hcpObj.full_name + " | All4Diabetes"} src={this.state.imageToUpload} />
											: <img src={this.state.imageLoaded} alt= {this.state.hcpObj.specialty_primary + " | " + this.state.hcpObj.full_name + " | All4Diabetes"} className="img-fluid m-auto profileImage"/>
										}
										<div className="about-title">Σχετικά με εμένα...</div>
										<div className="form-group">										
											<Field aria-label="Σχετικά" type="text" component="textarea" value={values.about ? values.about : ''} name="about" className='form-control'  disabled={!this.state.isEditingForm} /> 
										</div>									
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="profileInfoContainer container">
										{
											this.state.authenticatedHcp && !this.state.isEditingForm ?
											<div className="text-right">
												<button type="button" onClick={this.handleOnActivateForm} className="btn btn-primary">Ενεργοποίηση αλλαγής στοιχείων</button> 
											</div>
											:
											''
										}										
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="full_name">Ονοματεπώνυμο</label>
											<div className="col-md-6 col-sm-12">
												<Field aria-label="Ονοματεπώνυμο" required type="text" component="textarea" value={values.full_name ? values.full_name : ''}  name="full_name" className='form-control'  disabled={!this.state.isEditingForm}/> 
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="specialty_primary">Ειδικότητα</label>
											<div className="col-md-6 col-sm-12">
												<Field aria-label="Ειδικότητα" required type="text" component="textarea" value={values.specialty_primary ? values.specialty_primary : ''} name="specialty_primary" className='form-control'  disabled={!this.state.isEditingForm}/> 
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="phone">Τηλέφωνο επικοινωνίας</label>
											<div className="col-md-6 col-sm-12">
												{
													this.state.authenticatedHcp && this.state.isEditingForm ?													
													<Field aria-label="Τηλέφωνο επικοινωνίας" required type="text" component="textarea" value={values.phone ? values.phone : ''} name="phone" className='form-control'  disabled={!this.state.isEditingForm}/> 
													:
													<a href={"tel:"+ values.phone } rel="noopener noreferrer nofollow" title="Τηλέφωνο επικοινωνίας">{values.phone ? values.phone : ''}</a>
												}
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="address">Διεύθυνση</label>
											<div className="col-md-6 col-sm-12">
												<Field aria-label="Διεύθυνση" required type="text" component="textarea" value={values.address ? values.address : ''} name="address" className='form-control'  disabled={!this.state.isEditingForm}/> 
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="postcode">Τ.Κ.</label>
											<div className="col-md-6 col-sm-12">
												<Field aria-label="Ταχυδρομικός κώδικας" required type="text" name="postcode" className='form-control'  disabled={!this.state.isEditingForm}/> 
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="email">E-mail</label>
											<div className="col-md-6 col-sm-12">												
												{
													this.state.authenticatedHcp && this.state.isEditingForm ?
													<Field aria-label="Ηλεκτρονικό ταχυδρομείο" required type="text" component="textarea" value={values.email ? values.email : ''} name="email" className='form-control'  disabled={!this.state.isEditingForm}/> 
													:
													values.email ? 
														<a href={"mailto:"+ values.email } rel="noopener noreferrer nofollow" title="Ηλεκτρονικό ταχυδρομείο">{values.email}</a>
														: ''
												}
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="facebook">Facebook</label>
											<div className="col-md-6 col-sm-12">
												{
													this.state.authenticatedHcp && this.state.isEditingForm ?
													<Field aria-label="facebook" type="text" name="facebook" component="textarea" value={values.facebook ? values.facebook : ''} className='form-control' disabled={!this.state.isEditingForm}/> 
													:
													values.facebook ? 
														<a href={values.facebook} target="_blank" rel="noopener noreferrer nofollow" title="facebook">{values.facebook}</a> 
														: ''
													
												}
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="twitter">Twitter</label>
											<div className="col-md-6 col-sm-12">
												{
													this.state.authenticatedHcp && this.state.isEditingForm ?
													<Field aria-label="Twitter" type="text" name="twitter" component="textarea" value={values.twitter ? values.twitter : ''} className='form-control' disabled={!this.state.isEditingForm}/> 
													:
													values.twitter ? 
														<a href={values.twitter } target="_blank" rel="noopener noreferrer nofollow" title="Twitter">{values.twitter}</a>
														: 
														''
												}
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="linkedin">Linkedin</label>
											<div className="col-md-6 col-sm-12">
												{
													this.state.authenticatedHcp && this.state.isEditingForm ?
													<Field aria-label="Linkedin" type="text" name="linkedin" component="textarea" value={values.linkedin ? values.linkedin : ''} className='form-control' disabled={!this.state.isEditingForm}/> 
													:
													values.linkedin ? 													
														<a href={values.linkedin } target="_blank" rel="noopener noreferrer nofollow" title="Linkedin">{values.linkedin}</a>
														:
														''
												}
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="instagram">Instagram</label>
											<div className="col-md-6 col-sm-12">
												{
													this.state.authenticatedHcp && this.state.isEditingForm ?
													<Field aria-label="instagram" type="text" name="instagram" component="textarea" value={values.instagram ? values.instagram : ''} className='form-control' disabled={!this.state.isEditingForm}/> 
													:
													values.instagram ? 
														<a href={values.instagram} target="_blank" rel="noopener noreferrer nofollow" title="instagram">{values.instagram}</a> 
														: ''
													
												}
											</div>
										</div>
										<div className="form-group row">
											<label className="control-label col-md-6 col-sm-12" htmlFor="website">Website</label>
											<div className="col-md-6 col-sm-12">
												{
													this.state.authenticatedHcp && this.state.isEditingForm ?
													<Field aria-label="Ιστοσελίδα" type="text" name="website" component="textarea" value={values.website ? values.website : ''} className='form-control' disabled={!this.state.isEditingForm}/> 
													:
													values.website ? 
														<a href={values.website} target="_blank" rel="noopener noreferrer nofollow" title="Ιστοσελίδα">{values.website}</a> 
														: 
														''
													
												}
											</div>
										</div>										
										{
											this.state.authenticatedHcp && this.state.isEditingForm ?

											<div className="text-right">
												<button type="button" onClick={this.handleOnResetForm} className="btn btn-danger">Ακύρωση</button>
												<button type="submit" disabled={isSubmitting} className="btn btn-primary">Αποθήκευση αλλαγών</button>
												
											</div>
											:
											''
										}
										
									</div>
								</div>
							</div>
						</Form>
						)}
					</Formik>						
					
				</div>

				<div className="container-fluid commentsAndRatings">
					<h4>Σχόλια / Αξιολογήσεις</h4>

					<CommentsAndRatingsList hcp_id = {this.props.match.params.id}/>
				</div>
				
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return({
		currentUser: state.user
	});
};

const HcpProfile = connect(mapStateToProps)(ConnectedHcpProfile);

export default HcpProfile;

