import React, {Component, Fragment } from 'react';
import HomeBanner from './homepageComponents/homeBanner';
import ActiveHcps from './homepageComponents/activeHcps';
import News from './homepageComponents/news';
import ProposedArticles from './homepageComponents/proposedArticles';
import IndicatedArticles from './homepageComponents/indicatedArticles';
import PopularArticles from './homepageComponents/popularArticles';
//import PopularMedicalArticles from './homepageComponents/popularMedicalArticles';
import ArticleColumns from './homepageComponents/articleColumns';
import Categories from './homepageComponents/categories';
import parallaxImage from '../../Assets/img/parallax_image.png';
import { Parallax } from 'react-parallax';
import { connect } from "react-redux";
import axios from 'axios';
import Advertisement from '../basicComponents/advertisement';
import {Helmet} from "react-helmet";

/*const insideStyles = {
  color:'white',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};*/

/*const image1 =
  "https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D";*/


class ConnectedHomePage extends Component {

	constructor() {
		super();
		this.state = {
			recentArticles: [],
			indicatedArticles: [],
			proposedArticles: [],
			popularArticles: [],
			categoriesArticles: [],
			advertisements:[],
			announcements:[]
		};
		
		this.getRandom = this.getRandom.bind(this);
		this.getRecentArticles = this.getRecentArticles.bind(this);
		this.getProposedArticles = this.getProposedArticles.bind(this);
		this.getIndicatedArticles = this.getIndicatedArticles.bind(this);
		this.getCategories = this.getCategories.bind(this);
		this.getAdvertisements = this.getAdvertisements.bind(this);
		this.getAnnouncements = this.getAnnouncements.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0,0);		
		this.getRecentArticles();
		this.getProposedArticles();
		this.getPopularArticles();
		this.getIndicatedArticles();
		this.getCategories();
		this.getAdvertisements();
		this.getAnnouncements();
	}

	componentDidUpdate(prevProps){
		if(prevProps.userData !== this.props.userData){ 
	    	this.changeHomeView();
		}
		
		window.$('body').tooltip({
    		selector: '[data-toggle="tooltip"]'
    	});
	}

	getRandom(arr, n) {
		var result = new Array(n),
	        len = arr.length,
	        taken = new Array(len);
	    if (n > len)
	        throw new RangeError("getRandom: more elements taken than available");
	    while (n--) {
	        var x = Math.floor(Math.random() * len);	  
	        if (arr[x in taken ? taken[x] : x].images.includes("H2NSf36uPt5ATalWY9zhZKcoScbMqWz3Czd7KVOE") ){n++;continue;}      
	        result[n] = arr[x in taken ? taken[x] : x];

	        taken[x] = --len in taken ? taken[len] : len;
	    }
	    //console.log(result);
	    return result;
	}

	getRecentArticles() {
		
    	let recentArticles = [];
    	let parameters = {
    		homePageComponect: 'recent'
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/homePageArticles', parameters)
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        recentArticles = res.data.data;
	        //console.log(recentArticles);
	        this.setState({
	        	recentArticles : recentArticles,
	        	articlesLoaded : true,
	        });		        
	      }).catch(err => 
	      	console.log('Login: ', err)
	      	);
    
	}

	getProposedArticles() {
		
    	let proposedArticles = [];
    	let parameters = {
    		homePageComponect: 'proposed',
    		comments:1,
    		rating:1
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/homePageArticles', parameters)
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        proposedArticles = res.data.data;
	        //console.log(proposedArticles);
	        this.setState({
	        	proposedArticles : proposedArticles,
	        	articlesLoaded : true,
	        });		        
	      }).catch(err => 
	      	console.log('Login: ', err)
	      );
    
	}

	getIndicatedArticles() {
		
    	let indicatedArticles = [];
    	let parameters = {
    		homePageComponect: 'indicated',
    		rating:1
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/homePageArticles', parameters)
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        indicatedArticles = res.data.data;
	        //console.log(indicatedArticles);
	        this.setState({
	        	indicatedArticles : indicatedArticles,
	        	articlesLoaded : true,
	        });		        
	      }).catch(err => 
	      	console.log('Login: ', err)
	      );
    
	}

	getAdvertisements() {
		let advertisements = [];
		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/advertisements', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        advertisements = res.data.data;
	        
	        this.setState({
	        	advertisements : advertisements
	        });		        
	    }).catch(err => console.log('Login: ', err));
	}

	getAnnouncements() {
		let announcements = [];
		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/announcements', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        announcements = res.data.data;
	        
	        this.setState({
	        	announcements : announcements
	        });		        
	    }).catch(err => console.log('Login: ', err));
	}

	getPopularArticles() {
		
    	let popularArticles = [];
    	let parameters = {
    		homePageComponect: 'popular',
    		comments:1,
    		rating:1
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/homePageArticles', parameters)
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        popularArticles = res.data.data;
	        //console.log(popularArticles);
	        this.setState({
	        	popularArticles : popularArticles,
	        	articlesLoaded : true,
	        });		        
	      }).catch(err => console.log('Login: ', err));
    
	}

	getCategories() {
		let categoriesArticles = [];
    	let parameters = {
    		homePageComponect: 'categories'
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/homePageArticles', parameters)
	      .then(res => {      	
	      	
	        categoriesArticles = res.data.data;
	        var categoriesArticlesSorted = categoriesArticles.sort((a, b) => (a.main_category < b.main_category) ? -1 : 1);        
	        this.setState({
	        	categoriesArticles : categoriesArticlesSorted,
	        	articlesLoaded : true,
	        });		        
	      }).catch(err => console.log('Login: ', err));

		/*var result = [];
		var taken = [];
		var n=0;
		var i=0;
		
		while (n < this.props.tags.length && this.props.articles[i]) {
			
			if (taken.includes(this.props.articles[i].catid) || this.props.articles[i].images.includes("H2NSf36uPt5ATalWY9zhZKcoScbMqWz3Czd7KVOE")) {
				i++;
				continue;
			}			

			result[n] = this.props.articles[i];
			n++;
			taken.push(this.props.articles[i].catid);
			i++;			
		}
		
		return result;*/
		
	}

	changeHomeView(){
		
	}

	render() {
		
		let homeBanner = <HomeBanner 
						articles = { this.props.articlesLoaded ? this.state.recentArticles : []}
						tags = { this.props.tagsLoaded ? this.props.tags : []}
						parentClassName = "homeBanner"
					/>;
		let type1Ads = this.state.advertisements.filter(entry => Object.values(entry).some(val => val===1));
		let randomType1Ad = type1Ads[Math.floor(Math.random()*type1Ads.length)];
		let advertisement1 = <Advertisement adObj = {randomType1Ad}/>;

		let type2Ads = this.state.advertisements.filter(entry => Object.values(entry).some(val => val===2));
		let randomType2Ad = type2Ads[Math.floor(Math.random()*type2Ads.length)];		

		let type3Ads = this.state.advertisements.filter(entry => Object.values(entry).some(val => val===3));
		let randomType3Ad = type3Ads[Math.floor(Math.random()*type3Ads.length)];		
		
		let activeHcps = <ActiveHcps />;
		let indicatedArticles = <IndicatedArticles 
					articles = { this.state.indicatedArticles ? this.state.indicatedArticles : []}
					tags = { this.props.tagsLoaded ? this.props.tags : []}
					parentClassName = "indicated-articles"
					isLoggedIn = {this.props.userData.isLoggedIn}
					adObj = {randomType2Ad}
				/>;
		let proposedArticles = <ProposedArticles 
					articles = { this.props.articlesLoaded ? this.state.proposedArticles : []}
					tags = { this.props.tagsLoaded ? this.props.tags : []}
					parentClassName = "proposed-articles"
					isLoggedIn = {this.props.userData.isLoggedIn}
					adObj = {randomType2Ad}
				/>;
		/*let news = <News 
					articles = { this.props.articlesLoaded ? this.state.recentArticles : []}
					/>;*/
		let news = <News 
					announcements = { this.state.announcements ? this.state.announcements : []}
					/>;
		let popularArticles = <PopularArticles 
					articles = { this.props.articlesLoaded ? this.state.popularArticles : []}
					tags = { this.props.tagsLoaded ? this.props.tags : []}
					parentClassName = "popular-articles"
				/>;
		/*let popularMedicalArticles = <PopularMedicalArticles 
					articles = { this.props.medicalArticlesLoaded ? this.props.medicalArticles : []}
					tags = { this.props.tagsLoaded ? this.props.tags : []}
					parentClassName = "popular-medical-articles"
				/>;*/

		let articleColumns = <ArticleColumns 
					medicalArticles = { this.props.medicalArticlesLoaded ? this.props.medicalArticles : []}
					articles = { this.props.articlesLoaded ? this.state.popularArticles : []}
					tags = { this.props.tagsLoaded ? this.props.tags : []}
					parentClassName = "popular-medical-articles popular-articles"
				/>;
			

		let renderOrder;

		if (this.props.userData.isLoggedIn) {
			renderOrder = [advertisement1,news,articleColumns,activeHcps,indicatedArticles,proposedArticles];
		}else{
			renderOrder = [homeBanner,activeHcps,indicatedArticles,(this.state.announcements.length>0)?news:null,proposedArticles,popularArticles];
		}

		return (
			<div className={this.props.userData.isLoggedIn ? 'reorganized' : 'default'}>	
				<Helmet
						title={"Τα Πάντα Γύρω από τον Διαβήτη | All4Diabetes"}
						//<meta name="description" content="Κοινό, αρθρογραφία & ειδικοί συνδέονται με μοναδικό τρόπο στη νέα πύλη για το διαβήτη. Κάνε κλικ στο All4Diabetes!">
						meta={[
						
						]}
					/>
				
					{renderOrder.map((v, i) => {
						return <Fragment key={i}>{v}</Fragment>
					})}
				
				
				<Parallax bgImage={parallaxImage} strength={400} bgStyle={{backgroundAttachment:'fixed'}}>
			      <div style={{ height: 'auto' }} className="d-flex align-items-center">
			        <div className="container ">
			        	<h2>Όλα και όλοι γύρω από το διαβήτη</h2>
			        	<p>
			        		Βάσει ερευνών, άνω του 60% των Ελλήνων επισκεπτών του διαδικτύου χρησιμοποιούν το διαδίκτυο για ενημέρωση σε θέματα υγείας. Μόλις όμως το 6% πιστεύει ότι όσα διαβάζει, είναι αξιόπιστα. Από την άλλη πλευρά, το 98% των Ελλήνων ασθενών θεωρούν τους ιατρούς την πιο έμπιστη πηγή πληροφόρησης, αλλά μόνο το 7% των ιατρών δραστηριοποιείται στην οnline ενημέρωση των πολιτών για θέματα της ειδικότητας τους. Αυτό το χάσμα έρχεται να γεφυρώσει η πλατφόρμα all4Diabetes, φέρνοντας κοντά όλα και όλους γύρω από τη διαχείριση της νόσου του σακχαρώδη διαβήτη.
			        	</p>
			        	<p>
			        		Η πλατφόρμα σε καθημερινή βάση εντοπίζει και κατηγοριοποιεί αρθρογραφία από δημοφιλείς ή εξιδεικευμένες ιστοσελίδες περιεχομένου υγείας, δίνοντας στους επαγγελματίες υγείας τη δυνατότητα αξιολόγησης και σχολιασμού της. Το κοινό αποκτά πρόσβαση, με βάση διαφορετικές θεματικές κατηγορίες, σε πρόσφατα, δημοφιλή και αξιόλογα κατά τους ειδικούς άρθρα.  Έτσι, κοινό, ειδικοί και αρθρογραφία γύρω από την νόσο συνδέονται μοναδικά.
			        	</p>
			        	<a data-toggle="modal" data-target="#loginModal" className="" href="/">Εάν είστε Επαγγελματίας Υγείας κάντε την εγγραφή σας</a>
			        	
			        </div>
			      </div>
			    </Parallax>
				
				
				<Categories 
					articles = { this.props.articlesLoaded ? this.state.categoriesArticles : []}
					tags = { this.props.tagsLoaded ? this.props.tags : []}
					parentClassName = "categories"
					isLoggedIn = {this.props.userData.isLoggedIn}
					adObj = {randomType3Ad}
				/>
				
			</div>
		);
	}
}

const mapStateToProps = state => {
  return {
  	userData: {
  		isLoggedIn : state.user.isLoggedIn
  	}
  };
};

const HomePage = connect(mapStateToProps)(ConnectedHomePage);

export default HomePage;

