import React, {Component} from 'react';
import '../../Assets/css/articlepage.min.css';
import Tag from '../basicComponents/tag';
import Rating from '../basicComponents/rating';
import AskTheExpertModal from '../basicComponents/askTheExpertModal';
import axios from 'axios';
import Iframe from '../helper/Iframe.js';
//import { TrackerProvider, Tracker } from 'react-tracker';
import { withTracking } from 'react-tracker';
import getTrackArticlePageView from '../../tracking/events';
//import $ from 'jquery';
import CommentsList from './articleComponents/commentList';
import AskTheExpertButton from './articleComponents/askTheExpertButton';

import { connect } from "react-redux";
import { renderToStaticMarkup } from 'react-dom/server';
import {Helmet} from "react-helmet";

//const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const decodeHtml = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

const stripHtml = (html) => {
	var doc = new DOMParser().parseFromString(html, 'text/html');
   	return doc.body.textContent || "";
}

class ConnectedArticlePage extends Component {

	constructor() {
		super();

		this.state = {
			articleObj:{},
			allTags:[],
			tags:[],
			date:'',
			comments:[],
			rating:0,
			clientInfo:{type:"article"},
			articleComments: [],
			articleRatings: [],
			commentCount: 0,
			commentNames: [],
			urlHasChanged: false,
			expertHcp:{}
		}
		
		this.styleFullText = this.styleFullText.bind(this);
		this.getAllTags = this.getAllTags.bind(this);
		this.getArticleById = this.getArticleById.bind(this);
		this.buildStateFromDB = this.buildStateFromDB.bind(this);
		this.getArticleComments = this.getArticleComments.bind(this);
		this.getCommentCount = this.getCommentCount.bind(this);

		this.updateRating = this.updateRating.bind(this);
		this.calculateRating = this.calculateRating.bind(this);
		this.getArticleRatings = this.getArticleRatings.bind(this);
		this.checkIfQueryStringExists = this.checkIfQueryStringExists.bind(this);
		
	}

	componentDidMount() {	

		window.scrollTo(0,0);
		/*if(this.props.location.state) {	
			this.setState( {
				articleObj:this.props.location.state.articleInfo,
				tags:this.props.location.state.tags,
				date:this.props.location.state.dateFormatted
			}  );
			//console.log(this.props.location.state);
		} else {		
			this.getAllTags();
		}	*/
		this.getAllTags();
		this.props.trackArticlePageView('article',this.props.match.params.id);

		window.$('body').tooltip({
    		selector: '[data-toggle="tooltip"]'
    	});


	}


	componentDidUpdate(prevProps, prevState) {
		window.$('body').tooltip({
    		selector: '[data-toggle="tooltip"]'
    	});
	}

	componentWillUnmount() {
		document.title = "All4Diabetes";
	}

	styleFullText() {
		const firstLetterTextStyles = {			
			color: this.state.tags[0].color,
			fontSize:'5em',
			lineHeight: '0.7em',
			float:'left',
			padding:'0.1em'
		};

		//I had to do this because of unknown document format from Tiny text Editor from the admin
		var htmlStrippedText = stripHtml(this.state.articleObj.full_text);
		var firstLetter = htmlStrippedText.charAt(0);
		var decodedHtml = decodeHtml(this.state.articleObj.full_text);
		var indexOfFirstLetter = decodedHtml.indexOf(firstLetter);
		var restText = decodedHtml.substring(indexOfFirstLetter+1);
		return (<p> <span style={firstLetterTextStyles}>{firstLetter}</span>{restText}</p>);
	}

	getArticleById(stateData) {		
		let article = [];
    	let articleId = {
    		id: this.props.match.params.id
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/articles', articleId)
	      .then(res => {
	      	
	      	
	        article = res.data.data;
	        //console.log(article);
	        stateData = {
	        	...stateData,
	        	articleObj : article[0],
	        };
	        
	        this.getArticleComments(stateData);
			
	    }).catch(err => 
	    	console.log('Login: ', err)
	    	);
	}

	getAllTags() {
    	let allTags = [];
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/tags', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allTags = res.data.data;
	        //console.log(allTags);
	         
	        this.getArticleById({
	        	allTags : allTags,
	        });

	        //this.buildStateFromDB();
	      }).catch(err => 
	      	console.log('Login: ', err)
	      );
	}

	buildStateFromDB(stateData) {
		var self = stateData;

		if (self.hasOwnProperty('articleObj') && self.hasOwnProperty('allTags')) {
			/*self.articleObj = this.state.articleObj;
			self.allTags = this.state.allTags;*/
			
			self.tags = [];
			self.tagNames = (self.articleObj.tags)? self.articleObj.tags.split(',') : [];
			
			/* INSTEAD OF SHOWING ALL TAGS, WE SHOW ONLY THE MAIN CATEGORY (requested)*/
			/*for (var i=0; i < self.tagNames.length; i++) {
				let tag = self.tagNames[i];
		        const tagColor = self.allTags.find(function(element) {
		        	return element.short_name === tag;
				}).color;
				const tagCatId = self.allTags.find(function(element) {
		        	return element.short_name === tag;
				}).category_id;
				const tagObj = {
					'name':tag,
					'color':tagColor,
					'catId':tagCatId

				}
				self.tags.push(tagObj);
		    }*/

		    let tag = self.articleObj.main_category;
			const tagFound = self.allTags.find(function(element) {
				return element.title === tag;
			});
			const tagColor = tagFound !== undefined ? tagFound.color : "#000000";
			const tagCatId = tagFound !== undefined ? tagFound.category_id : "";
			const tagObj = {
				name: tag,
				color: tagColor,
				catId: tagCatId
			};
			self.tags.push(tagObj);

		    if (self.tags.length <= 0) {
		    	const tagObj = {
					'name':'Διάφορα',
					'color':'#dddddd',
					'catId':36

				}
				self.tags.push(tagObj);
		    }

		    //console.log(new Date(Date.parse(self.articleObj.created_at)));
		    //console.log(new Date(Date.parse(self.created_at.toString().replace(' ','T'))));
		    var dateOptions = { /*weekday: 'long',*/ year: 'numeric', month: 'short', day: 'numeric' };
			self.date = new Date(self.articleObj.created_at.replace(' ','T')).toLocaleDateString('el-EL', dateOptions);
		}	    
	    
		this.setState({
			...this.state,
			...self
		});

		if (this.checkIfQueryStringExists('asktheexpert')) {
			window.$('#askTheExpertModal').modal('show');
		}
		
	}

	getArticleExpertHcp(stateData = {}) {
		let hcp = {};

		let hcpData = {
			id: stateData.articleObj.expert_hcp_id
		};

    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/hcpProfile', hcpData)
		.then(res => {
			/*console.log(window.serverURL);*/
			hcp = res.data.data[0];	        
	        

			stateData = {
	        	...stateData,
	        	expertHcp:hcp
	        };
	        

	        this.buildStateFromDB(stateData);
		}).catch(err => console.log('Login: ', err));
	}

	getArticleComments(stateData = {}){
		let articleComments = [];

		let articleData = {
			article_id: stateData.hasOwnProperty('articleObj') ? stateData.articleObj.id : this.state.articleObj.id, 
			article_type: 'article'
		};

		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/comments', articleData)
		.then(res => {
			
			/*console.log(window.serverURL);*/
			articleComments = res.data.data;
			//console.log(articleComments);

			let formatedArticleComments = [];

			var dateOptions = { /*weekday: 'long',*/ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};

			articleComments.forEach(function(item, index) {			  	
				let created_at = new Date(item.created_at.replace(' ','T')).toLocaleDateString('el-EL', dateOptions);
				let updated_at = new Date(item.updated_at.replace(' ','T')).toLocaleDateString('el-EL', dateOptions);
				//var n = created_at.getTimezoneOffset();
				
			  	let comment = {
					userData: {
						id: item.user_id,
						full_name: item.full_name,
						social: {
							facebook: item.facebook,
							twitter: item.twitter,
							instagram: item.instagram,
							linkedin: item.linkedin
						},
						image: (item.image) ? item.image : null
					},
					commentData: {
						id: item.id,
						parentCommentID: item.parent_id,
						articleId: item.article_id,
						articleType: item.article_type,
						text: item.text,
						created_at: created_at,
						updated_at: updated_at,
						is_deleted: item.is_deleted
					},
					replies: []
				}

				formatedArticleComments.push(comment)
			});

			let sortedArticleComments = this.buildArticleCommentsArray(formatedArticleComments);

			stateData = {
	        	...stateData,
	        	articleComments : sortedArticleComments,
				commentCount:formatedArticleComments.length,
				commentNames: [...new Set(formatedArticleComments.map(comment => comment.userData.full_name))]
	        };
	        
	        this.getArticleRatings(stateData);

		}).catch(err => console.log('Login: ', err));
	}

	buildArticleCommentsArray(articleComments){
		let newArticleComments = articleComments.map(comment => {
			return {
				...comment,
				unique_id: '_' + Math.random().toString(36).substr(2, 9)
			}
		});
		var i;
		for ( i = newArticleComments.length - 1; i >= 0; i--) {
			if (newArticleComments[i].commentData.parentCommentID !== 0) {
				let parentIndex = newArticleComments.findIndex(comment => comment.commentData.id === newArticleComments[i].commentData.parentCommentID);
				if (parentIndex >= 0) {
					newArticleComments[parentIndex].replies.push(newArticleComments.splice(i,1)[0]);
				}
			}
		}

		for ( i = 0; i < newArticleComments.length; i++) {
			newArticleComments[i].replies.sort(function(a,b) {
				return Date.parse(a.commentData.created_at) - Date.parse(b.commentData.created_at);
			});
		}

		return newArticleComments;
	}

	getArticleRatings(stateData = {}){
		let articleRatings = [];

		let articleData = {
			article_id: stateData.hasOwnProperty('articleObj') ? stateData.articleObj.id : this.state.articleObj.id, 
			article_type: 'article'
		};

    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/ratings', articleData)
		.then(res => {
			/*console.log(window.serverURL);*/
			articleRatings = res.data.data;
			//console.log(articleRatings);

			for (let i = articleRatings.length - 1; i > 0; i--) {
		        const j = Math.floor(Math.random() * (i + 1));
		        [articleRatings[i], articleRatings[j]] = [articleRatings[j], articleRatings[i]];
		    }

			let newArticleRatings = this.calculateRating(articleRatings);

			stateData = {
	        	...stateData,
	        	...newArticleRatings
	        };
	        
	        if (stateData.articleObj.expert_hcp_id) {
				this.getArticleExpertHcp(stateData);
				
			} else {
	        	this.buildStateFromDB(stateData);
			}

		}).catch(err => console.log('Login: ', err));
	}

	calculateRating(articleRatings,shouldSetState = true){
		let articleRating = articleRatings.reduce((sum, { rating }) => sum + parseInt(rating), 0) / articleRatings.length;

		return {
			rating: isNaN(articleRating) ? 0 : articleRating,
			articleRatings: articleRatings
		};
	}

	updateRating(userRating){
		let ratingData={
			article_id: this.state.articleObj.id,
			article_type: 'article',
			user_id: this.props.currentUserId,
			rating: userRating
		}

		let headers = {
    		'Authorization': 'Bearer '+this.props.currentUserToken
    	}

		axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/addRating', ratingData, {headers: headers})
		.then(res => {			
			//console.log(res.data);
			let newArticleRatingArray;
			let updatedRatingIndex = this.state.articleRatings.findIndex(rating => rating.user_id === res.data.data.user_id && rating.article_id === res.data.data.article_id);

			if (updatedRatingIndex > -1) {
				newArticleRatingArray = [
					...this.state.articleRatings
				];

				newArticleRatingArray[updatedRatingIndex] = {
					...newArticleRatingArray[updatedRatingIndex],
					rating: res.data.data.rating
				};
			} else {
				newArticleRatingArray = [
					...this.state.articleRatings
				];

				newArticleRatingArray[0] = {
					...newArticleRatingArray[0],
					rating: res.data.data.rating
				};
			}
			
			let newArticleRatings = this.calculateRating(newArticleRatingArray);

			this.setState({
				...this.state,
				...newArticleRatings
			});

		}).catch(err => 
			console.log('Login: ', err)
		);
	}

	getCommentCount(newCount, newNames){
		this.setState({
			commentCount: newCount,
			commentNames: newNames
		});
	}

	changeTitleAndUrlOfCurrectPage() {		
		// debugger;
		if (this.state.urlHasChanged || this.state.articleObj.title === undefined || this.state.articleObj.id === undefined) {
			return;
		}
		this.setState({urlHasChanged:true});
		document.title = this.state.articleObj.title;		
		let newUrl = /*this.state.articleObj.id+"/"+*/this.string_to_slug(document.title);
		this.ChangeUrl(document.title,newUrl);	
	}

	string_to_slug(str) {

		str  = str.replace(/^\s+|\s+$/g, '') // TRIM WHITESPACE AT BOTH ENDS.
		          .toLowerCase();            // CONVERT TO LOWERCASE

		var fromArray = [ "ου", "ΟΥ", "Ού", "ού", "αυ", "ΑΥ", "Αύ", "αύ", "ευ", "ΕΥ", "Εύ", "εύ", "α", "Α", "ά", "Ά", "β", "Β", "γ", "Γ", "δ", "Δ", "ε", "Ε", "έ", "Έ", "ζ", "Ζ", "η", "Η", "ή", "Ή", "θ", "Θ", "ι", "Ι", "ί", "Ί", "ϊ", "ΐ", "Ϊ", "κ", "Κ", "λ", "Λ", "μ", "Μ", "ν", "Ν", "ξ", "Ξ", "ο", "Ο", "ό", "Ό", "π", "Π", "ρ", "Ρ", "σ", "Σ", "ς", "τ", "Τ", "υ", "Υ", "ύ", "Ύ", "ϋ", "ΰ", "Ϋ", "φ", "Φ", "χ", "Χ", "ψ", "Ψ", "ω", "Ω", "ώ", "Ώ" ];
		var toArray   = [ "ou", "ou", "ou", "ou", "au", "au", "au", "au", "eu", "eu", "eu", "eu", "a", "a", "a", "a", "b", "b", "g", "g", "d", "d", "e", "e", "e", "e", "z", "z", "i", "i", "i", "i", "th", "th", "i", "i", "i", "i", "i", "i", "i", "k", "k", "l", "l", "m", "m", "n", "n", "ks", "ks", "o", "o", "o", "o", "p", "p", "r", "r", "s", "s", "s", "t", "t", "y", "y", "y", "y", "y", "y", "y", "f", "f", "x", "x", "ps", "ps", "o", "o", "o", "o" ];

		for ( var i = 0; i < fromArray.length; i++ ) {

		    while( str.indexOf( fromArray[i]) !== -1 ){

		        str = str.replace( fromArray[i], toArray[i] );    // CONVERT GREEK CHARACTERS TO LATIN LETTERS

		    }

		}

		str = str.replace(/[^a-z0-9 -]/g, '') // REMOVE INVALID CHARS
		         .replace(/\s+/g, '-')        // COLLAPSE WHITESPACE AND REPLACE BY DASH - 
		         .replace(/-+/g, '-');        // COLLAPSE DASHES

		return str;

	}

	ChangeUrl(title, url) {		
	    if (typeof (window.history.replaceState) != "undefined") {
	        var obj = { Title: title, Url: url };
	        window.history.replaceState(obj, obj.Title, obj.Url);
	    } else {
	        alert("Browser does not support HTML5.");
	    }
	}

	checkIfQueryStringExists(query) {
		var field = query;
		var url = this.props.location.search;		
		if(url.indexOf('?' + field + '=') !== -1)
		    return true;
		else if(url.indexOf('&' + field + '=') !== -1)
		    return true;
		return false	
	}
	
	
	render() {
		if (this.state.tags.length>0) {
			
			this.changeTitleAndUrlOfCurrectPage();
			const h2Styles = {
				textAlign:'center',
				padding:'1em 3em',
				width:'100%',
				backgroundColor:this.state.tags[0].color+"55"
			}

			const readArticleStyles = {
				padding:'0.7em 1.5em',
				backgroundColor:this.state.tags[0].color,
				margin:'0 auto',
				color:'white',
				borderRadius:'1em'
			}
			const fullText = [this.styleFullText()]
			return (
				
				<div className="container-fluid articlePage">
					<Helmet
						title={this.state.articleObj.title}
						meta={[
						{ name: 'description', content: this.state.articleObj.title },
						{ name: 'author', content: "all4diabetes" },

						{ name: 'twitter:site', content: "all4diabetes" },
						{ name: 'twitter:creator', content: "all4diabetes" },
						{ name: 'twitter:title', content: this.state.articleObj.title },
						{ name: 'twitter:image', content: window.serverURL+"/serve_images/"+this.state.articleObj.images },

						{ property: 'og:title', content: this.state.articleObj.title },
						{ property: 'og:site_name', content: "all4diabetes" },
						{ property: 'og:type', content: "website" },
						{ property: 'og:url', content: "https://all4diabetes.gr/article/"+this.state.articleObj.id },
						{ property: 'og:description', content: this.state.articleObj.title },
						{ property: 'og:image', content: window.serverURL+"/serve_images/"+this.state.articleObj.images },
						{ property: 'og:site_name', content: "all4diabetes" },

						{ name: 'viewport', content: 'width=device-width, maximum-scale=1' },
						{ name: 'apple-itunes-app', content: 'app-id=1125423676' },
						]}
					/>
					<h3 className="article-title" style={h2Styles}>{this.state.articleObj.title}</h3>				
					
					
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-12">
								<img src={window.serverURL+"/serve_images/"+this.state.articleObj.images} className="img-fluid" style={{minWidth: '100%'}} alt={this.state.articleObj.title}/>
							</div>
							<div className="col-md-6 col-12 d-flex align-items-end">
								<div className="col-md-8">
									<div className="d-block url">
										<i className="fa fa-globe"></i><a href={this.state.articleObj.urls} target="_blank" rel="noopener noreferrer nofollow">{this.state.articleObj.source_name}</a>	
									</div>
									<div className="banner-tag d-block">
					                	{this.state.tags.map((tag, index) => {
						                    return (
						                        <Tag key={index} tagColor={tag.color} tagName={tag.name} catId={tag.catId}/>
						                    )
						                })}                    
					                </div>
					                <div className="d-block">
					                	<div><i className="fa fa-clock-o"></i>{this.state.date}</div>
					                	<div>
											<span href="#" 
												className="comment-tooltip" 
												data-toggle="tooltip" 
												data-placement="top" 
												data-html="true" 
												data-container="body" 
												data-original-title={this.state.commentNames.join('<br>')}><i className="fa fa-commenting" aria-hidden="true"></i>{this.state.commentCount + ' Comments'}
											</span>
										</div>
					                	<div>
					                		<Rating rating={this.state.rating} updateRating={this.updateRating} ratingNames={this.state.articleRatings.map(rating => rating.full_name)}/>
					                	</div>
					                </div>
				                </div>
				                <div className="col-md-4 d-flex justify-content-center">
				                	{(this.state.expertHcp.id) ? <AskTheExpertButton />: ''}
				                </div>
							</div>
						</div>
					</div>					
					<div className="container full-text" dangerouslySetInnerHTML={{ __html: decodeHtml(fullText.map(renderToStaticMarkup).join("")) }}>
						
					</div>
					<div className="container" style={{textAlign:'center'}}>
						{/*href={this.state.articleObj.urls} */}
						<a href="/" data-toggle="modal" data-target="#iframeModal"  style={readArticleStyles} target="_blank" rel="noopener noreferrer">Διαβάστε το άρθρο</a>
					</div>
					<div className="container" id="commentsSection" style={{marginTop:'30px'}}>
						<div className="section-title">
							<h2>Σχόλια</h2>
						</div>
						<CommentsList depth={0} maxDepth={1} comments={this.state.articleComments} articleId={this.state.articleObj.id} articleType='article' returnCommentCount={this.getCommentCount}/>
					</div>
					{/*<iframe src="http://google.com" style={{width: "100%",height: "100%"}}/>*/}
					<Iframe source={this.state.articleObj.urls} ratingsCallback={this.updateRating} ratingsData={{rating: this.state.rating, ratingNames: this.state.articleRatings.map(rating => rating.full_name)}} isUserLoggedIn={this.props.isUserLoggedIn}/>
					{(this.state.expertHcp.id) ? <AskTheExpertModal articleId={this.state.articleObj.id} hcpInfo={this.state.expertHcp} />: ''} 
				</div>
				
			);
		} else {
			return (
				<div className="container-fluid articlePage">
					<h2 >{this.state.articleObj.title}</h2>
					<div className="container">
						
					</div>
					
					
				</div>
			);	
		}
		
	}
}

const mapTrackingToProps = trackEvent => {
	return {
		trackArticlePageView: (type, articleId) => {
			trackEvent(getTrackArticlePageView(type, articleId))
		}
	}
}

const mapStateToProps = state => {
	return {
		currentUserId: state.user.data.id,
		currentUserToken: state.user.token,
		isUserLoggedIn: state.user.isLoggedIn
	};
};

const TrackedArticlePage = withTracking(mapTrackingToProps)(ConnectedArticlePage);

const ArticlePage = connect(mapStateToProps)(TrackedArticlePage);

export default ArticlePage;

