import React from "react";
import { Link } from "react-router-dom";
import Tag from "./tag";
import Rating from "../basicComponents/rating";

function Article(props) {
	var self = {};
	self.articleObj = props.articleObj;
	self.allTags = props.tags;
	self.tags = [];
	
	self.tagNames = self.articleObj.tags ? self.articleObj.tags.split(",") : [];
	self.commentNames = self.articleObj.comment_names ? self.articleObj.comment_names.split(",") : [];
	
	/* INSTEAD OF SHOWING ALL TAGS, WE SHOW ONLY THE MAIN CATEGORY (requested)*/
	/*for (var i = 0; i < self.tagNames.length; i++) {
		let tag = self.tagNames[i];
		const tagFound = self.allTags.find(function(element) {
			return element.short_name === tag;
		});
		const tagColor = tagFound !== undefined ? tagFound.color : "#000000";
		const tagCatId = tagFound !== undefined ? tagFound.category_id : "";
		const tagObj = {
			name: tag,
			color: tagColor,
			catId: tagCatId
		};
		self.tags.push(tagObj);
	}*/

	let tag = self.articleObj.main_category;
	const tagFound = self.allTags.find(function(element) {
		return element.title === tag;
	});
	const tagColor = tagFound !== undefined ? tagFound.color : "#000000";
	const tagCatId = tagFound !== undefined ? tagFound.category_id : "";
	const tagObj = {
		name: tag,
		color: tagColor,
		catId: tagCatId
	};
	self.tags.push(tagObj);

	if (self.tags.length <= 0) {
		const fallbackTag = self.allTags.find(function(element) {
			return element.short_name === "Διάφορα";
		});

		const tagObj = {
			name: fallbackTag.short_name,
			color: fallbackTag.color,
			catId: fallbackTag.category_id
		};

		self.tags.push(tagObj);
	}

	//console.log(new Date(Date.parse(self.articleObj.created_at)));
	//console.log(new Date(Date.parse(self.created_at.toString().replace(' ','T'))));
	var dateOptions = { /*weekday: 'long',*/ year: "numeric", month: "short", day: "numeric" };
	self.date = new Date(self.articleObj.created_at.replace(" ", "T")).toLocaleDateString("el-EL", dateOptions);

	self.divStyle = {
		//serve_images is configured from apache. Put it in front of imgUrl
		background: "url(" + window.serverURL + "/serve_images/" + self.articleObj.images + ") no-repeat center center",
		backgroundSize: "cover"
	};

	self.divStyle2 = {
		//serve_images is configured from apache. Put it in front of imgUrl
		background: "url(" + window.serverURL + "/serve_images/" + self.articleObj.images + ") no-repeat center center",
		backgroundSize: "cover",
		height: "100%"
	};

	if (props.parentClassName === "homeBanner") {
		return (
			<div className="swiper-slide article" style={self.divStyle}>
				<div className="home_banner_text">
					<div className="banner-tag">
						{self.tags.map((tag, index) => {
							return <Tag key={index} tagColor={tag.color} tagName={tag.name} catId={tag.catId} />;
						})}
					</div>
					<h2>
						<Link to={{ pathname: "/article/" + self.articleObj.id + "/" + encodeURIComponent(self.articleObj.title), state: { articleInfo: self.articleObj, tags: self.tags, dateFormatted: self.date } }}>{self.articleObj.title} </Link>
					</h2>
					<p>
						<i className="fa fa-globe" />
						<a href={self.articleObj.urls} target="_blank" rel="noopener noreferrer nofollow">
							{self.articleObj.source_name}
						</a>{" "}
						<i className="fa fa-clock-o" />
						{self.date}
					</p>
				</div>
			</div>
		);
	} else if (props.parentClassName === "indicated-articles") {
		return (
			<div className={props.class + " article"} style={self.divStyle}>
				<div className="home_banner_text">
					<div className="image-overlay" />
					<div style={{ position: "relative" }}>
						<h3>
							<Link to={{ pathname: "/article/" + self.articleObj.id + "/" + encodeURIComponent(self.articleObj.title), state: { articleInfo: self.articleObj, tags: self.tags, dateFormatted: self.date } }}>{self.articleObj.title} </Link>
						</h3>
						<div className="banner-tag">
							{self.tags.map((tag, index) => {
								if (index < 3) {
									return <Tag key={index} tagColor={tag.color} tagName={tag.name} catId={tag.catId} />;
								}
								return null;
							})}
						</div>
						<p>
							<i className="fa fa-globe" />
							<a href={self.articleObj.urls} target="_blank" rel="noopener noreferrer nofollow">
								{self.articleObj.source_name}
							</a>{" "}
							<i className="fa fa-clock-o" />
							{self.date}
						</p>
						{self.articleObj.article_rating > 0 && self.articleObj.hcps_ratings ? <Rating rating={self.articleObj.article_rating} cannotUpdate={true} ratingNames={self.articleObj.hcps_ratings.split(",")} /> : ""}
					</div>
				</div>
			</div>
		);
	} else if (props.parentClassName === "proposed-articles" || props.parentClassName === "popular-articles" || props.parentClassName === "popular-medical-articles popular-articles") {
		
		return (
			<div className={props.class + " article"}>
				<div className="row">
					<div className="col-12 col-md-5">
						<Link aria-label={self.articleObj.title} to={{ pathname: "/article/" + self.articleObj.id + "/" + encodeURIComponent(self.articleObj.title), state: { articleInfo: self.articleObj, tags: self.tags, dateFormatted: self.date } }}>
							<div style={self.divStyle2}>
								<div className="d-md-none">
									<img src={window.serverURL + "/serve_images/" + self.articleObj.images} className="img-fluid" width="100%" alt={self.articleObj.title} />
								</div>
							</div>
						</Link>
					</div>
					<div className="col-12 col-md-7">
						<Link to={{ pathname: "/article/" + self.articleObj.id + "/" + encodeURIComponent(self.articleObj.title), state: { articleInfo: self.articleObj, tags: self.tags, dateFormatted: self.date } }}>{self.articleObj.title} </Link>
						<p style={{ marginBottom: 0 }}>
							<i className="fa fa-globe" />
							<a href={self.articleObj.urls} target="_blank" rel="noopener noreferrer nofollow">
								{self.articleObj.source_name}
							</a>
							<i className="fa fa-clock-o" />
							{self.date}
							<span href="#" className="comment-tooltip d-inline-block ml-3" data-toggle="tooltip" data-placement="top" data-html="true" data-container="body" data-original-title={self.commentNames.join("<br>")}>
								<i className="fa fa-commenting" aria-hidden="true" />
								{self.articleObj.comments_count + " Comments"}
							</span>
						</p>
						<div className="rating-container" style={{ fontSize: "0.7em" }}>
							{self.articleObj.article_rating > 0 && self.articleObj.hcps_ratings ? <Rating rating={self.articleObj.article_rating} cannotUpdate={true} ratingNames={self.articleObj.hcps_ratings.split(",")} /> : <Rating rating={0} cannotUpdate={true} ratingNames={[]} />}
						</div>
						<div className="banner-tag">
							{self.tags.map((tag, index) => {
								return <Tag key={index} tagColor={tag.color} tagName={tag.name} catId={tag.catId} />;
							})}
						</div>
					</div>
				</div>
			</div>
		);
	} else if (props.parentClassName === "categories") {
		return (
			<div className="swiper-slide">
				<div className={props.class + " article"} style={self.divStyle}>
					<div className="home_banner_text">
						<div className="image-overlay" />
						<div style={{ position: "relative" }}>
							<div className="banner-tag">
								{self.tags.map((tag, index) => {
									return <Tag key={index} tagColor={tag.color} tagName={tag.name} catId={tag.catId} />;
								})}
							</div>
							<p>
								<Link to={{ pathname: "/article/" + self.articleObj.id + "/" + encodeURIComponent(self.articleObj.title), state: { articleInfo: self.articleObj, tags: self.tags, dateFormatted: self.date } }}>{self.articleObj.title} </Link>
							</p>
							<p>
								<i className="fa fa-globe" />
								<a href={self.articleObj.urls} target="_blank" rel="noopener noreferrer nofollow">
									{self.articleObj.source_name}
								</a>{" "}
								<i className="fa fa-clock-o" />
								{self.date}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	} else if (props.parentClassName === "category-page") {
		return (
			<div className={props.class + " article"} style={self.divStyle}>
				<Link to={{ pathname: "/article/" + self.articleObj.id + "/" + encodeURIComponent(self.articleObj.title), state: { articleInfo: self.articleObj, tags: self.tags, dateFormatted: self.date } }}>
					<div className="category-page-wrapper">
						<div className="home_banner_text">
							<div className="banner-tag">
								{self.tags.map((tag, index) => {
									return <Tag key={index} tagColor={tag.color} tagName={tag.name} catId={tag.catId} />;
								})}
							</div>
							<h2>
								<Link to={{ pathname: "/article/" + self.articleObj.id + "/" + encodeURIComponent(self.articleObj.title), state: { articleInfo: self.articleObj, tags: self.tags, dateFormatted: self.date } }}>{self.articleObj.title} </Link>
							</h2>
							<p>
								<i className="fa fa-globe" />
								<a href={self.articleObj.urls} target="_blank" rel="noopener noreferrer nofollow">
									{self.articleObj.source_name}
								</a>{" "}
								<i className="fa fa-clock-o" />
								{self.date}
							</p>
							<div className="rating-container" style={{ fontSize: "0.7em" }}>
								{self.articleObj.article_rating > 0 && self.articleObj.hcps_ratings ? <Rating rating={self.articleObj.article_rating} cannotUpdate={true} ratingNames={self.articleObj.hcps_ratings.split(",")} /> : <Rating rating={0} cannotUpdate={true} ratingNames={[]} />}
							</div>
							<div className="comment-container">
								<span href="#" className="comment-tooltip d-inline-block" data-toggle="tooltip" data-placement="top" data-html="true" data-container="body" data-original-title={self.commentNames.join("<br>")}>
									<i className="fa fa-commenting" aria-hidden="true" />
									{self.articleObj.comments_count + " Comments"}
								</span>
							</div>
						</div>
					</div>
				</Link>
			</div>
		);
	}
}

export default Article;
