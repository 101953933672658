import React, { Component } from 'react';
import Article from '../../basicComponents/article';


class Popular extends Component {
	
	render() {
		if (this.props.articles.length > 0) {
			return (
				
				<section className="popular-articles" id="popular-articles-section">
					<div className="container">
						
						<div className="section-title">
							<h2>Δημοφιλή άρθρα</h2>
						</div>
						<div className="row">	
							<div className="col-sm-6 col-12">
								{this.props.articles.map((tag, index) => {
									if (index < 3) {
										return <Article {...this.props} articleObj={this.props.articles[index]} class="item-small"/>
									}
									
								})}								
						    </div>	
						    <div className="col-sm-6 col-12">
								{this.props.articles.map((tag, index) => {
									if (index >= 3 && index < 6) {
										return <Article {...this.props} articleObj={this.props.articles[index]} class="item-small"/>
									}									
								})}
						    </div>					
							
						</div>
						
					</div>
				</section>
				
			);
		} else {
			return (
				<section className="popular-articles">
					
				</section>				
			);
		}
	}
}

export default Popular;