import React, { Component } from 'react';

class News extends Component {
	constructor() {
		super();
		this.state = {
			animationName: '',
			animationDuration:'',
			startAnimating:false
		};
		this.onWindowResize = this.onWindowResize.bind(this);
		
	}

	componentDidUpdate() {
		if (!this.state.startAnimating) {
			this.onWindowResize();
		}
	}

	onWindowResize() {
		var section = document.getElementById('newscontent');
		if (section === undefined || section === null) {return};	
		var marquee = section.getElementsByClassName('marquee')[0];
		if (marquee === undefined) {return};		
		var marqueeContent = document.getElementById('marqueeElementsContainerForNews');
		if (marqueeContent === undefined || marqueeContent === null ) {
			return;
		}
		var marqueeContents = marqueeContent.getElementsByTagName('span');
		if (!this.state.startAnimating) {
			this.setState({
				startAnimating: true
			})	
		} 
		let marqueeContentWidth = 0;

		for (var i =0; i<marqueeContents.length; i++) {
			marqueeContentWidth+= marqueeContents[i].offsetWidth;
		}
		
		let styleSheet = document.styleSheets[0];
 
	    let animationName = 'marquee-news-content';

	    let animationSpeed = 100;

	    let animationDuration = parseInt( (marqueeContentWidth+marquee.offsetWidth) / (animationSpeed ) ) + 's';
	    
	    var toMove = -(marqueeContentWidth+marquee.offsetWidth);
	    //console.log(toMove);
	    let keyframes =
	    `@keyframes ${animationName} {
	        0% {transform:translate3d(0px, 0px,0px)} 
	        100% {transform:translate3d(${toMove}px, 0px,0px)}
	    }`;	 	
	    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
	    keyframes =
	    `@-webkit-keyframes ${animationName} {
	        0% {-webkit-transform:translate3d(0px, 0px,0px)} 
	        100% {-webkit-transform:translate3d(${toMove}px, 0px,0px)}
	    }`;	 	
	    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
	    

		this.setState({
			animationName: animationName,
			animationDuration: animationDuration
		});
		
    }

	componentDidMount() {
		//console.log(this.props);
		window.addEventListener("resize", this.onWindowResize);
		this.onWindowResize();
	}

	render() {
		let style = {
			animationName: this.state.animationName,
			animationTimingFunction: 'linear',
			animationDuration: this.state.animationDuration,
			animationDelay: '0.0s',
			animationIterationCount: 'infinite',
			animationDirection: 'normal',
			animationFillMode: 'forwards'
		};
		
		return (
			<section id="newscontent" className="news-content">
				<div className="container">
					<div className="d-flex flex-row col p-0">
						<div className="d-flex marquee-title">Νέα</div>
						<div className='marquee w-100'>
							{	(this.props.announcements.length > 0) ?
								<div id="marqueeElementsContainerForNews" style={style}>
									{this.props.announcements.map((announcements, index) => {
					                    return (
					                        <span key={index}><a href={this.props.announcements[index].link} target="_blank" rel="noopener noreferrer nofollow">{this.props.announcements[index].title}</a></span>
					                    )
					                })} 
									
									
								</div>
								:
								''
							}
							<div className="marquee-finish"></div>
						</div>
					</div>
				</div>
			</section>
			
		);
	}
}

export default News;