import { LOGIN_USER } from "./action-types";
import { LOGOUT_USER } from "./action-types";
import { LOAD_ARTICLE_COMMENTS } from "./action-types";
import { LOAD_ARTICLE_RATINGS } from "./action-types";
import { ERROR_MESSAGE } from "./action-types";

export function loginUser(payload) {
  return { type: LOGIN_USER, payload }
};

export function logoutUser() {
  return { type: LOGOUT_USER }
};

export function loadArticleComments(payload) {
  return { type: LOAD_ARTICLE_COMMENTS, payload }
};

export function loadArticleRatings(payload) {
  return { type: LOAD_ARTICLE_RATINGS, payload }
};

export function captureRequestError(payload) {
  return { type: ERROR_MESSAGE, payload }
};