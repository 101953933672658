import React, {Component } from 'react';
import { connect } from "react-redux";

import Tag from '../../components/basicComponents/tag';
import '../../Assets/css/appMobile.min.css';

class ConnectedHomePage extends Component {

	constructor() {
		super();
		this.state = {
			recentArticles: [],
			proposedArticles: [],
			popularArticles: [],
			categoriesArticles: [],
			advertisements:[]
		};
		
	}

	componentDidMount() {
		window.scrollTo(0,0);
	}

	componentDidUpdate(prevProps){
		
	}

	render() {
		return (
			<section id="categoriesAppMobile">					
				<div className="container" style={{height: '100%',width:'100%'}}>
					<div className="row">
						<div className="col-12">
							<div className="section-title align-self-start">
								<h2>Επίλεξε κατηγορία</h2>
							</div>
							<ul>
								{this.props.tags.map((tag, index) => {								
				                    return (
				                    	<li>
				                        	<Tag key={tag.category_id} tagColor={tag.color} tagName={tag.title} catId={tag.category_id}/>
				                        </li>
				                    )
				                })} 
			                </ul>   
						</div>
					</div>
			  	</div>
			</section>	
		
		);
	}
}

const mapStateToProps = state => {
  return {
  	userData: {
  		isLoggedIn : state.user.isLoggedIn
  	}
  };
};

const HomePage = connect(mapStateToProps)(ConnectedHomePage);

export default HomePage;

