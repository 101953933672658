import 'react-app-polyfill/ie9';
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppMobile from './AppMobile';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import store from "./redux/store";
import { CookiesProvider } from 'react-cookie';
import { TrackerProvider, Tracker } from 'react-tracker';
import trackAddToCart from './tracking/listeners';

const tracker = new Tracker([trackAddToCart]);

/*if (process.env.NODE_ENV === 'development') {
  const axe = require('react-axe')
  axe(React, ReactDOM, 1000)
}*/

var url = new URL(window.location.href);
var c = url.searchParams.get("isMobile");

if (c==="true") {
	ReactDOM.render(
		<Provider store={store}>
		  	<TrackerProvider tracker={tracker}>
				<AppMobile />
			</TrackerProvider>
		</Provider>,
		document.getElementById('root')
	);
} else {
	ReactDOM.render(
		<CookiesProvider>
		  <Provider store={store}>
		  	<TrackerProvider tracker={tracker}>
		    	<App />
		    </TrackerProvider>
		  </Provider>
	  	</CookiesProvider>,
	  document.getElementById('root'));
	
}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
