import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import ReactDOM from 'react-dom';
import '../../Assets/css/medicalArticlesPage.min.css';
import MedicalArticle from '../basicComponents/medicalArticle';
import axios from 'axios';
import CustomFilters from '../basicComponents/customFilters';
import loadingSpinner from '../../Assets/img/loading_articles_spinner1.gif';
import $ from 'jquery';
import { connect } from "react-redux";
const mapStateToProps = state => {

  	return {
	  	userData: {
	  		isLoggedIn : state.user.isLoggedIn,
	  		userName : state.user.data.full_name,
	  		userToken : state.user.token,
	  		userId:state.user.data.id
	  	}
  	};
};


class ConnectedMedicalArticlesListPage extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			medicalArticles:[],
			tags: [],
			searchValue:'',			
		    fetchingMedicalArticlesFromDB: true,
		    totalMedicalArticlesCount:0,
		    latestMedicalArticleId: 0,
		    filterMedicalArticlesOrder:'descending',
		    filterMedicalArticlesBy:'date',
		    paginateMultiplier:1,
		    requestForMoreMedicalArticles:false
		};		

		this.getTotalMedicalArticlesCount = this.getTotalMedicalArticlesCount.bind(this);
		this.getAllMedicalArticles = this.getAllMedicalArticles.bind(this);
		this.getMoreMedicalArticles = this.getMoreMedicalArticles.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);


		//this.handleMedicalArticleSorting = this.handleMedicalArticleSorting.bind(this);
	}

	componentDidMount() {		
		window.scrollTo(0,0);	
		this.getTotalMedicalArticlesCount();
		this.getAllMedicalArticles();

		this.setState({
        	tags: (this.props.tags) ? this.props.tags : []
		});
	}
	
	componentDidUpdate() {			
		const node = ReactDOM.findDOMNode(this);

		if (node instanceof HTMLElement) {
			$('.customFilter').removeClass('selected');
		    const filterSelected = node.querySelector('.customFilter[by="'+this.state.filterMedicalArticlesBy+'"]');
		    if (filterSelected) {
		    	filterSelected.classList.add('selected');
		    	filterSelected.setAttribute('order',this.state.filterMedicalArticlesOrder);
		    }
		    
		    

		}
	}	

	filterMedicalArticles(e) {
		let inputValue = e.target.value;		
		this.setState({
			searchValue: inputValue
		})
	}

	getTotalMedicalArticlesCount() {
		let allMedicalArticlesCount = 0;

    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/medical_articles_count', {})
	      .then(res => {
	      	
	      	/*console.log(window.serverURL);*/
	        allMedicalArticlesCount = res.data.data;
	        
	        this.setState({
	        	totalMedicalArticlesCount : allMedicalArticlesCount[0].count
	        	
			});	        
			
	    }).catch(err => 
	    	console.log('Login: ', err)
	    );
	}

	getMoreMedicalArticles() {
		this.setState({
			requestForMoreMedicalArticles:true
		},() => {			
			this.getAllMedicalArticles()	
		})
	}

	getAllMedicalArticles() {		
		let allMedicalArticles = [];
		
		var paginateMultiplier = Math.floor(this.state.medicalArticles.length/30)+1;
		

		let parameters = {    		
    		rating: 1,
    		comments:1,
    		limitFetch:paginateMultiplier*30,
    		
    		order:this.state.filterMedicalArticlesOrder,
    		by:this.state.filterMedicalArticlesBy
    	} 
    	 

    	this.setState({ fetchingMedicalArticlesFromDB: true });
    	var self = this;
    	
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/medical_articles', parameters)
	      .then(res => {
	        allMedicalArticles = res.data.data;
	        
	        
	        
	        self.setState({
	        	medicalArticles : allMedicalArticles,//[...self.state.medicalArticles,...allMedicalArticles],        	       	
	        	
	        	requestForMoreMedicalArticles:false,
	        	paginateMultiplier:paginateMultiplier	        	
			});
			self.setState({ fetchingMedicalArticlesFromDB: false });
			
	    }).catch(err => console.log('MedicalArticle: ', err));

	}

	

	handleFilterChange = (filter) => {	
		
		
		var allMedicalArticles = this.state.medicalArticles;

		
		
		this.setState({
			medicalArticles: [],
			
			filterMedicalArticlesOrder:filter.order,
			filterMedicalArticlesBy:filter.by			
		},() => {
			//console.log(this.state);
			
			this.getAllMedicalArticles()	
		});
	}
	
	render() {
		
		let filteredMedicalArticles = this.state.medicalArticles.filter(
			(medicalArticle) => {
				let inSubject = (medicalArticle.subject) ? medicalArticle.subject.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.state.searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1 : false;
				let inPublisher = (medicalArticle.publisher) ? medicalArticle.publisher.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(this.state.searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) !== -1 : false;				

				return inSubject || inPublisher;
				
			}
		);
		if (this.state.medicalArticles && this.props.userData.isLoggedIn) {			
			if (this.state.medicalArticles.length>0) {
				
				return (
					<div className="container-fluid medicalArticlesListPage">
						{/*<h3 className="medical-articles-page-title d-flex justify-content-center align-items-center">ΟΛΕΣ ΔΗΜΟΣΙΕΥΣΕΙΣ</h3>*/}
						<h3 className="medical-articles-page-title d-flex justify-content-center align-items-center">
							<div className="container">
								<div className="row d-flex justify-content-between align-items-center">
								    <div className="container d-flex align-items-center justify-content-center col-12 col-lg-6 mt-3 mt-lg-0 mb-2 mb-lg-0">
								    	ΟΛΕΣ ΟΙ ΔΗΜΟΣΙΕΥΣΕΙΣ
								    </div>
								    <div className="container d-flex justify-content-center col-12 col-lg-6 mb-3 mb-lg-0">
								    	<input aria-label="Search" name="search" className='form-control' placeholder="Αναζήτηση..." type="text" value={this.state.searchValue} onChange={this.filterMedicalArticles.bind(this)} />
								    </div>
								</div>
							</div>
						</h3>
						<CustomFilters onSelectFilter={this.handleFilterChange} />
						
			        	<div className="container d-flex flex-row justify-content-between flex-wrap">
							{filteredMedicalArticles.map((medicalArticle, index) => {
								return <MedicalArticle searchValue={this.state.searchValue} medicalArticleObj={medicalArticle} tags={this.state.tags} parentClassName="medical-articles-list-page" class="medical-articles-page-article" key={medicalArticle.id}/>			                    
							})}	
						</div>		

						<div className="container d-flex flex-row justify-content-between flex-wrap">	
						{
							(this.state.totalMedicalArticlesCount > this.state.medicalArticles.length) ?
								<button id="loadMoreMedicalArticles" onClick={this.getMoreMedicalArticles} disabled={this.state.fetchingMedicalArticlesFromDB}> 
									Περισσότερα... {this.state.fetchingMedicalArticlesFromDB ? <img id="loadingSpinner" width="22" height="22" src={loadingSpinner} /> : ''}
								</button>
							: 
								<div className="d-flex text-center m-auto">Τέλος των δημοσιεύσεων</div>
						}
						
					</div>			
						
						
					</div>
					
				);
			}
		}
		
		return (
			
			<div className="container-fluid medicalArticlesListPage">
				{this.state.fetchingMedicalArticlesFromDB ? 
					<h3 className="medical-articles-page-title d-flex justify-content-center align-items-center">Γίνεται εύρεση των δημοσιεύσεων</h3>					
					:
					<h3 className="medical-articles-page-title d-flex justify-content-center align-items-center">Δεν υπάρχουν δημοσιεύσεις.</h3> 
				}
				
			</div>
		);
		
	}
}
const MedicalArticlesListPage = connect(mapStateToProps,null,null)(ConnectedMedicalArticlesListPage);
export default MedicalArticlesListPage;