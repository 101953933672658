import React, {Component} from 'react';

class PrivacyPageApp extends Component {

	constructor() {
		super();
		
		this.state = {
			
		};		
	}

	componentDidMount() {		
		window.scrollTo(0,0);
	}
	
	componentDidUpdate() {				
			
	}
	
	render() {
		return (
			
			<div className="container-fluid privacyPage">
				<h3 className="event-page-title d-flex justify-content-center align-items-center">ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</h3>
				<div className="container">
					<p>
					    Σε πλήρη συμμόρφωση με την ισχύουσα νομοθεσία για την προστασία των προσωπικών δεδομένων η GET2WORK ΜΟΝΟΠΡΟΣΩΠΗ ΙΚΕ, δεν τηρεί αρχείο δεδομένων προσωπικού χαρακτήρα των χρηστών της εφαρμογής all4Diabetes.
					</p>
					<p>
						Η εφαρμογή δεν απαιτεί εγγραφή ή κωδικό πρόσβασης για τη χρήση της. Σε περίπτωση διαγραφής της εφαρμογής σας ή αλλαγής συσκευής τα δεδομένα δεν μεταφέρονται. Τα στοιχεία που τυχόν έχετε καταχωρήσει δεν αποθηκεύονται από την GET2WORK MON IKE.
					</p>
					
					<p align="right">
					    Copyright &copy; { new Date().getFullYear()}, Get2Work
					</p>
				</div>
			</div>
		);
		
	}
}

export default PrivacyPageApp;