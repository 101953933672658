import React from 'react';
import logo from '../../Assets/img/logo.png';
import { HashLink } from 'react-router-hash-link';
import Rating from '../basicComponents/rating';

function extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
}

const Iframe = ({ source, ratingsCallback, ratingsData, isUserLoggedIn }) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    function updateRating(userRating) {
        ratingsCallback(userRating);
    }

    const src = source;
    const srcName = extractHostname(src);
    //debugger;
    return (       
        <div className="modal" id="iframeModal">
            <div className="d-flex flex-column w-100 h-100">
                <div className="articlePageFrameHeader">
                    <div className="row  d-flex justify-content-center align-items-center">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            {/*<span aria-hidden="true">&times;</span>*/}
                            <img src={logo} className="" alt="All4Diabetes logo" height="30"/><span style={{fontSize:"0.4em",verticalAlign:"middle"}}>All4Diabetes</span>
                        </button>
                        <div>Πατήστε <a href = {src} target="_blank" rel="noopener noreferrer nofollow"> {srcName} </a> αν δεν εμφανίζεται περιεχόμενο</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>                  
                        </button>
                    </div>
                    {(isUserLoggedIn) ? <div className="row  d-flex justify-content-around align-items-center">
                        <HashLink className="m-auto" smooth to="#commentsSection" data-dismiss="modal" >Σχολιάστε το άρθρο</HashLink>
                        <div className="m-auto">
                            <Rating rating={ratingsData.rating} updateRating={updateRating} ratingNames={ratingsData.ratingNames}/>
                        </div>
                    </div> : ''}
                </div>
                <div className="modal-dialog">
                    <div className="modal-content"> 
                        <div className="modal-body">
                           {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>*/}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                       
                                            <iframe title={srcName} src={src}></iframe>                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
    );
};

export default Iframe;