import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import defaultAvatar from '../../Assets/img/catalog_avatar.jpg';

class HcpAvatar extends Component {
    constructor() {
        super();
        
        this.state = {
            hcpObj: {},
            hovered: false
        };      
        this.hoverOn = this.hoverOn.bind(this);
        this.hoverOff = this.hoverOff.bind(this);
    }

    componentDidMount() {
        let selfProps = {};
        selfProps.full_name = this.props.hcpObj.full_name;
        selfProps.image = (this.props.hcpObj.image) ? window.serverURL+"/serve_images/"+this.props.hcpObj.image : defaultAvatar;
        selfProps.id = this.props.hcpObj.id;       
        this.setState({
            hcpObj:selfProps
        })
        
    }

    hoverOn() {
        this.setState({
            hovered: true
        })
    }

    hoverOff() {
        this.setState({
            hovered: false
        })
    }

    render () {
       
        return (
            <div className={"hcpAvatar active"/*this.state.hovered == true ? "hcpAvatar active" : "hcpAvatar inactive"*/} onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
                <div className="hcpAvatarImage">
                    <img src={this.state.hcpObj.image} alt={this.state.hcpObj.full_name + " | All4Diabetes"} className="img-fluid m-auto"/>
                </div>

                <div className="hcpAvatarName">
                    <Link to={{pathname:"/hcp_profile/"+this.state.hcpObj.id}}>{this.state.hcpObj.full_name} </Link>
                </div>
            </div>
        );
    }
}

/*function HcpAvatar(props) {	
	let self = {};
    self.name = props.hcpObj.full_name;
    self.image = (props.hcpObj.image) ? window.serverURL+"/serve_images/"+props.hcpObj.image : defaultAvatar;
    
    return (
        <div className="hcpAvatar" onMouseEnter="">
            <div className="hcpAvatarImage">
                <img src={self.image} alt="Επαγγελματίας υγείας" className="img-fluid m-auto"/>
            </div>
            <div className="hcpAvatarName">
                {self.name}
            </div>
        </div>
    );
}*/

export default HcpAvatar;