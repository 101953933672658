import React, {Component} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import PaginationArrow from '../../../Assets/img/pagination_arrow_left.png';

var dateOptions = { /*weekday: 'long',*/ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

class CommentsAndRatingsList extends Component {

	constructor() {
		super();
		
		this.state = {
			commentsAndRatings:[],
			currentItemsPage: 1,
			itemsPerPage:5
		};		
		this.getHcpCommentsAndRatings = this.getHcpCommentsAndRatings.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
	}

	componentDidMount() {		
		this.getHcpCommentsAndRatings();
	}
	
	componentDidUpdate() {				
			
	}

	handlePageClick(data){
    	this.setState({
			currentItemsPage: data.selected+1,
		});
    }


	getHcpCommentsAndRatings() {
		let commentsAndRatings = [];

    	let hcpId = {
    		hcp_id: this.props.hcp_id
    	}
    	axios.post(window.serverURL+'/all4diabetes/admin/public/admin/api/ajax_requests/hcpCommentsAndRatings', hcpId)
	      .then(res => {
	      	
	        commentsAndRatings = res.data.data;	        
	        this.setState({
	        	commentsAndRatings : [...commentsAndRatings]
	        })
	        
			
	    }).catch(err => console.log('Login: ', err));
	}

	componentWillReceiveProps(nextProps) {
		
		
	}
	
	render() {
		
		const indexOfLastItem = this.state.currentItemsPage * this.state.itemsPerPage;
		const indeOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
		const currentItems = this.state.commentsAndRatings.slice(indeOfFirstItem, indexOfLastItem);
		//console.log(this.state.commentsAndRatings.length/this.state.itemsPerPage);
		return (
			
			<div className="container-fluid commentsAndRatingsContainer">
				{currentItems.map((item, index) => {
					const dateFormatted = new Date(item.date).toLocaleDateString('el-EL', dateOptions)
					return <div className="itemInfo" key={index}> <i className="fa fa-clock-o" aria-hidden="true"></i> {dateFormatted} | {item.type} | {<Link to={{pathname:"/article/"+item.article_id+"/"+item.title}}>{item.title}</Link>} </div>
				})}
				<ReactPaginate
		          previousLabel={<img src={PaginationArrow} className="arrow" alt="Navigation button"/>}
		          nextLabel={<img src={PaginationArrow} className="arrow rightArrow" alt="Navigation button" />}
		          breakLabel={'...'}
		          breakClassName={'break-me'}
		          pageCount={(this.state.commentsAndRatings.length !==0) ? Math.ceil(this.state.commentsAndRatings.length/this.state.itemsPerPage) : 0}
		          marginPagesDisplayed={2}
		          pageRangeDisplayed={5}
		          onPageChange={this.handlePageClick}
		          pageClassName={'pages'}
		          pageLinkClassName={'links'}
		          previousClassName={'previous'}
		          previousLinkClassName={'previous'}
		          nextClassName={'next'}
		          nextLinkClassName={'next'}
		          containerClassName={'pagination'}
		          subContainerClassName={'pages pagination'}
		          activeClassName={'active'}
		          activeLinkClassName={'active'}
		          disabledClassName={'disabled'}

		        />
			</div>
		);
		
	}
}

export default CommentsAndRatingsList;